import { Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';

const StringToJsx: FC<{ text?: string; className?: string } & TextProps> = (
  props
) => {
  const { text, className } = props;
  const texts = (text ?? '').split(/(?:\r\n|\r|\n)/g);

  return (
    <>
      {texts.map((text, ind) => (
        <Text
          mb="8px"
          _last={{ marginBottom: 0 }}
          display="block"
          className={className}
          key={`txt-${ind}`}
          {...props}
        >
          {text || '　'}
        </Text>
      ))}
    </>
  );
};
export default StringToJsx;
