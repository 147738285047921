import { storeOptionsState } from 'features/membership/libs/recoil/selector';
import { getAllStores } from 'features/my-store/api';
import { StoreType } from 'features/my-store/types/StoreType';
import { allStoresAtom } from 'libs/recoil/atom';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

// TODO: 将来useStoreに移行する
const useStoreOptions = () => {
  // TODO: skip できるようにする
  const storeOptions = useRecoilValue(storeOptionsState);
  const setAllStoreData = useSetRecoilState<StoreType[]>(allStoresAtom);

  useEffect(() => {
    if (storeOptions.length) {
      return;
    }

    getAllStores().then((stores) => {
      if (stores.length) {
        setAllStoreData(stores);
      }
    });
  }, [storeOptions, setAllStoreData]);

  return storeOptions;
};
export default useStoreOptions;
