import LabeledValue from 'components/modules/labeledValue/LabeledValue';
import { FC, ReactNode } from 'react';

const LabeledValueWithVisibility: FC<
  {
    label: string;
    value: string | ReactNode;
  } & {
    isVisible?: boolean;
  }
> = ({ label, value, isVisible }) => {
  if (!isVisible) return null;
  return <LabeledValue label={label} value={value} />;
};
export default LabeledValueWithVisibility;
