import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text
} from '@chakra-ui/react';
import { FC } from 'react';

type CuminOvertimePointGetCautionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  availableHour?: string;
  isLoaded: boolean;
};

/**
 * ポイント情報取得時間外モーダル
 * */
const CuminOvertimePointGetCautionModal: FC<
  CuminOvertimePointGetCautionModalProps
> = ({ isOpen, onClose, availableHour, isLoaded }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent maxH="80vh" mx="1rem" position="relative">
        <ModalHeader textAlign="center" pt="1.5rem" pb="0">
          <Text fontSize="2xl">
            現在の時間は保有ポイント数を
            <br />
            表示できません
          </Text>
        </ModalHeader>
        <ModalBody
          textAlign="center"
          pt="0.5rem"
          pb="1.5rem"
          fontSize="md"
          fontWeight="400"
        >
          <Skeleton isLoaded={isLoaded}>
            {!!availableHour && (
              <>
                ポイント数表示時間は{availableHour}です。
                <br />
                対応時間内に再度お試しください。
              </>
            )}
          </Skeleton>
        </ModalBody>
        <ModalFooter py="0">
          <Button
            mb="1rem"
            variant="dark-outline"
            py="0.25rem"
            w="100%"
            borderRadius="20px"
            onClick={onClose}
          >
            閉じる
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CuminOvertimePointGetCautionModal;
