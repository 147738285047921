import { Grid, GridItem } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';

type FieldProps = {
  inputComponent: ReactElement;
  error: string | undefined | null;
};
type CuminNameFieldGroupProps = {
  lastNameField: FieldProps;
  firstNameField: FieldProps;
  noticeContent: string | undefined | null;
};

const CuminNameFieldGroup: FC<CuminNameFieldGroupProps> = ({
  lastNameField: { inputComponent: lastNameInput, ...lastName },
  firstNameField: { inputComponent: firstNameInput, ...firstName },
  noticeContent
}) => (
  <Grid templateColumns="1fr 1fr" columnGap=".5rem" width="100%">
    <GridItem>{lastNameInput}</GridItem>
    <GridItem>{firstNameInput}</GridItem>
    {noticeContent && (
      <GridItem
        colSpan={2}
        my="0.5rem"
        fontSize="md"
        wordBreak="break-all"
        whiteSpace="pre-wrap"
      >
        {noticeContent}
      </GridItem>
    )}
    {lastName.error && (
      <GridItem fontSize="sm" color="danger">
        {lastName.error}
      </GridItem>
    )}
    {firstName.error && (
      <GridItem fontSize="sm" colStart={2} color="danger">
        {firstName.error}
      </GridItem>
    )}
  </Grid>
);
export default CuminNameFieldGroup;
