import dayjs from 'dayjs';
import PreReservedUndueCaution from 'features/reservations/components/PreReservedUndueCaution';
import { ReservationType } from 'features/reservations/types/ReservationType';
import React, { FC } from 'react';
import {
  PaidInformation,
  PayInformationSection,
  ReservationPrepaidOverdue
} from './CaveatsSections';

/**
 * 商品予約詳細 お知らせ・注意表示コンポーネント
 * 予約状態ごとに適切な文言を表示する
 */
const ReservedWarningSection: FC<{
  reservation: ReservationType | null;
}> = ({ reservation }) => {
  // 支払期日を過ぎていないか確認した結果のフラグ
  const isDeadlineBefore = dayjs().isBefore(
    dayjs(reservation?.payment_deadline_at)
  );

  // 事前支払いなし
  if (reservation?.reservation_status === 'reserved') {
    return <PayInformationSection />;
  }
  // 事前支払いありで期日を過ぎていない場合
  if (
    reservation?.reservation_status === 'pre_reserved' &&
    reservation?.payment_deadline_at &&
    isDeadlineBefore
  ) {
    return (
      <PreReservedUndueCaution
        paymentDeadLineAt={reservation.payment_deadline_at}
      />
    );
  }
  // 事前支払いありで支払い期日を過ぎている場合
  if (
    reservation?.reservation_status === 'pre_reserved' &&
    reservation?.payment_deadline_at &&
    !isDeadlineBefore
  ) {
    return (
      <ReservationPrepaidOverdue dueDate={reservation.payment_deadline_at} />
    );
  }
  // 支払いが完了してる場合
  if (reservation?.reservation_status === 'paid_reserved') {
    return <PaidInformation />;
  }

  // 上記の条件に当てはまらない場合は、要素を返却しない。
  return <></>;
};

export default React.memo(ReservedWarningSection);
