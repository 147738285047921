import dayjs from 'dayjs';
import toObject from 'dayjs/plugin/toObject';

/** 会員情報表示APIから取得してきた誕生日をフォーム用に変換する関数 */
const cuminFormatBirthdayForForm = (birthday: string) => {
  // toObject pluginを有効化
  dayjs.extend(toObject);
  return Object.fromEntries(
    Object.entries(dayjs(birthday).toObject())
      .map(([keyName, keyValue]) => {
        switch (keyName) {
          case 'months':
            return ['month', String((keyValue as number) + 1)];
          case 'years':
            return ['year', String(keyValue)];
          case 'date':
            return ['date', String(keyValue)];
          default:
            return null;
        }
      })
      .filter((entry) => entry) as string[][]
  );
};

export default cuminFormatBirthdayForForm;
