import {
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react';
import { ProductType } from 'features/reservations/types/ProductType';
import { getUnitPriceIncludingTax } from 'libs/calculation';
import { FALLBACK_IMAGES } from 'libs/constants';
import { RoundingAliasKeys } from 'libs/rounding';
import React, { FC } from 'react';
import { ProductListSkeletonBox } from './SkeletonBoxes';

type ReservedProductListSectionType = {
  products?: Array<
    Pick<
      ProductType,
      | 'id'
      | 'name'
      | 'description'
      | 'price'
      | 'tax_rate'
      | 'image_file_urls'
      | 'jan_code'
      | 'jan_code_format'
    > & { canceled_at: string; quantity: number }
  >;
  roundingAlias: RoundingAliasKeys;
  isLoaded?: boolean;
};

/** 予約商品を表示するコンポーネント */
const ReservedProductListSection: FC<ReservedProductListSectionType> = ({
  products = [],
  roundingAlias = 'round_off',
  isLoaded = false
}) => {
  return (
    <Stack gap="1rem">
      <Heading size="md">予約商品</Heading>
      <VStack gap="1rem">
        {isLoaded ? (
          (products ?? []).map((product, index) => (
            <Flex
              key={index}
              flexDir="row"
              alignItems="flex-start"
              gap="0.5rem"
              w="100%"
              minH="5rem"
              wordBreak="break-all"
            >
              <Box w="5rem" h="5rem" flexShrink={0}>
                <Image
                  boxSize="100%"
                  objectFit="cover"
                  loading="lazy"
                  src={product.image_file_urls[0]?.image_file_url ?? ''}
                  fallbackSrc={FALLBACK_IMAGES.square}
                  alt="商品画像"
                />
              </Box>
              <Box color="dark.500" w="100%">
                <Heading
                  color="dark.500"
                  fontWeight={700}
                  fontSize="md"
                  mb="0.25rem"
                >
                  {product.name}
                </Heading>
                <Flex m="0" w="100%" justifyContent="space-between">
                  <Text>
                    {getUnitPriceIncludingTax(
                      product,
                      roundingAlias
                    ).toLocaleString()}
                    円（税込）
                  </Text>
                  <Text>× {product.quantity}個</Text>
                </Flex>
              </Box>
            </Flex>
          ))
        ) : (
          <ProductListSkeletonBox />
        )}
      </VStack>
    </Stack>
  );
};

export default React.memo(ReservedProductListSection);
