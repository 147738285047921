import { getTenantConfiguration } from 'api';
import dayjs from 'dayjs';
import {
  confirmCreateReservation,
  getCampaignProductDetail
} from 'features/reservations/api';
import { getGrandTotal } from 'libs/calculation';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { RoundingAliasKeys } from 'libs/rounding';
import { useEffect, useState } from 'react';

import { ProductType } from 'features/reservations/types/ProductType';

export const useInvoice = (
  campaignId: number,
  storeId: number,
  cartProducts: { id: number; quantity: number }[],
  additionalParams: {
    receipt_at: string;
    receipt_hour_width: string;
    card_number: string;
    tel_number: string;
    staff_comment: string;
    option_1_answer: string;
    option_2_answer: string;
    option_3_answer: string;
  } | null,
  hasValidation: boolean
) => {
  const [lineProducts, setLineProducts] = useState<
    Array<ProductType & { quantity: number }>
  >([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [roundingOption, setRoundingOption] =
    useState<RoundingAliasKeys>('round_down');
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isDoneCalculation, setIsDoneCalculation] = useState<boolean>(false);
  const [paymentDeadline, setPaymentDeadline] = useState<string | null>(null);
  const [autoCancelScheduledAt, setAutoCancelScheduledAt] = useState<
    string | null
  >(null);
  // 「RSV-API016 予約確定確認用情報取得」 API で発行されるトークン(「RSV-API008 予約確定」 API 呼び出し時に利用)
  const [requestToken, setRequestToken] = useState<string | undefined>(
    undefined
  );

  // NOTE: Get latest reservation start date among lined products.
  const reservableDurationStart =
    lineProducts
      .filter((product) => dayjs(product.reservation_start_at).isValid())
      .sort((a, b) =>
        dayjs(a.reservation_start_at).isBefore(dayjs(b.reservation_start_at))
          ? 1
          : -1
      )[0]?.reservation_start_at ?? null;

  // NOTE: Get earliest reservation end date among lined products.
  const reservableDurationEnd =
    lineProducts
      .filter((product) => dayjs(product.reservation_end_at).isValid())
      .sort((a, b) =>
        dayjs(a.reservation_end_at).isAfter(dayjs(b.reservation_end_at))
          ? 1
          : -1
      )[0]?.reservation_end_at ?? null;

  useEffect(() => {
    const allCartProductsPromises = cartProducts.map((product) =>
      getCampaignProductDetail({
        campaign_id: campaignId,
        product_id: product.id
      })
    );
    setIsDoneCalculation(false);

    Promise.allSettled(allCartProductsPromises).then((responses) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const lineItems = responses.flatMap(({ value }: any, index) => {
        if (value.status === 404) return [];

        return {
          ...value,
          quantity: cartProducts[index]?.quantity ?? 0
        };
      });
      setLineProducts(lineItems);

      getTenantConfiguration().then((res) => {
        const roundingMethod = res.data?.tax_round ?? 'round_down';
        setRoundingOption(roundingMethod);
        const grandTotal = getGrandTotal(lineItems, roundingMethod);
        setTotalAmount(grandTotal);
        setIsDone(true);
        setIsDoneCalculation(true);
      });
    });

    confirmCreateReservation({
      campaign_id: campaignId,
      store_id: storeId,
      products: cartProducts.map((product) => ({
        product_id: product.id,
        quantity: product.quantity
      })),
      ...additionalParams,
      has_validation: hasValidation
    }).then((res) => {
      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
        setPaymentDeadline(res.data?.payment_deadline_at ?? null);
        setAutoCancelScheduledAt(
          res.data?.payment_auto_cancel_scheduled_at ?? null
        );
        if (res.data?.request_token) setRequestToken(res.data?.request_token); // request_token がレスポンスパラメータに存在すればセット
      }
    });
    // FIXME:dependencies の精査
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartProducts]);

  return {
    lineProducts,
    totalAmount,
    isDone,
    isDoneCalculation,
    roundingOption,
    reservableDurationStart,
    reservableDurationEnd,
    paymentDeadline,
    autoCancelScheduledAt,
    requestToken
  };
};
