import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  Skeleton,
  SkeletonText,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import Dialog from 'components/modules/Dialog';
import dispatchToast from 'components/modules/Toast';
import UpdateButton from 'components/modules/UpdateButton';
import dayjs from 'dayjs';
import CampaignReceiptNote from 'features/reservations/components/CampaignReceiptNote';
import CampaignReceiptTimeNotes from 'features/reservations/components/CampaignReceiptTimeNotes';
import ProductBarcodeModalButtonSection from 'features/reservations/components/ProductBarcodeModalButtonSection';
import ReservationFormViewer from 'features/reservations/components/ReservationFormViewer';
import ReservationNumber from 'features/reservations/components/ReservationNumber';
import ReservedProductListSection from 'features/reservations/components/ReservedProductListSection';
import ReservedSummarySection from 'features/reservations/components/ReservedSummarySection';
import ReservedWarningSection from 'features/reservations/components/ReservedWarningSection';
import { useCampaignDetail } from 'features/reservations/hooks/useCampaign';
import { useReservation } from 'features/reservations/hooks/useReservation';
import { getReservedSummaryCaveatsByStatus } from 'features/reservations/libs/caveats';
import { getReservationStatusObjectByAlias } from 'features/reservations/libs/reservationStatus';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { tenantConfigurationAtom } from 'libs/recoil/atom';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/** 履歴詳細（予約詳細） */
const ReservationDetail: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { reservationId } = useParams();
  const { reservation, isDone, handleCancellation } = useReservation(
    Number(reservationId)
  );
  const tenantConfiguration = useRecoilValue(tenantConfigurationAtom);
  const { targetCampaign } = useCampaignDetail(reservation?.campaign_id);

  const { label, isAvailableForPickup, isAvailableForCancel } =
    // FIXME: define initial value
    getReservationStatusObjectByAlias(
      reservation?.reservation_status ?? 'reserved'
    );

  const isEmptyValueOfCancelableDeadline = !reservation?.cancelable_deadline_at;

  const navigate = useNavigate();
  const handleMoveToPickup = () => {
    navigate('pickup');
  };

  const isOverdueCancelableDeadline = dayjs().isAfter(
    dayjs(reservation?.cancelable_deadline_at)
  );

  const handleReload = () => location.reload();

  const onSubmitCancel = () => {
    handleCancellation().then((res) => {
      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
        location.reload();
      } else {
        dispatchToast({
          id: 'toast-reservation-cancel-error',
          toastText: `エラー：${res.errors.join(', ')}`,
          center: true
        });
      }
      onClose();
    });
  };

  return (
    <>
      <MainLayout header footer>
        <Spacer h="2.5rem" />
        {/* 予約状態ごとに適切なお知らせ・注意コンポーネントを表示 */}
        <ReservedWarningSection reservation={reservation} />
        <Container px="1rem">
          <Flex alignItems="center">
            <Heading>予約詳細</Heading>
            <Spacer />
            <UpdateButton onClick={handleReload} />
          </Flex>
          <Stack alignItems="center" textAlign="center" mt="2rem" gap="2rem">
            <ReservationNumber
              reservationNumber={reservation?.reservation_number}
              isDone={isDone}
            />
            {reservation?.reservation_status === 'pre_reserved' && (
              <ProductBarcodeModalButtonSection
                reservation={reservation}
                isScanPayment={tenantConfiguration.scan_paymentable}
              />
            )}
          </Stack>
        </Container>
        <Container px="1rem" pb="1rem" mt="2rem" mb="1rem">
          <Skeleton isLoaded={isDone} w="100%" mb="1.5rem">
            {reservation?.reservation_status !== 'pre_reserved' && (
              <Button
                isDisabled={!isAvailableForPickup}
                variant="primary-fullwidth-rounded"
                onClick={handleMoveToPickup}
              >
                {reservation?.reservation_status === 'reserved' ||
                reservation?.reservation_status === 'paid_reserved'
                  ? '商品の受け取りに進む'
                  : label}
              </Button>
            )}
          </Skeleton>
          <SkeletonText isLoaded={isDone} noOfLines={8}>
            {/* TODO: 画面全体の余白指定方法の見直し */}
            <Stack w="100%" gap="1.5rem">
              {/* HACK:画面左側側に親要素の padding 分余白ができてしまうため、calc(50% - 50vw) を追加 */}
              <Box mx="calc(50% - 50vw)">
                <Divider />
              </Box>
              {reservation?.campaign_receipt_note && (
                <CampaignReceiptNote
                  campaign_receipt_note={reservation.campaign_receipt_note}
                />
              )}

              {reservation && (
                <ReservationFormViewer
                  headding="予約内容"
                  targetCampaign={targetCampaign}
                  reservationForm={{ ...reservation }}
                />
              )}
              {reservation && (
                <CampaignReceiptTimeNotes
                  campaign_auto_cancel_waiting_hours={
                    reservation.campaign_auto_cancel_waiting_hours
                  }
                />
              )}
              <ReservedProductListSection
                products={reservation?.products}
                roundingAlias={reservation?.tax_round ?? 'round_off'}
                isLoaded={isDone}
              />
              <ReservedSummarySection
                total={reservation?.total_with_tax}
                isLoaded={isDone}
                caveats={getReservedSummaryCaveatsByStatus(
                  reservation?.reservation_status ?? 'reserved',
                  targetCampaign?.pre_paymentable ?? false
                )}
              />

              {isAvailableForCancel && (
                <Box
                  w="100%"
                  px="1rem"
                  my="0.5rem"
                  display="block"
                  textAlign="center"
                >
                  {reservation?.reservation_status === 'paid_reserved' ? (
                    <Text fontSize="md" fontWeight={400} color="dark.200">
                      支払い済みのため予約の取り消しはできません
                    </Text>
                  ) : isEmptyValueOfCancelableDeadline ? (
                    <Text fontSize="md" fontWeight={400} color="dark.200">
                      予約の取り消しはできません
                    </Text>
                  ) : isOverdueCancelableDeadline ? (
                    <Text fontSize="md" fontWeight={400} color="dark.200">
                      予約の取り消し可能期限を過ぎています
                    </Text>
                  ) : (
                    <Button variant="danger-link" onClick={onOpen}>
                      <VStack gap="0.25rem">
                        <Text fontSize="md" fontWeight="700" textAlign="center">
                          予約を取り消す
                        </Text>
                        <Text fontSize="md" fontWeight="400">
                          （{reservation?.cancelable_deadline_at}まで可能）
                        </Text>
                      </VStack>
                    </Button>
                  )}
                </Box>
              )}
            </Stack>
          </SkeletonText>
        </Container>
      </MainLayout>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        title="予約の取り消し"
        description="予約を取り消しますか？"
        cancelText="キャンセル"
        submitText="取り消す"
        onSubmit={onSubmitCancel}
        isDestructive={true}
      />
    </>
  );
};

export default ReservationDetail;
