import {
  formatErrorObject,
  getRequestHeader,
  handleMoveToErrorPage,
  handleResetLocalStorage
} from 'api';
import axios from 'axios';
import { CreateReservationRequestType } from 'features/reservations/types/ReservationType';
import { RESERVATIONS_API_ENDPOINT, TENANT_CODE } from 'libs/constants';
import qs from 'qs';

export const reservationsApi = axios.create({
  baseURL: `${RESERVATIONS_API_ENDPOINT}/${TENANT_CODE}/reserve`
});

// TODO: 今後の対応でも構いませんので、responseとerrorのformattingはinterceptorの方でやって、共通にしましょう。
reservationsApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    // NOTE: Move the user to /error page in case of the error response with 500 status.
    if (error.response?.status === 500) {
      handleMoveToErrorPage('server-error');
    }

    // NOTE: Move the user to session expired page in case of the error response with "IdToken expired." message
    if (error.response) {
      const errorObject = error.response.data?.errors;
      if (errorObject.includes('IdToken expired.')) {
        handleResetLocalStorage();
        handleMoveToErrorPage('session-expired');
      }
    }
    return Promise.reject(error);
  }
);

/** RSV-API001 キャンペーン一覧取得 */
export const getCampaignsByStoreId = (store_id: number) =>
  reservationsApi
    .request({
      url: '/campaigns',
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true }),
      params: {
        store_id
      }
    })
    .then((res) => res.data.data.campaigns);

/** RSV-API013 キャンペーン詳細取得 */
export const getCampaignDetailByCampaignId = (campaign_id: number) =>
  reservationsApi
    .request({
      url: `/campaigns/${campaign_id}`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API002 キャンペーングループ一覧取得 */
export const getCampaignGroupsByCampaignId = (campaign_id: number) =>
  reservationsApi
    .request({
      url: `/campaigns/${campaign_id}/groups`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API003 キャンペーングループの商品一覧取得 */
export const getCampaignProducts = ({
  campaign_id,
  group_id
}: {
  campaign_id: number;
  group_id: number;
}) =>
  reservationsApi
    .request({
      url: `/campaigns/${campaign_id}/groups/${group_id}/products`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API004 キャンペーン商品詳細取得 */
export const getCampaignProductDetail = ({
  campaign_id,
  product_id
}: {
  campaign_id: number;
  product_id: number;
}) =>
  reservationsApi
    .request({
      url: `/campaigns/${campaign_id}/products/${product_id}`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API014 キャンペーン商品取得 */
export const getMultipleProductsDetails = ({
  campaign_id,
  product_ids
}: {
  campaign_id: number;
  product_ids: number[];
}) =>
  reservationsApi
    .request({
      url: `/campaigns/${campaign_id}/products/`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true }),
      params: {
        product_ids
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      paramsSerializer: (params: any) =>
        qs.stringify(params, { arrayFormat: 'brackets' })
    })
    .then((res) => res.data.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API005 キャンペーン商品予約可否チェック */
export const checkStocksForReservation = ({
  campaign_id,
  product_id,
  quantity
}: {
  campaign_id: number;
  product_id: number;
  quantity: number;
}) =>
  reservationsApi
    .request({
      url: `/campaigns/${campaign_id}/products/${product_id}/check_stock`,
      method: 'POST',
      headers: getRequestHeader({ hasIdToken: true }),
      data: {
        quantity
      }
    })
    .then((res) => res.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API006 受け取り可能日一覧取得 */
export const getReceiptableDates = ({
  campaign_id,
  store_id,
  products
}: {
  campaign_id: number;
  store_id: number;
  products: {
    product_id: number;
    quantity: number;
  }[];
}) =>
  reservationsApi
    .request({
      url: `/campaigns/${campaign_id}/stores/${store_id}/receiptable_dates`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true }),
      params: {
        products
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      paramsSerializer: (params: any) =>
        qs.stringify(params, { arrayFormat: 'brackets' })
    })
    .then((res) => res.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API015 受け取り可能日チェック */
export const checkReceiptableDate = ({
  campaign_id,
  store_id,
  receipt_at,
  products
}: {
  campaign_id: number;
  store_id: number;
  receipt_at: string;
  products: {
    product_id: number;
    quantity: number;
  }[];
}) =>
  reservationsApi
    .request({
      url: `/campaigns/${campaign_id}/stores/${store_id}/receiptable_dates/check`,
      method: 'POST',
      headers: getRequestHeader({ hasIdToken: true }),
      data: {
        receipt_at,
        products
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      paramsSerializer: (params: any) =>
        qs.stringify(params, { arrayFormat: 'brackets' })
    })
    .then((res) => res.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API007 受け取り可能時間帯一覧取得 */
export const getReceiptableHourWidths = ({
  campaign_id,
  store_id,
  receipt_at
}: {
  campaign_id: number;
  store_id: number;
  // NOTE: "yyyy-MM-dd"
  receipt_at: string;
}) =>
  reservationsApi
    .request({
      url: `/campaigns/${campaign_id}/stores/${store_id}/receiptable_hour_widths`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true }),
      params: {
        receipt_at
      }
    })
    // NOTE: e.g. "09:00:00~11:59:59" []
    .then((res) => res.data.data.receiptable_hour_widths)
    .catch((error) => formatErrorObject(error));

/** RSV-API016 予約確定確認用情報取得 */
export const confirmCreateReservation = (params: {
  campaign_id: number;
  store_id: number;
  // NOTE: "yyyy-MM-dd"
  receipt_at?: string;
  receipt_hour_width?: string;
  card_number?: string;
  tel_number?: string;
  staff_comment?: string;
  option_1_answer?: string;
  option_2_answer?: string;
  option_3_answer?: string;
  has_validation: boolean; // 追加項目のバリデーションの要否を判断するフラグ
  products: {
    product_id: number;
    quantity: number;
  }[];
}) =>
  reservationsApi
    .request({
      url: '/users/current/reservations/confirm_create_information',

      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true }),
      params,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      paramsSerializer: (params: any) =>
        qs.stringify(params, { arrayFormat: 'brackets' })
    })
    .then((res) => res.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API008 予約確定 */
export const createReservation = (
  reservationRequestData: CreateReservationRequestType
) =>
  reservationsApi
    .request({
      url: '/users/current/reservations',
      method: 'POST',
      headers: getRequestHeader({ hasIdToken: true, hasAccessToken: true }),
      data: reservationRequestData
    })
    .then((res) => res.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API009 予約一覧取得 */
export const getAllReservations = () =>
  reservationsApi
    .request({
      url: '/users/current/reservations',
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data.data.reservations);

/** RSV-API010 予約詳細取得 */
export const getReservationById = (reservation_id: number) =>
  reservationsApi
    .request({
      url: `/users/current/reservations/${reservation_id}`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API011 予約キャンセル */
export const cancelReservation = (reservation_id: number) =>
  reservationsApi
    .request({
      url: `/users/current/reservations/${reservation_id}`,
      method: 'DELETE',
      headers: getRequestHeader({ hasIdToken: true, hasAccessToken: true })
    })
    .then((res) => res.data)
    .catch((error) => formatErrorObject(error));

/** RSV-API012 商品受け取り完了 */
export const pickupReservation = (reservation_id: number) =>
  reservationsApi
    .request({
      url: `/users/current/reservations/${reservation_id}/receipt`,
      method: 'POST',
      headers: getRequestHeader({ hasIdToken: true, hasAccessToken: true })
    })
    .then((res) => res.data)
    .catch((error) => formatErrorObject(error));
