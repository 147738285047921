export const roundingOptions = {
  round_up: '切り上げた',
  round_down: '切り捨てした',
  round_off: '四捨五入した'
} as const;

export type RoundingAliasKeys = keyof typeof roundingOptions;
export type RoundingNameValues = (typeof roundingOptions)[RoundingAliasKeys];

/**
 * Output the integer value
 *
 * @module roundToInteger
 * @param {number} value - input value
 * @param {roundingOption} value - how to round the float part
 * @return {number} - output the value with floating point.
 */
export const roundToInteger = (value: number, option: RoundingAliasKeys) => {
  if (option === 'round_up') {
    return Math.ceil(value);
  } else if (option === 'round_down') {
    return Math.floor(value);
  } else if (option === 'round_off') {
    return Math.round(value);
  } else {
    return value;
  }
};

/**
 * Output the value with 1/1000 precision.
 * (e.g.) 7500 * 1 * 1.08 = 8100.000000000001
 * With the function, 7500 * 1 * 1.08 = 8100.000
 *
 * @module roundDecimalError
 * @param {number} value - input value
 * @param {roundingOption} value - how to round the float part
 * @return {number} - output the value with 1/1000 precision.
 */
export const roundDecimalError = (
  value: number,
  roundingOption: RoundingAliasKeys
) => {
  if (roundingOption === 'round_up') {
    return Math.ceil(Math.floor(value * 1000)) / 1000;
  } else if (roundingOption === 'round_down') {
    return Math.floor(Math.floor(value * 1000)) / 1000;
  } else if (roundingOption === 'round_off') {
    return Math.round(Math.floor(value * 1000)) / 1000;
  } else {
    return value;
  }
};
