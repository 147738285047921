import CuminCompletePage from 'features/membership/components/cumin/CuminCompletePage';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  CuminPagePageTemplateOptionKey,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FEATURES } from 'libs/features';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * カード再連携/完了
 */
const CuminCardRelinkComplete: FC = () => {
  const templateOptions = useRecoilValue<
    Record<CuminPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.CUMIN));
  const title =
    templateOptions?.relinkCompleteTitleContentOption?.content ?? '';
  const explanation =
    templateOptions?.relinkCompleteExplanationContentOption?.content ?? '';
  const imageUrl = templateOptions?.relinkCompleteImageOption?.image_url ?? '';

  return (
    <CuminCompletePage
      title={title}
      explanation={explanation}
      imageUrl={imageUrl}
      topPagePath={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.CUMIN}`}
    />
  );
};

export default CuminCardRelinkComplete;
