import { API_RESPONSE_STATUS } from 'libs/constants';
import { useState } from 'react';

import {
  cancelReservation,
  getReservationById,
  pickupReservation
} from 'features/reservations/api';
import { ReservationType } from 'features/reservations/types/ReservationType';
import { useEffectOnce } from 'react-use';

export const useReservation = (reservationId: number) => {
  const [reservation, setReservation] = useState<ReservationType | null>(null);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  useEffectOnce(() => {
    getReservationById(reservationId).then((res) => {
      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
        setReservation(res.data);
      }
      if (res.status === 404) {
        setIsNotFound(true);
      }
      setIsDone(true);
    });
  });

  const handleCancellation = () => cancelReservation(reservationId);

  const handlePickup = () => pickupReservation(reservationId);

  return {
    reservation,
    isDone,
    isNotFound,
    handleCancellation,
    handlePickup
  };
};
