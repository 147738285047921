import { useState } from 'react';
import { useDebounce as useDebounceByReactUse } from 'react-use';

/** react-useのuseDebounceに値を返却するようにラップしたhooks */
const useDebounce = (value: string | null, milliseconds: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const [isReady, cancel] = useDebounceByReactUse(
    () => {
      setDebouncedValue(value);
    },
    milliseconds,
    [value]
  );

  return { debouncedValue, isReady, cancel };
};

export default useDebounce;
