import {
  Box,
  Button,
  Container,
  Heading,
  Skeleton,
  VStack
} from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import CampaignGroupListItem from 'features/reservations/components/CampaignGroupListItem';
import { CampaignGroupListSkeletonBox } from 'features/reservations/components/SkeletonBoxes';
import { useCampaignGroup } from 'features/reservations/hooks/useCampaignGroup';
import { useCart } from 'features/reservations/hooks/useCart';
import { getTotalQuantity } from 'features/reservations/libs';
import { FC } from 'react';
import {
  Link as RouterLink,
  useParams,
  useSearchParams
} from 'react-router-dom';

/** キャンペーン詳細（キャンペーングループ一覧） */
const CampaignGroupsList: FC = () => {
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const storeId = searchParams.get('storeId') || '';

  const { campaignGroups, parentCampaignName, isDone } = useCampaignGroup(
    Number(campaignId)
  );
  const { cartProducts } = useCart(Number(campaignId));

  return (
    <MainLayout bg="dark.50" header>
      <Container px="1rem" mt="3rem" mb="3.5rem">
        <Skeleton isLoaded={isDone} h={isDone ? 'auto' : '1.25rem'} mb="1rem">
          <Heading size="md" mb="0.5rem">
            {parentCampaignName}
          </Heading>
        </Skeleton>

        <VStack gap="0.5rem">
          {isDone ? (
            campaignGroups.map((group) => (
              <CampaignGroupListItem
                key={group.id}
                group={group}
                storeId={storeId}
              />
            ))
          ) : (
            <>
              <CampaignGroupListSkeletonBox />
              <CampaignGroupListSkeletonBox />
              <CampaignGroupListSkeletonBox />
            </>
          )}
        </VStack>
      </Container>

      <Box w="100%" px="1rem" display="block" position="fixed" bottom="0.75rem">
        <RouterLink
          to={`/reservations/${campaignId}/cart`}
          state={{ storeId: Number(storeId) }}
        >
          <Button variant="primary-fullwidth-rounded-shadow">
            カートを見る ({getTotalQuantity(cartProducts)})
          </Button>
        </RouterLink>
      </Box>
    </MainLayout>
  );
};

export default CampaignGroupsList;
