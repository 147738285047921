import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  HStack,
  Text
} from '@chakra-ui/react';
import { PinIcon } from 'assets/icons/miscs';
import { StoreType } from 'features/my-store/types/StoreType';
import { FC } from 'react';
import StoreListItem from './StoreListItem';

type StoreListAccordionType = {
  prefectureName: string;
  stores: Array<
    StoreType & {
      isMyStore?: boolean;
    }
  >;
  handleAddMyStore: (id: number) => void;
  handleRemoveMyStore: (id: number) => void;
};

const StoreListAccordion: FC<StoreListAccordionType> = ({
  prefectureName,
  stores,
  handleAddMyStore,
  handleRemoveMyStore
}) => {
  return (
    <AccordionItem>
      <AccordionButton py="0.75rem">
        <HStack flex="1" textAlign="left">
          <PinIcon boxSize="1rem" color="dark.400" />
          <Text fontSize="md">{prefectureName}</Text>
        </HStack>
        <Badge>{stores.length}</Badge>
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel py="1rem">
        {stores.map((store) => (
          <StoreListItem
            key={store.id}
            {...store}
            isMyStore={store.isMyStore ?? false}
            handleAddMyStore={handleAddMyStore}
            handleRemoveMyStore={handleRemoveMyStore}
          />
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default StoreListAccordion;
