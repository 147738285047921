import { Text } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import { handleAnchorClick } from 'libs/line';
import { FC } from 'react';

type LinkTextProps = {
  variant: 'primary' | 'secondary';
  content: string;
  fontSize?: string;
  fontWeight?: number | string;
};

/**
 * リンクを含むテキスト表示用コンポーネント
 */
const LinkText: FC<LinkTextProps> = ({
  variant,
  content,
  fontSize = 'md',
  fontWeight = 400
}) => {
  const cleanContent = DOMPurify.sanitize(content, {
    ALLOWED_TAGS: ['a'],
    ALLOWED_ATTR: ['href', 'target', 'rel']
  });
  return (
    <Text
      fontWeight={fontWeight}
      fontSize={fontSize}
      wordBreak="break-all"
      whiteSpace="pre-wrap"
      dangerouslySetInnerHTML={{
        __html: cleanContent
      }}
      css={{
        a: {
          color:
            variant === 'secondary'
              ? 'var(--chakra-colors-secondary)'
              : 'var(--chakra-colors-primary)'
        }
      }}
      onClick={handleAnchorClick}
    />
  );
};

export default LinkText;
