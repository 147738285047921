import SimpleSignUpTopPage from 'features/membership/components/simple/SimpleSignupTopPage';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { membershipSimpleSettingOptionsState } from 'features/membership/libs/recoil/selector';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * 会員登録トップ
 */
const SimpleSignupTop: FC = () => {
  const templateOptions = useRecoilValue(membershipSimpleSettingOptionsState);

  return (
    <SimpleSignUpTopPage
      templateAliasCode={TEMPLATE_ALIAS_CODE.SIMPLE}
      templateOptions={templateOptions}
    />
  );
};

export default SimpleSignupTop;
