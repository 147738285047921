import { Button, Card, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { CycleIcon } from 'assets/icons/miscs';
import dayjs from 'dayjs';
import { FC, MouseEventHandler } from 'react';

type GingerPointBoxProps = {
  pointDisplay: {
    pointName?: string | null;
    pointBalance?: number;
    pointUnit?: string | null;
    unavailableHour?: string;
  };
  expiredDateDisplay: {
    pointExpiredDate?: string;
    showPointExpiredDate?: boolean;
  };
  isCardPointMutating: boolean;
  onUpdateButtonClick: MouseEventHandler;
};

/**
 * VD+CSD CSDポイント残高表示コンポーネント
 *
 * @params
 * pointDisplay ポイント表示に関する情報をまとめたObject \
 *   pointName CSDポイントセクションのタイトル テンプレート設定 \
 *   pointBalance CSDポイント APIから受け取り \
 *   pointUnit CSDポイントの単位 テンプレート設定 \
 *   unavailableHour CSDポイント取得時間外の時間帯情報 \
 * expiredDateDisplay 有効期限表示に関する情報をまとめたObject
 *   pointExpiredDate CSDポイントの有効期限 APIから受け取り \
 *   showPointExpiredDate CSDポイントの有効期限表示フラグ テンプレート設定 \
 * isCardPointMutating ポイント残高表示API（CSD連携）のmutate状態 \
 * onUpdateButtonClick 更新ボタン押下時の処理 \
 */
const GingerPointBox: FC<GingerPointBoxProps> = ({
  pointDisplay: { pointName, pointBalance, pointUnit, unavailableHour },
  expiredDateDisplay: { pointExpiredDate, showPointExpiredDate },
  isCardPointMutating,
  onUpdateButtonClick
}) => (
  <Card px="1.25rem" py="1rem" boxShadow="xs" borderColor="dark.300">
    <Flex m="0" w="100%" alignItems="center" justifyContent="space-between">
      <Stack gap="0.25rem">
        {/* NOTE: デフォルト値設定はしない */}
        {!!pointName && (
          <Text fontSize="md" wordBreak="break-all" whiteSpace="pre-wrap">
            {pointName}
          </Text>
        )}
        <Skeleton isLoaded={!isCardPointMutating}>
          {!unavailableHour ? (
            <Text fontSize="2xl" fontWeight="bold" data-testid="point-element">
              {pointBalance?.toLocaleString() ?? '-'}
              {/* NOTE: デフォルト値設定はしない */}
              {!!pointUnit && (
                <Text
                  as="span"
                  fontSize="lg"
                  fontWeight="400"
                  wordBreak="break-all"
                  whiteSpace="pre-wrap"
                >
                  {pointUnit}
                </Text>
              )}
            </Text>
          ) : (
            <Text fontSize="xl" fontWeight="bold">
              {/* サービス時間外がAPIから返却されている場合は表示 */}
              {unavailableHour}は<br />
              表示できません
            </Text>
          )}
        </Skeleton>
        {/** ポイント有効期限表示が管理画面側でONに設定している場合は表示 */}
        {showPointExpiredDate && (
          <Skeleton isLoaded={!isCardPointMutating}>
            <Text fontSize="md" color="dark.400">
              有効期限{' '}
              {pointExpiredDate
                ? dayjs(pointExpiredDate).format('YYYY/MM/DD')
                : '----/--/--'}
            </Text>
          </Skeleton>
        )}
      </Stack>
      {/* 更新ボタン */}
      <Button
        mt="auto"
        type="button"
        variant="link"
        fontWeight="400"
        color="dark.400"
        p="0"
        size="lg"
        leftIcon={<CycleIcon color="dark.400" boxSize="1.5rem" />}
        onClick={onUpdateButtonClick}
        isLoading={isCardPointMutating}
      >
        更新
      </Button>
    </Flex>
  </Card>
);
export default GingerPointBox;
