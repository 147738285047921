import {
  getRequestHeader,
  handleMoveToErrorPage,
  handleResetLocalStorage
} from 'api';
import axios from 'axios';
import { ErrorResponse } from 'features/membership/types';
import { FEATURES_API_ENDPOINT, TENANT_CODE } from 'libs/constants';
import { GetLinksResponse } from 'types/LinkType';

const menuApi = axios.create({
  baseURL: `${FEATURES_API_ENDPOINT}/${TENANT_CODE}`
});

menuApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // NOTE: Move the user to /error page in case of the error response with 500 status.
    if (error.response?.status === 500) {
      handleMoveToErrorPage('server-error');
      return;
    }

    // NOTE: Move the user to session expired page in case of the error response with "IdToken expired." message
    // NOTE: Extract cart value in localstorage to prevent remove the value on reset localstorage.
    if (error.response) {
      const errorObject = error.response.data?.errors;
      if (errorObject.includes('IdToken expired.')) {
        handleResetLocalStorage();
        handleMoveToErrorPage('session-expired');
        return;
      }
    }
    return Promise.reject(error);
  }
);

/**
 * リンク集取得API
 */
export const getLinks = (): Promise<GetLinksResponse | ErrorResponse> =>
  menuApi
    .request<GetLinksResponse>({
      url: '/link/links',
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status
    }));
