import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

type HorizontalLabeledValueProps = {
  label: string;
  value: string;
};

const HorizontalLabeledValue: FC<HorizontalLabeledValueProps> = ({
  label,
  value
}) => {
  return (
    <Flex gap="0.5rem" wordBreak="break-all" whiteSpace="pre-wrap">
      <Text flex={1} fontSize="md" fontWeight={400} color="dark.300">
        {label}
      </Text>
      <Text flex={1} fontSize="md" fontWeight={400}>
        {value}
      </Text>
    </Flex>
  );
};

export default HorizontalLabeledValue;
