import {
  getRequestHeader,
  handleMoveToErrorPage,
  handleResetLocalStorage
} from 'api';
import axios from 'axios';
import {
  DigiclueplusTokenResponse,
  ErrorResponse,
  ServiceCode
} from 'features/digiclueplus/types';
import { DIGICLUEPLUS_API_ENDPOINT, TENANT_CODE } from 'libs/constants';

export const digiclueplusApi = axios.create({
  baseURL: `${DIGICLUEPLUS_API_ENDPOINT}/${TENANT_CODE}/digiclueplus`
});

digiclueplusApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // サーバーのエラーメッセージが表示されるエラー画面へ
    if (error.response?.status === 500) {
      handleMoveToErrorPage('server-error');
      return;
    }

    // セッションの有効期限切れのエラーメッセージが表示されるエラー画面へ
    if (error.response) {
      const errorObject = error.response.data?.errors;
      if (errorObject.includes('IdToken expired.')) {
        handleResetLocalStorage();
        handleMoveToErrorPage('session-expired');
        return;
      }
    }

    // 上記2ケース以外は共通のエラーメッセージが表示されるエラー画面へ
    handleMoveToErrorPage('');
    return;
  }
);

/** デジクルプラスのサービスURL取得API */
export const createDigiclueplusToken = (
  serviceCode?: ServiceCode,
  servicePath?: string
): Promise<DigiclueplusTokenResponse | ErrorResponse> =>
  digiclueplusApi
    .request<DigiclueplusTokenResponse>({
      url: '/users/current/token',
      method: 'POST',
      data: serviceCode
        ? { service_code: serviceCode }
        : { service_path: servicePath },
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);
