import {
  Button,
  Container,
  Heading,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import { useMyStore } from 'features/my-store/hooks/useMyStore';
import { useStore } from 'features/my-store/hooks/useStore';
import { StoreType } from 'features/my-store/types/StoreType';
import NotificationListItemWrapper from 'features/notification/components/NotificationListItemWrapper';
import { useNotificationList } from 'features/notification/hooks/useNotificationList';
import { reactSelectStyles } from 'libs/react-select';
import { FC } from 'react';
import Select from 'react-select';

/** お知らせ一覧 */
const NotificationList: FC = () => {
  const {
    displayedNotificationList,
    unreadIds,
    setCurrentStoreId,
    readNotification,
    isReadNotificationMutating,
    isNotificationListMutating
  } = useNotificationList();

  const {
    stores,
    isDone: isDoneStores,
    isFeatureActive: isStoreActive
  } = useStore();
  const { myStores, isDone: isDoneMyStores } = useMyStore();

  const isStoresLoaded = isDoneStores && isDoneMyStores;

  //ToDo:CampaignsListのgenerateStoreOptionと共通化
  const generateStoreOption = (store: StoreType) => ({
    value: store.id,
    label: store.name
  });

  return (
    <MainLayout bg="dark.50" header footer>
      <Container px="1rem" pt="2.25rem">
        <Heading size="md" mb="1rem" display="flex">
          お知らせ
          <Skeleton marginLeft="auto" isLoaded={!isNotificationListMutating}>
            <Button
              variant="primary-link"
              h="inherit"
              p={0}
              onClick={() => readNotification(unreadIds)}
              isDisabled={isReadNotificationMutating || unreadIds.length === 0}
            >
              すべて既読にする
            </Button>
          </Skeleton>
        </Heading>
        {/* 店舗選択 */}
        {isStoreActive && (
          <Skeleton
            mb="1.5rem"
            isLoaded={isStoresLoaded}
            data-testid="shop-select"
          >
            <Select<{
              value: number;
              label: string;
            }>
              options={[
                {
                  label: 'マイ店舗',
                  options: myStores.map(generateStoreOption)
                },
                ...stores.map(({ prefecture, stores }) => ({
                  label: prefecture,
                  options: stores.map(generateStoreOption)
                }))
              ]}
              onChange={(option) => {
                setCurrentStoreId(option?.value ?? null);
              }}
              onFocus={(e) => e.currentTarget.scrollIntoView()}
              styles={reactSelectStyles}
              placeholder="店舗を選択してください"
              noOptionsMessage={() => (
                <Text fontSize="md">検索されている店舗名はありません</Text>
              )}
              isMulti={false}
              isClearable
              isSearchable
            />
          </Skeleton>
        )}
        {/* お知らせ一覧 */}
        <VStack gap="0.5rem">
          <NotificationListItemWrapper
            isLoaded={!isNotificationListMutating}
            notificationList={displayedNotificationList}
            unreadIds={unreadIds}
            readNotification={readNotification}
          />
        </VStack>
      </Container>
    </MainLayout>
  );
};

export default NotificationList;
