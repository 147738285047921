import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

type SectionMessageItemType = {
  label: string;
  value: string;
};

export const SectionMessageItem: FC<SectionMessageItemType> = ({
  label,
  value
}) => (
  <Flex fontWeight="400" my="0.25rem">
    <Text fontSize="md" width="5.5rem" mb="0">
      {label}
    </Text>
    <Text
      fontSize="md"
      mb="0"
      flex="1"
      wordBreak="break-all"
      whiteSpace="pre-wrap"
    >
      {value}
    </Text>
  </Flex>
);

type SectionMessageType = {
  variant: 'primary' | 'danger';
  children: ReactNode;
};

export const SectionMessage: FC<SectionMessageType> = ({
  variant,
  children
}) => (
  <Box
    width="100%"
    bg={variant === 'danger' ? 'red.100' : 'primary30'}
    px="1rem"
    py="0.75rem"
    borderRadius="6px"
    mb="1rem"
  >
    {children}
  </Box>
);

type SquareSectionMessageType = {
  variant: 'message' | 'danger';
  children: ReactNode;
};

/** 帯状メッセージコンポーネント */
export const SquareSectionMessage: FC<SquareSectionMessageType> = ({
  variant,
  children
}) => (
  <Box
    width="100vw"
    bg={variant === 'danger' ? 'red.100' : 'message'}
    px="1rem"
    py="0.75rem"
    mb="1rem"
  >
    {children}
  </Box>
);
