import CuminCardRenewalCardGroup from 'features/membership/components/cumin/CuminCardRenewalCardGroup';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { EARTH_FEATURE_CODES } from 'features/membership/libs/const/plugins/earthFeatureCodes';
import { MARS_FEATURE_CODES } from 'features/membership/libs/const/plugins/marsFeatureCodes';
import {
  GingerPagePageTemplateOptionKey,
  membershipPluginFeatureState,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FEATURES } from 'libs/features';
import _ from 'lodash';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * VD+CSD カード紛失・再発行
 */
const GingerCardRenewal: FC = () => {
  const templateOptions = useRecoilValue<
    Record<GingerPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.GINGER));

  const pluginState = useRecoilValue(membershipPluginFeatureState);
  const isReissueFeatureActive = Boolean(
    pluginState.mars?.includes(_.camelCase(MARS_FEATURE_CODES.REISSUE)) // Mars pluginを利用
  );
  const isRelinkFeatureActive = Boolean(
    pluginState.mars?.includes(_.camelCase(MARS_FEATURE_CODES.RELINK)) // Mars pluginを利用
  );
  const isGetMemberFeatureActive = Boolean(
    pluginState.earth?.includes(_.camelCase(EARTH_FEATURE_CODES.GET_MEMBER)) // Earth pluginを利用
  );

  return (
    <CuminCardRenewalCardGroup
      templateOptions={templateOptions}
      isReissueFeatureActive={isReissueFeatureActive}
      isRelinkFeatureActive={isRelinkFeatureActive}
      isGetMemberFeatureActive={isGetMemberFeatureActive}
      topPagePath={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.GINGER}`}
    />
  );
};

export default GingerCardRenewal;
