import { Heading, Image, Skeleton, SkeletonText } from '@chakra-ui/react';
import { FALLBACK_IMAGES } from 'libs/constants';
import React, { FC } from 'react';

type CampaignHeroSectionType = {
  title?: string;
  imgSrc?: string;
  isLoaded?: boolean;
};

const CampaignHeroSection: FC<CampaignHeroSectionType> = ({
  title,
  imgSrc,
  isLoaded = false
}) => {
  return (
    <>
      <Skeleton
        flexShrink="0"
        h="9rem"
        mb="1rem"
        w="100%"
        bg="dark.50"
        isLoaded={isLoaded}
      >
        <Image
          boxSize="100%"
          objectFit="contain"
          loading="lazy"
          src={imgSrc ?? ''}
          fallbackSrc={FALLBACK_IMAGES.rect}
          alt="キャンペーン画像"
        />
      </Skeleton>
      {title && (
        <SkeletonText px="1rem" isLoaded={isLoaded} noOfLines={1} mb="1rem">
          <Heading fontSize="1rem">{title}</Heading>
        </SkeletonText>
      )}
    </>
  );
};

export default React.memo(CampaignHeroSection);
