import { Text, VStack } from '@chakra-ui/react';
import { ReservationPrepaidNotification } from 'features/reservations/components/CaveatsSections';
import { FC } from 'react';

const PreReservedUndueCaution: FC<{
  paymentDeadLineAt: string;
}> = ({ paymentDeadLineAt }) => (
  <VStack spacing="1.5rem">
    <Text color="danger" fontSize="lg" fontWeight="400">
      まだご予約は完了していません
    </Text>
    <ReservationPrepaidNotification dueDate={paymentDeadLineAt} />
  </VStack>
);

export default PreReservedUndueCaution;
