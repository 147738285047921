import { yupResolver } from '@hookform/resolvers/yup';
import { HALF_WIDTH_NUMBER_VALIDATION_MESSAGE } from 'features/membership/libs/validate';
import { FormEventHandler, useEffect } from 'react';
import {
  FormState,
  useForm,
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

export type Inputs = {
  card_number: string;
};

export type UseSimpleFormProps = {
  useFormReturn: {
    register: UseFormRegister<Inputs>;
    setValue: UseFormSetValue<Inputs>;
    formState: FormState<Inputs>;
  };
  toConfirmPage: (
    confirmPagePath: string
  ) => () => FormEventHandler<HTMLDivElement>;
};

/**
 * Simpleの会員登録・編集画面で利用するhooksをまとめたCustom Hook
 */
export const useSimpleForm = (
  defaultCardNumber?: string
): UseSimpleFormProps => {
  const navigate = useNavigate();

  const schema = yup
    .object()
    .shape({
      card_number: yup
        .string()
        .required('入力してください')
        .matches(/^\d{1,255}$/, HALF_WIDTH_NUMBER_VALIDATION_MESSAGE)
    })
    .required();

  const { register, handleSubmit, setValue, formState, reset, getValues } =
    useForm<Inputs>({
      resolver: yupResolver(schema),
      mode: 'onChange',
      defaultValues:
        typeof defaultCardNumber === 'string'
          ? { card_number: defaultCardNumber }
          : undefined
    });

  // NOTE: formの初期化より遅いタイミングで初期値取得する場合、初期値はうまく入らないので resetで設定する
  useEffect(() => {
    // NOTE: まだ入力、設定されない場合のみ、初期値設定する
    if (getValues('card_number')?.length || !defaultCardNumber) {
      return;
    }
    reset({
      card_number: defaultCardNumber
    });
  }, [defaultCardNumber, getValues, reset]);

  // NOTE: 共通コンポーネント内でhandleSubmitを実行する必要があるので高階層関数を定義
  const toConfirmPage = (confirmPagePath: string) => () =>
    handleSubmit(({ card_number }) =>
      navigate(confirmPagePath, { state: card_number })
    );

  return {
    useFormReturn: { register, setValue, formState },
    toConfirmPage
  };
};
