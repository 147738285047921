import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { UpdateIcon } from 'assets/icons/miscs';
import { FC } from 'react';

const UpdateButton: FC<ButtonProps> = (props) => (
  <Button
    variant="dark-outline"
    borderRadius="44px"
    borderColor="#E3E3E3"
    color="dark.400"
    px="0.75rem"
    py="0.375rem"
    {...props}
    fontSize="md"
  >
    <Icon as={UpdateIcon} me="0.25rem" />
    更新
  </Button>
);

export default UpdateButton;
