import { patchEarthMembershipLinkCard } from 'features/membership/api';
import CuminCardLinkConfirmPage from 'features/membership/components/cumin/CuminCardLinkConfirmPage';
import useCardLinkConfirm from 'features/membership/hooks/cumin/useCardLinkConfirm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { CardLinkFormInputs } from 'features/membership/types/form';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

/**
 * カード再連携/確認
 */
const CuminCardRelinkConfirm: FC = () => {
  const location = useLocation();
  const state: null | { formData: CardLinkFormInputs } = location.state;

  const formPagePath = `/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/card-renewal/relink`;
  const completePagePath = `/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/card-renewal/relink/complete`;
  const { onClickRegisterButton, onClickBackButton, isMutating } =
    useCardLinkConfirm(
      state,
      completePagePath,
      formPagePath,
      patchEarthMembershipLinkCard
    );

  // 入力値が取得できない場合、入力画面へリダイレクト
  if (!state?.formData) return <Navigate to={formPagePath} />;

  return (
    <CuminCardLinkConfirmPage
      onClickRegisterButton={onClickRegisterButton}
      onClickBackButton={onClickBackButton}
      isMutating={isMutating}
      formData={state.formData}
    />
  );
};

export default CuminCardRelinkConfirm;
