import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text
} from '@chakra-ui/react';
import { MinusIcon, PlusIcon } from 'assets/icons/miscs';
import StringToJsx from 'components/modules/StringToJsx';
import dispatchToast from 'components/modules/Toast';
import dayjs from 'dayjs';
import { checkStocksForReservation } from 'features/reservations/api';
import { ProductType } from 'features/reservations/types/ProductType';
import {
  API_RESPONSE_STATUS,
  FALLBACK_IMAGES,
  NOW_LOADING
} from 'libs/constants';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type ProductDetailModalProps = {
  isOpen: boolean;
  product: ProductType;
  productStatus?:
    | 'isLoading'
    | 'available'
    | 'outOfReservationPeriod'
    | 'outOfStock';
  existingQuantityInCart?: number;
  maxQuantity: number;
  onClose: () => void;
  addProduct?: ({ id, quantity }: { id: number; quantity: number }) => void;
};

export type PassingCartStateType = {
  state: {
    campaignId: number;
    campaignGroupId: number;
    storeId: number;
    products: { id: number; quantity: number }[];
    createdAt: string;
    updatedAt: string;
  };
};

const CAVEATS = {
  isLoading: NOW_LOADING,
  available: '',
  outOfReservationPeriod: '予約期間外のため予約できません',
  outOfStock: '在庫切れです'
};

const ProductDetailModal: FC<ProductDetailModalProps> = ({
  isOpen,
  product,
  productStatus = 'isLoading',
  existingQuantityInCart = 0,
  maxQuantity,
  onClose,
  addProduct
}) => {
  const [quantity, setQuantity] = useState<number>(0);
  const { campaignId } = useParams();
  const [showExceeded, setShowExceeded] = useState<boolean>(false);
  const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setQuantity(0);
    }
  }, [isOpen]);

  const formatDate = useCallback(
    (datetimeStr: string) => dayjs(datetimeStr).format('YYYY/MM/DD'),
    []
  );

  const handleDecrement = useCallback(() => {
    setQuantity((prev) => (prev > 0 ? prev - 1 : 0));
    setShowExceeded(false);
  }, []);

  const handleIncrement = useCallback(() => {
    if (maxQuantity - existingQuantityInCart - quantity > 0) {
      setQuantity((prev) => prev + 1);
      setShowExceeded(false);
    } else {
      setShowExceeded(true);
    }
  }, [existingQuantityInCart, maxQuantity, quantity]);

  const handleChangeCartProduct = () => {
    setIsAddingToCart(true);
    checkStocksForReservation({
      campaign_id: Number(campaignId),
      product_id: product.id,
      quantity
    }).then((res) => {
      if (res.status === API_RESPONSE_STATUS.SUCCEEDED && addProduct) {
        addProduct({ id: product.id, quantity });
        onClose();
      } else {
        dispatchToast({
          id: 'toast-reservation-handle-change-cart-error',
          toastText: `エラー：${res.errors.join(', ')}`,
          center: true
        });
      }
      setIsAddingToCart(false);
    });
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent h="80vh" position="relative" mx="1rem" display="block">
        <Box
          h={showExceeded ? 'calc(78vh - 7.75rem)' : 'calc(80vh - 7.75rem)'}
          pb="0.5rem"
          overflowY="scroll"
          borderRadius="0.5rem"
          maxWidth="calc(100vw - 2rem)"
        >
          <Box h="15rem" w="100%" bg="dark.50" position="relative">
            <IconButton
              aria-label="Close modal"
              icon={<CloseIcon color="white" boxSize="1rem" />}
              w="2rem"
              bg="rgba(51, 51, 51, 0.6)"
              borderRadius="50%"
              position="absolute"
              top="0.5rem"
              left="0.5rem"
              _hover={{ bg: 'rgba(51, 51, 51, 0.6)' }}
              _focus={{ bg: 'rgba(51, 51, 51, 0.6)' }}
              _active={{ bg: 'rgba(51, 51, 51, 0.6)' }}
              onClick={onClose}
            />
            <Image
              boxSize="100%"
              objectFit="contain"
              loading="lazy"
              src={product.image_file_urls[0]?.image_file_url ?? ''}
              fallbackSrc={FALLBACK_IMAGES.rect}
              alt="商品画像"
            />
          </Box>
          <ModalHeader>
            <Heading size="md" mb="0.5rem">
              {product.name}
            </Heading>
            <Box alignItems="center">
              <Text fontSize="lg" display="inline">
                {product.price_with_tax.toLocaleString()}
              </Text>
              <Text fontSize="sm" display="inline" ms="0.25rem">
                円 税込
              </Text>
            </Box>
          </ModalHeader>
          <ModalBody>
            <Box mb="1rem" bg="primary30" p="0.75rem" borderRadius="4px">
              <Text>
                <Text as="span" me="0.75rem">
                  予約期間
                </Text>
                <Text as="span">
                  {formatDate(product.reservation_start_at)}〜
                  {formatDate(product.reservation_end_at)}
                </Text>
              </Text>
              <Text>
                <Text as="span" me="0.75rem">
                  受取期間
                </Text>
                <Text as="span">
                  {formatDate(product.reception_start_at)}〜
                  {formatDate(product.reception_end_at)}
                </Text>
              </Text>
            </Box>
            <StringToJsx text={product.description} />
          </ModalBody>
        </Box>

        <ModalFooter
          w="100%"
          h={showExceeded ? '11rem' : '7.75rem'}
          borderTop="1px solid"
          borderTopColor="dark.50"
          bg="white"
          flexDir="column"
          justifyContent="center"
          borderBottomRadius="1rem"
        >
          {productStatus === 'available' ? (
            <>
              <HStack alignItems="center" mb="0.75rem">
                <IconButton
                  variant="primary"
                  w="2rem"
                  borderRadius="50%"
                  aria-label="Decrease"
                  icon={<MinusIcon color="white" boxSize={4} />}
                  isDisabled={quantity <= 0}
                  onClick={handleDecrement}
                />
                <Heading fontSize="4xl" w="4rem" pt="-4px" textAlign="center">
                  {quantity}
                </Heading>
                <IconButton
                  variant="primary"
                  w="2rem"
                  borderRadius="50%"
                  aria-label="Increase"
                  icon={<PlusIcon color="white" boxSize={4} />}
                  isDisabled={quantity >= maxQuantity}
                  onClick={handleIncrement}
                />
              </HStack>
              {showExceeded && (
                <Flex
                  justifyContent="center"
                  bg="pink.50"
                  w="100%"
                  mb="1rem"
                  px="1rem"
                  py="0.25rem"
                >
                  <Text color="danger" fontSize="sm" fontWeight="700">
                    同じ商品は20個まで予約できます
                  </Text>
                </Flex>
              )}
              <Button
                variant="primary-fullwidth-rounded"
                isDisabled={quantity <= 0 || isAddingToCart}
                onClick={handleChangeCartProduct}
                isLoading={isAddingToCart}
                loadingText="カートに追加中..."
              >
                カートに追加する
              </Button>
            </>
          ) : (
            <Skeleton isLoaded={productStatus !== 'isLoading'}>
              <Box bg="dark.50" w="100%" my="1rem" p="1rem">
                <Text color="dark.400" fontSize="md" fontWeight="700">
                  {CAVEATS[productStatus] ?? ''}
                </Text>
              </Box>
            </Skeleton>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(ProductDetailModal);
