import { Heading, Image, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';

type CuminTitleImageNoticeProps = Partial<{
  title: string | null;
  imageUrl: string | null;
  notice: string | null;
}>;

const CuminTitleImageNotice: FC<CuminTitleImageNoticeProps> = ({
  title,
  imageUrl,
  notice
}) => (
  <Stack spacing="1rem">
    {/* タイトル */}
    {title && (
      <Heading
        as="h1"
        fontSize="xl"
        fontWeight="700"
        wordBreak="break-all"
        whiteSpace="pre-wrap"
      >
        {title}
      </Heading>
    )}
    {/* 画像 */}
    {imageUrl && (
      <Image
        src={imageUrl}
        width="100%"
        height="13rem"
        objectFit="contain"
        alt="カード画像"
        borderRadius="10px"
      />
    )}
    {/* 説明文言 */}
    {notice && (
      <Text fontSize="lg" wordBreak="break-all" whiteSpace="pre-wrap">
        {notice}
      </Text>
    )}
  </Stack>
);
export default CuminTitleImageNotice;
