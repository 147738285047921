import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Container,
  Heading,
  HStack,
  Icon,
  Stack,
  Text
} from '@chakra-ui/react';
import { WarningOutlineIcon } from 'assets/icons/miscs';
import {
  SectionMessage,
  SquareSectionMessage
} from 'components/modules/SectionMessage';
import dayjs from 'dayjs';

export const PrepaidInformationSection = ({ dueDate }: { dueDate: string }) => (
  <Container px="1rem">
    <SectionMessage variant="primary">
      <Heading fontSize="lg" color="primary" textAlign="center" mb="0.5rem">
        事前店舗支払い期日：
        {dayjs(dueDate).format('YYYY/MM/DD')}
      </Heading>
      <Text fontWeight="400">
        受取店舗で事前の決済が必要です。期日までに支払いが確認できない場合、仮予約は自動でキャンセルとなりますのでご注意ください。
      </Text>
    </SectionMessage>
  </Container>
);

export const PayInformationSection = () => (
  <SquareSectionMessage variant="message">
    <HStack alignItems="center">
      <InfoOutlineIcon boxSize="28px" me="0.5rem" color="warning" />
      <Heading fontSize="lg" fontWeight="700" textAlign="start">
        受取当日に店舗でお支払いを行ってください
      </Heading>
    </HStack>
  </SquareSectionMessage>
);

export const ReservationPrepaidNotification = ({
  dueDate
}: {
  dueDate: string;
}) => (
  <SquareSectionMessage variant="message">
    <HStack alignItems="center">
      <InfoOutlineIcon boxSize="28px" me="0.5rem" color="warning" />
      <Stack fontWeight="400" textAlign="start" spacing="4px">
        <Heading fontSize="lg" fontWeight="700">
          事前に店舗でのお支払いが必要です
        </Heading>
        <Text fontSize="md">
          期日までに支払いが確認できない場合、自動でキャンセルとなりますのでご注意ください。
        </Text>
        <Text fontSize="md" color="dark.400">
          支払期限
          <Text
            as="span"
            fontWeight="700"
            ml="10px"
            fontSize="md"
            color="dark.500"
          >
            {dayjs(dueDate).format('YYYY年MM月DD日')}
          </Text>
        </Text>
      </Stack>
    </HStack>
  </SquareSectionMessage>
);

export const ReservationPrepaidOverdue = ({ dueDate }: { dueDate: string }) => (
  <SquareSectionMessage variant="danger">
    <HStack alignItems="center">
      <Icon as={WarningOutlineIcon} boxSize="28px" me="0.5rem" />
      <Stack fontWeight="400" textAlign="start" spacing="4px">
        <Heading fontSize="lg" fontWeight="700">
          支払い期限が過ぎています。早急にお支払いください。
        </Heading>
        <Text fontSize="md" color="dark.400">
          支払期限
          <Text
            as="span"
            fontWeight="700"
            ml="10px"
            fontSize="md"
            color="dark.500"
          >
            {dayjs(dueDate).format('YYYY年MM月DD日')}
          </Text>
        </Text>
      </Stack>
    </HStack>
  </SquareSectionMessage>
);

export const PaidInformation = () => (
  <SquareSectionMessage variant="message">
    <HStack alignItems="center">
      <InfoOutlineIcon boxSize="28px" me="0.5rem" color="warning" />
      <Stack fontWeight="400" textAlign="start" spacing="4px">
        <Heading fontSize="lg" fontWeight="700">
          お支払いが完了しました
        </Heading>
        <Text fontSize="md">
          ご予約の受取日時に店舗にて商品をお受け取りください。
        </Text>
      </Stack>
    </HStack>
  </SquareSectionMessage>
);
