import { Box, Heading, Image } from '@chakra-ui/react';
import { SimpleCampaignGroupType } from 'features/reservations/types/CampaignType';
import { FALLBACK_IMAGES } from 'libs/constants';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type CampaignGroupListItemProps = {
  group: SimpleCampaignGroupType;
  storeId: string;
};

const CampaignGroupListItem: FC<CampaignGroupListItemProps> = ({
  group,
  storeId
}) => {
  return (
    <Box w="100%" bg="white" borderRadius="0.5rem">
      <RouterLink to={`${group.id}?storeId=${storeId}`}>
        <Box
          h="7rem"
          w="100%"
          bg="gray.50"
          borderTopRadius="0.5rem"
          overflow="hidden"
        >
          <Image
            boxSize="100%"
            objectFit="contain"
            src={group.image_file_urls[0]?.image_file_url ?? ''}
            fallbackSrc={FALLBACK_IMAGES.rect}
            alt="グループ画像"
          />
        </Box>
        <Box p="0.75rem" pb="0.5rem">
          <Heading color="primary" size="md" mb="0.5rem">
            {group.name}
          </Heading>
        </Box>
      </RouterLink>
    </Box>
  );
};

export default CampaignGroupListItem;
