import liff from '@line/liff';
import { MouseEventHandler } from 'react';

/**
 * @param url - url string
 * @param external - 外部ブラウザーで開く
 * @returns void
 */
export const openWindow = (url: string, external?: boolean) => {
  if (url) {
    liff.openWindow({
      url: url,
      external: external ?? false
    });
  }
};

/**
 * リンク開く時動作統一する用の クリック関数
 * 汎用性を考えHTMLParagraphElementじゃなくてHTMLElementにした
 */
export const handleAnchorClick: MouseEventHandler<HTMLElement> = (event) => {
  event.preventDefault();

  const target = event.target as HTMLAnchorElement;
  if (target.tagName !== 'A') return;

  // 他の箇所の外部リンクがLIFFブラウザで開くようにしているので、ここもLIFFブラウザで開くようにする
  openWindow(target.href);
};
