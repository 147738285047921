import { handleMoveToErrorPage } from 'api';
import MainLayout from 'components/layouts/MainLayout';
import { createDigiclueplusToken } from 'features/digiclueplus/api';
import { API_RESPONSE_STATUS, NOW_LOADING } from 'libs/constants';
import { openWindow } from 'libs/line';
import {
  digiclueplusRedirectParamsAtom,
  tenantConfigurationAtom
} from 'libs/recoil/atom';
import { FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const RedirectDigiclueplus: FC = () => {
  /** tenantConfiguration と wall_path 更新による React.StrictMode での2度目の読み込みを防止するためのフラグ */
  const ignoreEffect = useRef(false);

  const navigate = useNavigate();

  const tenantConfiguration = useRecoilValue(tenantConfigurationAtom);
  const digiclueplusRedirectParams = useRecoilValue(
    digiclueplusRedirectParamsAtom
  );
  const wall_path = digiclueplusRedirectParams.wall_path;

  useEffect(() => {
    (async () => {
      // code と name のどちらかあれば取得とみなす（Top.tsx や MembershipRoot.tsx と同じ判定条件にする）
      const hasTenantConfiguration =
        tenantConfiguration.code || tenantConfiguration.name;

      // 「読み込み中...」を表示させる
      if (ignoreEffect.current || !hasTenantConfiguration || !wall_path) return;

      ignoreEffect.current = true;

      const isDigiclueplusActive = tenantConfiguration.feature_objects.some(
        (object) => object.code === 'digiclueplus'
      );

      if (!isDigiclueplusActive) {
        // エラー画面へ
        return handleMoveToErrorPage('');
      }

      const digiclueplusTokenRes = await createDigiclueplusToken(
        undefined,
        wall_path.slice(1) // 先頭の '/' を取り除く
      );

      // interceptors 側の処理でエラー画面へ
      if (digiclueplusTokenRes.status === API_RESPONSE_STATUS.FAILED) return;

      // デジクルプラス画面へ
      openWindow(digiclueplusTokenRes.data.service_url, false);

      // デジクルプラス画面からLINEミニアプリに戻った時に表示する画面（トップ）へ
      navigate('/');
    })();
  }, [wall_path, navigate, tenantConfiguration]);

  return <MainLayout pageTitle={NOW_LOADING}>{NOW_LOADING}</MainLayout>;
};

export default RedirectDigiclueplus;
