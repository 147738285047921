import {
  createMyStore,
  deleteMyStore,
  getMyStores
} from 'features/my-store/api';
import { StoreType } from 'features/my-store/types/StoreType';
import { activeFeatureCodesState } from 'libs/recoil/selector';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

export const useMyStore = () => {
  const [isDone, setIsDone] = useState<boolean>(false);
  const [myStores, setMyStores] = useState<StoreType[]>([]);
  const activeFeatureCodes = useRecoilValue(activeFeatureCodesState);
  const isFeatureActive = activeFeatureCodes.includes('store');

  useEffect(() => {
    if (isFeatureActive) {
      getMyStores().then((res) => {
        setMyStores(res);
        setIsDone(true);
      });
    }
  }, [isFeatureActive]);

  const addMyStore = useCallback((id: number) => {
    createMyStore(id).then(() => {
      getMyStores().then((res) => {
        setMyStores(res);
      });
    });
  }, []);

  const removeMyStore = useCallback(
    (id: number) =>
      deleteMyStore(id).then(() => {
        getMyStores().then((res) => {
          setMyStores(res);
        });
      }),
    []
  );

  return { isFeatureActive, myStores, isDone, addMyStore, removeMyStore };
};
