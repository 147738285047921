import { Text } from '@chakra-ui/react';
import { NotificationListItemType } from 'features/notification/types/NotificationType';
import { FC } from 'react';
import NotificationListItem from './NotificationListItem';
import { NotificationListItemSkeletonBox } from './SkeletonBoxes';

type NotificationListItemWrapperProps = {
  isLoaded: boolean;
  notificationList: NotificationListItemType[];
  unreadIds: number[];
  readNotification: (ids: number[]) => void;
};

const NotificationListItemWrapper: FC<NotificationListItemWrapperProps> = ({
  isLoaded,
  notificationList,
  unreadIds,
  readNotification
}) => {
  if (!isLoaded) {
    return (
      <>
        <NotificationListItemSkeletonBox />
        <NotificationListItemSkeletonBox />
        <NotificationListItemSkeletonBox />
      </>
    );
  }

  if (notificationList.length === 0) {
    return (
      <Text fontWeight={400} fontSize="md" pt="0.25rem">
        まだお知らせはありません。
      </Text>
    );
  }

  return (
    <>
      {notificationList.map((notificationListItem) => (
        <NotificationListItem
          key={notificationListItem.id}
          notificationListItem={notificationListItem}
          unreadIds={unreadIds}
          readNotification={readNotification}
        />
      ))}
    </>
  );
};

export default NotificationListItemWrapper;
