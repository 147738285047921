import { APP_LIFF_ENDPOINT } from 'libs/constants';
import { openWindow } from 'libs/line';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MenuButton from './MenuButton';

type MenuLinkProps = {
  to: string;
  buttonText: string;
  index: number;
};

/**
 * メニュー画面で利用するリンク機能を持つボタン
 */
const MenuLink: FC<MenuLinkProps> = ({ to, buttonText, index }) => {
  // リンクのURLにミニアプリのBASE URLが設定されているかのチェックフラグ
  const isAppLink = to.includes(APP_LIFF_ENDPOINT);
  return (
    <MenuButton
      as={RouterLink}
      to={isAppLink ? to : '#'} // アプリリンクの場合はページ遷移
      borderTopWidth={index === 0 ? '0.015rem' : 0} // 一番上のリンクの場合のみ、上線を表示する
      onClick={!isAppLink ? () => openWindow(to) : undefined} // 外部リンクの場合はLIFFブラウザで新しいタブを開く
    >
      {buttonText}
    </MenuButton>
  );
};

export default MenuLink;
