import { Button, Heading, Skeleton, Stack, VStack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import LabeledValue from 'components/modules/labeledValue/LabeledValue';
import dayjs from 'dayjs';
import CuminMemberProfileForm from 'features/membership/components/cumin/CuminMemberProfileForm';
import { useMembershipProfileResponse } from 'features/membership/hooks/cumin/useMembershipProfileResponse';
import useProfileEditForm from 'features/membership/hooks/cumin/useProfileEditForm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  GingerPagePageTemplateOptionKey,
  memberFormRequiredFieldsState,
  memberFormVisibleFieldsState,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FEATURES } from 'libs/features';
import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * VD+CSD 会員情報の変更
 * (FYI: 確認画面がないため、付番と違う作りにする必要ある)
 */
const GingerProfileEdit: FC = () => {
  const { membershipProfileResponse, isMutating: isMembershipProfileMutating } =
    useMembershipProfileResponse();

  const memberFormVisibleFields = useRecoilValue(memberFormVisibleFieldsState);
  const memberFormRequiredFields = useRecoilValue(
    memberFormRequiredFieldsState
  );
  const { methods, formSubmitHandler, isMutating } = useProfileEditForm({
    formFields: memberFormVisibleFields,
    requiredFormFields: memberFormRequiredFields,
    membershipProfileResponse,
    completeRedirectUrl: `${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.GINGER}/profile`
  });

  const templateOptions = useRecoilValue<
    Record<GingerPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.GINGER));

  return (
    <MainLayout header>
      <Stack px="1rem" pt="3rem" pb="0.75rem" h="100%">
        <VStack gap="4" alignItems="start" w="100%" pb="0.75rem">
          <Heading fontSize="xl" fontWeight="700" m="0">
            登録情報を修正してください
          </Heading>
          <Skeleton isLoaded={!isMembershipProfileMutating} w="100%">
            <FormProvider {...methods}>
              <Stack
                as="form"
                onSubmit={formSubmitHandler}
                gap="1rem"
                alignItems="start"
                w="100%"
              >
                <LabeledValue
                  label="カード番号"
                  value={membershipProfileResponse?.card_number.replace(
                    /\w{4}(?=.)/g,
                    '$& '
                  )}
                />

                {/* PIN番号 */}
                <LabeledValue
                  label="PIN番号"
                  value={membershipProfileResponse?.pin_code}
                />

                <CuminMemberProfileForm
                  templateOptions={templateOptions}
                  visibleFields={memberFormVisibleFields}
                  requiredFields={memberFormRequiredFields}
                  birthdayTextForDisplay={
                    membershipProfileResponse?.birthday
                      ? dayjs(membershipProfileResponse.birthday).format(
                          'YYYY[年]M[月]D[日]'
                        )
                      : ''
                  }
                  isBirthdayEditable={false}
                />
                {/* 「保存する」ボタン */}
                <Button
                  type="submit"
                  variant="primary-fullwidth-rounded"
                  isDisabled={!methods.formState.isValid}
                  isLoading={isMutating}
                >
                  保存する
                </Button>
              </Stack>
            </FormProvider>
          </Skeleton>

          {/* 「戻る」ボタン */}
          <Button
            as={Link}
            to={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.GINGER}/profile`}
            variant="primary-outline-fullwidth-rounded"
            isLoading={isMutating}
          >
            戻る
          </Button>
        </VStack>
      </Stack>
    </MainLayout>
  );
};

export default GingerProfileEdit;
