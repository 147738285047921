import { Button, Heading, Skeleton, Stack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import LabeledValue from 'components/modules/labeledValue/LabeledValue';
import CuminMemberProfileList from 'features/membership/components/cumin/CuminMemberProfileList';
import { useMembershipProfileResponse } from 'features/membership/hooks/cumin/useMembershipProfileResponse';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { EARTH_FEATURE_CODES } from 'features/membership/libs/const/plugins/earthFeatureCodes';
import cuminFormatDataForForm from 'features/membership/libs/cuminFormatDataForForm';
import {
  GingerPagePageTemplateOptionKey,
  memberFormVisibleFieldsState,
  membershipPluginFeatureState,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FEATURES } from 'libs/features';
import _ from 'lodash';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * VD+CSD 会員情報の表示
 */
const GingerProfile: FC = () => {
  const cuminMemberFormVisibleFields = useRecoilValue(
    memberFormVisibleFieldsState
  );

  const earthFeatureCodes = useRecoilValue(membershipPluginFeatureState).earth;

  const templateOptions = useRecoilValue<
    Record<GingerPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.GINGER));

  const isUpdateUserFeatureActive = Boolean(
    earthFeatureCodes?.includes(_.camelCase(EARTH_FEATURE_CODES.UPDATE_MEMBER))
  );

  const { membershipProfileResponse, isMutating } =
    useMembershipProfileResponse();

  return (
    <MainLayout header footer>
      <Stack pt="3rem" px="1rem" spacing="1rem">
        <Stack spacing="1.5rem">
          {/* タイトル */}
          <Heading fontSize="xl" fontWeight="700">
            登録情報
          </Heading>

          <Skeleton isLoaded={!isMutating}>
            <Stack spacing="1.5rem">
              {/* カード番号 */}
              <LabeledValue
                label="カード番号"
                value={membershipProfileResponse?.card_number?.replace(
                  /\w{4}(?=.)/g,
                  '$& '
                )}
              />

              {/* PIN番号 */}
              <LabeledValue
                label="PIN番号"
                value={membershipProfileResponse?.pin_code}
              />

              {/* NOTE: 会員情報画面では、「よく行く店舗」 の表示・編集はしない */}
              {/* NOTE: 「よく行く店舗」はDB側では実質「入会店舗」と同じ扱いのため編集不要 */}
              <CuminMemberProfileList
                visibleFields={cuminMemberFormVisibleFields}
                memberProfile={
                  membershipProfileResponse
                    ? cuminFormatDataForForm(membershipProfileResponse)
                    : {}
                }
                occupationOptions={
                  templateOptions?.memberFormOccupationObjectListOption
                    ?.object_list
                }
              />
            </Stack>
          </Skeleton>
        </Stack>

        {isUpdateUserFeatureActive && (
          <Button
            as={Link}
            variant="primary-fullwidth-rounded"
            to={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.GINGER}/profile/edit`}
            isLoading={isMutating}
          >
            編集する
          </Button>
        )}
      </Stack>
    </MainLayout>
  );
};
export default GingerProfile;
