import { ChakraProvider } from '@chakra-ui/react';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { useNotificationList } from 'features/notification/hooks/useNotificationList';
import { theme } from 'libs/chakra';
import { activeFeatureCodesState } from 'libs/recoil/selector';
import { FC, Suspense, useEffect, useLayoutEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { RecoilRoot, useRecoilValue, useSetRecoilState } from 'recoil';
import AppRoutes from 'routes';
import { TenantType } from 'types/TenantType';
import { TENANT_CODE } from './libs/constants';
import GoogleAnalytics from './libs/GoogleAnalytics';
import {
  digiclueplusRedirectParamsAtom,
  tenantConfigurationAtom
} from './libs/recoil/atom';
import { tenantTheme } from './libs/tenant';

dayjs.locale(ja);

type TenantConfigProps = {
  tenantConfig: TenantType;
};

const ScrollToTop = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return <></>;
};

// Component for Error fallback
const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>コンポーネント読み込み時にエラーが発生しました</p>
      <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>再読み込み</button>
    </div>
  );
};

const InitialProcess: FC = () => {
  const setDigiclueplusRedirectParams = useSetRecoilState(
    digiclueplusRedirectParamsAtom
  );
  const activeFeatureCodes = useRecoilValue(activeFeatureCodesState);

  const { getNotificationList } = useNotificationList({
    firstExecutionWithoutCall: false
  });

  // 未読お知らせ状態の初期化
  useEffect(() => {
    if (!activeFeatureCodes.includes('notice')) return;
    getNotificationList();
  }, [activeFeatureCodes, getNotificationList]);

  // QRコード読み取り後、標準ブラウザ（デジクルプラス）からミニアプリへリダイレクトした時に付与されるパラメータの保存
  useEffect(() => {
    const paramStr = window.location.search;
    const searchParams = new URLSearchParams(paramStr);
    setDigiclueplusRedirectParams({
      digiclueplus: searchParams.get('digiclueplus') || '',
      wall_path: searchParams.get('wall_path') || ''
    });
  }, [setDigiclueplusRedirectParams]);

  return null;
};

const App: FC<TenantConfigProps> = ({ tenantConfig }) => (
  <RecoilRoot
    initializeState={({ set }) => {
      set(tenantConfigurationAtom, tenantConfig);
    }}
  >
    <InitialProcess />
    <ChakraProvider theme={tenantTheme(theme, tenantConfig)}>
      <HelmetProvider>
        <BrowserRouter
          basename={`${process.env.PUBLIC_URL as string}/${TENANT_CODE}`}
        >
          <ScrollToTop />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={null}>
              <AppRoutes />
            </Suspense>
          </ErrorBoundary>
          <GoogleAnalytics />
        </BrowserRouter>
      </HelmetProvider>
    </ChakraProvider>
  </RecoilRoot>
);

export default App;
