import { Box, Skeleton } from '@chakra-ui/react';
import { FC } from 'react';

export const NotificationListItemSkeletonBox: FC = () => {
  return (
    <Box w="100%" borderRadius="0.25rem" bg="white" overflow="hidden">
      <Skeleton h="3.75rem" w="100%" />
    </Box>
  );
};
