import { Box, Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { cashFormat } from 'features/membership/libs/numberFormat';
import { FC } from 'react';

type GingerBalanceDetailItemProps = {
  title: string;
  amount: number;
  expireDate: string | null;
  unit?: string | null;
};
const GingerBalanceDetailItem: FC<GingerBalanceDetailItemProps> = ({
  title,
  amount,
  expireDate,
  unit
}) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    p=".5rem"
    borderTop="solid 1px"
    borderColor="#e3e3e3"
    _last={{ paddingBottom: 0 }}
  >
    <Box>
      <Text fontWeight={400} fontSize="lg" color="dark.400" aria-label={title}>
        {title}
      </Text>
      {!!expireDate && (
        <Text lineHeight="1em" fontSize="md" fontWeight={400} color="dark.400">
          {`${dayjs(expireDate).format('YYYY/MM/DD')} まで`}
        </Text>
      )}
    </Box>
    <Text color="dark.400" fontSize="lg" fontWeight={700} wordBreak="break-all">
      {cashFormat(amount)}
      {!!unit && (
        <Text as="span" fontWeight={400} fontSize="xs">
          {unit}
        </Text>
      )}
    </Text>
  </Flex>
);
export default GingerBalanceDetailItem;
