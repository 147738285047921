import { CARD_NUMBER_PARAM_KEY } from 'features/membership/libs/const/paramInsertion';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * カード連携入力フォームに対してカード番号自動入力に関連するhook
 * パラメータから自動入力 or 確認画面からの state 自動入力2種類の情報をまとめて
 * 初期値を判定する
 */
const useDefaultCardNumber = () => {
  const { state: cardNumber, search, pathname } = useLocation();
  const navigate = useNavigate();
  const [paramCardNumber, setParamCardNumber] = useState<null | string>(null);
  useEffect(() => {
    const query = new URLSearchParams(search);
    if (!query.has(CARD_NUMBER_PARAM_KEY)) {
      return;
    }
    setParamCardNumber(query.get(CARD_NUMBER_PARAM_KEY));
    navigate(pathname, {
      replace: true
    });
  }, [search, navigate, pathname]);

  return paramCardNumber ?? cardNumber;
};
export default useDefaultCardNumber;
