import { Button, Stack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import CuminTitleImageNotice from 'features/membership/components/cumin/CuminTitleImageNotice';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { EARTH_FEATURE_CODES } from 'features/membership/libs/const/plugins/earthFeatureCodes';
import {
  CuminPagePageTemplateOptionKey,
  membershipPluginFeatureState,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * 初回起動_カード確認
 */
const CuminSignupTop: FC = () => {
  const templateAliasCode = TEMPLATE_ALIAS_CODE.CUMIN;
  const templateOptions = useRecoilValue<
    Record<CuminPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(templateAliasCode));
  const earthFeatureCodes = useRecoilValue(membershipPluginFeatureState).earth;
  const isLinkFeatureActive = Boolean(
    earthFeatureCodes?.includes(EARTH_FEATURE_CODES.LINK)
  );
  const isIssueFeatureActive = Boolean(
    earthFeatureCodes?.includes(EARTH_FEATURE_CODES.ISSUE)
  );

  return (
    <MainLayout header footer>
      <Stack
        justifyContent="space-between"
        px="1rem"
        pt="3rem"
        minH="100%"
        gap="1rem"
      >
        <CuminTitleImageNotice
          title={templateOptions?.signupTopTitleContentOption?.content}
          imageUrl={templateOptions?.signupTopImageOption?.image_url}
          notice={templateOptions?.signupTopNoticeContentOption?.content}
        />
        <Stack gap="1rem">
          {/* カード連携ボタン */}
          {isLinkFeatureActive && (
            <Button
              as={RouterLink}
              to={`/membership/${templateAliasCode}/link`}
              variant="primary-fullwidth-rounded"
              h="auto"
              pt="1rem"
              pb="1rem"
              whiteSpace="pre-wrap"
              wordBreak="break-all"
            >
              {templateOptions?.signupTopLinkButtonContentOption?.content ||
                '持っています'}
            </Button>
          )}
          {/* カード付番ボタン */}
          {isIssueFeatureActive && (
            <Button
              as={RouterLink}
              to={`/membership/${templateAliasCode}/issue`}
              variant="primary-outline-fullwidth-rounded"
              h="auto"
              pt="1rem"
              pb="1rem"
              whiteSpace="pre-wrap"
              wordBreak="break-all"
            >
              {templateOptions?.signupTopIssueButtonContentOption?.content ||
                '持っていません'}
            </Button>
          )}
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default CuminSignupTop;
