import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  VStack
} from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import CampaignHeroSection from 'features/reservations/components/CampaignHeroSection';
import ProductListItem from 'features/reservations/components/ProductListItem';
import { ProductListSkeletonBox } from 'features/reservations/components/SkeletonBoxes';
import { useCampaignGroup } from 'features/reservations/hooks/useCampaignGroup';
import { useCart } from 'features/reservations/hooks/useCart';
import { useProducts } from 'features/reservations/hooks/useProducts';
import { getTotalQuantity } from 'features/reservations/libs';
import { FC } from 'react';
import {
  Link as RouterLink,
  useParams,
  useSearchParams
} from 'react-router-dom';

/** キャンペーングループ詳細 */
const CampaignGroupDetail: FC = () => {
  const { campaignId, campaignGroupId } = useParams();
  const [searchParams] = useSearchParams();
  const storeId = searchParams.get('storeId') || '';

  const { getHeroImageSrc } = useCampaignGroup(Number(campaignId));

  const { cartProducts, addProduct } = useCart(Number(campaignId));

  const {
    products,
    parentCampaignName,
    parentCampaignGroupName,
    isDone,
    isNotFound
  } = useProducts(Number(campaignId), Number(campaignGroupId));

  return (
    <MainLayout header>
      <CampaignHeroSection
        title={parentCampaignName ?? ''}
        imgSrc={getHeroImageSrc(Number(campaignGroupId))}
        isLoaded={isDone}
      />
      {isDone ? (
        <Container px="1rem" mb="4rem">
          {isNotFound ? (
            <p>存在しない商品グループです</p>
          ) : (
            <Stack mt="1rem" gap="1rem">
              <Heading size="md" mb="0.5rem">
                {parentCampaignGroupName}
              </Heading>
              {products.map((product) => (
                <ProductListItem
                  key={product.id}
                  product={product}
                  existingQuantityInCart={
                    cartProducts.filter((p) => p.id === product.id)[0]
                      ?.quantity ?? 0
                  }
                  as="product"
                  addProduct={addProduct}
                />
              ))}
            </Stack>
          )}
        </Container>
      ) : (
        <VStack px="1rem" gap="0.25rem">
          <ProductListSkeletonBox />
          <ProductListSkeletonBox />
          <ProductListSkeletonBox />
        </VStack>
      )}
      <Box w="100%" px="1rem" display="block" position="fixed" bottom="0.75rem">
        <RouterLink
          to={`/reservations/${campaignId}/cart`}
          state={{ storeId: Number(storeId) }}
        >
          <Button variant="primary-fullwidth-rounded-shadow">
            カートを見る ({getTotalQuantity(cartProducts)})
          </Button>
        </RouterLink>
      </Box>
    </MainLayout>
  );
};

export default CampaignGroupDetail;
