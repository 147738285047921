import { Box, Heading, HStack, Image, Text } from '@chakra-ui/react';
import { getReservationStatusObjectByAlias } from 'features/reservations/libs/reservationStatus';
import { ReservationType } from 'features/reservations/types/ReservationType';
import { FALLBACK_IMAGES } from 'libs/constants';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type ReservationHistoryItem = {
  reservation: ReservationType;
};

const ReservationHistoryItem: FC<ReservationHistoryItem> = ({
  reservation
}) => {
  const { label, bgColor, color } = getReservationStatusObjectByAlias(
    reservation.reservation_status
  );
  return (
    <Box bg="white" w="100%" borderRadius="0.25rem">
      <RouterLink to={reservation.id.toString()}>
        <HStack alignItems="center" position="relative">
          <Box
            h="7rem"
            w="7.5rem"
            bg="gray.400"
            flexShrink={0}
            borderTopLeftRadius="0.25rem"
            borderBottomLeftRadius="0.25rem"
          >
            <Image
              boxSize="100%"
              objectFit="cover"
              loading="lazy"
              src={reservation?.image_file_urls[0]?.image_file_url ?? ''}
              fallbackSrc={FALLBACK_IMAGES.square}
              borderTopLeftRadius="0.25rem"
              borderBottomLeftRadius="0.25rem"
              alt="キャンペーン画像"
            />
            <Box
              color={color}
              bg={bgColor}
              px="0.5rem"
              py="0.125rem"
              position="absolute"
              top="0"
              left="0"
              fontSize="sm"
              fontWeight="700"
              borderTopLeftRadius="0.25rem"
              sx={{ fontFeatureSettings: 'palt' }}
            >
              {label}
            </Box>
          </Box>
          <Box px="0.25rem" pe="0.5em" py="0.25rem">
            <Heading
              className="u-readableAlphaNumerals"
              color="primary"
              size="md"
              mb="0.5rem"
            >
              予約番号：{reservation.reservation_number}
            </Heading>
            <Text noOfLines={2}>{reservation.campaign_name}</Text>
            <Text>
              受取：{reservation.receipt_at}{' '}
              {reservation.receipt_hour_width_start_at}〜
              {reservation.receipt_hour_width_end_at}
            </Text>
          </Box>
        </HStack>
      </RouterLink>
    </Box>
  );
};

export default ReservationHistoryItem;
