import { SearchIcon } from '@chakra-ui/icons';
import { Button, Heading, Stack } from '@chakra-ui/react';
import liff from '@line/liff';
import MainLayout from 'components/layouts/MainLayout';
import { FC } from 'react';

const NotFound: FC = () => (
  <MainLayout header>
    <Stack
      px="2.5rem"
      alignItems="center"
      textAlign="center"
      gap="1rem"
      mt="4rem"
    >
      <SearchIcon w={8} h={8} color="red.500" />
      <Heading fontSize="xl">機能が見つかりません。</Heading>
      <Button
        width="100%"
        rounded="full"
        variant="primary-fullwidth-rounded"
        mt="1rem"
        onClick={() => liff.closeWindow()}
      >
        アプリを閉じる
      </Button>
    </Stack>
  </MainLayout>
);

export default NotFound;
