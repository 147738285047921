import LabeledValue from 'components/modules/labeledValue/LabeledValue';
import { patchMarsMembershipLinkCard } from 'features/membership/api';
import CuminCardLinkConfirmPage from 'features/membership/components/cumin/CuminCardLinkConfirmPage';
import useCardLinkConfirm from 'features/membership/hooks/ginger/useCardLinkConfirm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { GingerCardLinkFormInputs } from 'features/membership/types/form';
import { FEATURES } from 'libs/features';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

/** カード再連携/確認 */
const GingerCardRelinkConfirm: FC = () => {
  const location = useLocation();
  const state: null | { formData: GingerCardLinkFormInputs } = location.state;

  const formPagePath = `${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.GINGER}/card-renewal/relink`;
  const completePagePath = `${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.GINGER}/card-renewal/relink/complete`;
  const { onClickRegisterButton, onClickBackButton, isMutating } =
    useCardLinkConfirm(
      state,
      completePagePath,
      formPagePath,
      patchMarsMembershipLinkCard
    );

  // 入力値が取得できない場合、入力画面へリダイレクト
  if (!state?.formData) return <Navigate to={formPagePath} />;

  return (
    <CuminCardLinkConfirmPage
      onClickRegisterButton={onClickRegisterButton}
      onClickBackButton={onClickBackButton}
      isMutating={isMutating}
      formData={state.formData}
      additionalLabeledValue={
        <LabeledValue label="PIN番号" value={state.formData.pin_code} />
      }
    />
  );
};

export default GingerCardRelinkConfirm;
