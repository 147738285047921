import { getCampaignsByStoreId } from 'features/reservations/api';
import { CampaignType } from 'features/reservations/types/CampaignType';
import { useEffect, useState } from 'react';

export const useCampaignList = (defaultStoreId: number | null) => {
  const [currentStoreId, setCurrentStoreId] = useState<number | null>(
    defaultStoreId
  );
  const [campaigns, setCampaigns] = useState<CampaignType[]>([]);
  const [isDone, setIsDone] = useState<boolean>(false);

  useEffect(() => {
    if (currentStoreId) {
      getCampaignsByStoreId(currentStoreId).then((res) => {
        setCampaigns(res);
        setIsDone(true);
      });
    } else {
      setCampaigns([]);
      setIsDone(true);
    }
  }, [currentStoreId]);

  return { campaigns, currentStoreId, setCurrentStoreId, isDone };
};
