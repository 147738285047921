import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Container, Heading } from '@chakra-ui/react';
import { useMyStore } from 'features/my-store/hooks/useMyStore';

import { PlusIcon } from 'assets/icons/miscs';
import MainLayout from 'components/layouts/MainLayout';
import { StoreListSkeletonBox } from 'features/my-store/components/SkeletonBoxes';
import StoreListItem from 'features/my-store/components/StoreListItem';

type LinkToAllStoresListType = { icon: React.ReactElement; label: string };
const LinkToAllStoresList: FC<LinkToAllStoresListType> = ({ icon, label }) => {
  return (
    <RouterLink to="register">
      <Button leftIcon={icon} variant="primary-outline" w="100%" bg="white">
        {label}
      </Button>
    </RouterLink>
  );
};

/** マイ店舗 */
const MyStoreList: FC = () => {
  const { myStores, addMyStore, removeMyStore, isDone } = useMyStore();
  return (
    <MainLayout bg="dark.50" header footer>
      <Container mt="3rem">
        {isDone ? (
          <>
            {myStores.length ? (
              <>
                <Box mb="1rem">
                  {myStores.map((store) => (
                    <StoreListItem
                      key={store.id}
                      {...store}
                      isMyStore={true}
                      handleAddMyStore={addMyStore}
                      handleRemoveMyStore={removeMyStore}
                    />
                  ))}
                </Box>
                <LinkToAllStoresList
                  icon={<SearchIcon />}
                  label="他の店舗を探す"
                />
              </>
            ) : (
              <Box
                position="absolute"
                width="100%"
                top="50%"
                left="0"
                transform="translateY(-50%)"
                px="1.25em"
                textAlign="center"
              >
                <Heading fontSize="lg" mb="1em">
                  マイ店舗は登録されていません
                </Heading>
                <LinkToAllStoresList
                  icon={<PlusIcon />}
                  label="マイ店舗を登録する"
                />
              </Box>
            )}
          </>
        ) : (
          <>
            <StoreListSkeletonBox />
            <StoreListSkeletonBox />
            <StoreListSkeletonBox />
          </>
        )}
      </Container>
    </MainLayout>
  );
};

export default MyStoreList;
