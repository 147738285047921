import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spacer,
  Stack,
  Text
} from '@chakra-ui/react';
import { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { EmptyCartIcon } from 'assets/icons/miscs';
import MainLayout from 'components/layouts/MainLayout';
import CampaignHeroSection from 'features/reservations/components/CampaignHeroSection';
import CartListItem from 'features/reservations/components/CartListItem';
import { PrepaidInformationSection } from 'features/reservations/components/CaveatsSections';
import ReservedSummarySection from 'features/reservations/components/ReservedSummarySection';
import { ProductListSkeletonBox } from 'features/reservations/components/SkeletonBoxes';
import { useCampaignDetail } from 'features/reservations/hooks/useCampaign';
import { useCampaignGroup } from 'features/reservations/hooks/useCampaignGroup';
import { useCart } from 'features/reservations/hooks/useCart';
import { useInvoice } from 'features/reservations/hooks/useInvoice';
import { MAX_QUANTITY } from 'features/reservations/libs';
import { getReservedSummaryCaveatsByStatus } from 'features/reservations/libs/caveats';

interface PassedState {
  storeId: number;
}

/** カート */
const ReservationCart: FC = () => {
  const { campaignId } = useParams();
  const location = useLocation();
  const { storeId } = location.state as PassedState;
  const navigate = useNavigate();
  const { parentCampaignName } = useCampaignGroup(Number(campaignId));
  const { targetCampaign } = useCampaignDetail(Number(campaignId));
  const { cartProducts, addProduct, removeProduct } = useCart(
    Number(campaignId)
  );

  const {
    lineProducts,
    totalAmount,
    isDone,
    isDoneCalculation,
    paymentDeadline
  } = useInvoice(
    Number(campaignId),
    Number(storeId),
    cartProducts,
    null,
    false
  );

  const handleNavigateToCustomerForm = () => {
    navigate(`/reservations/${campaignId}/customer-form`, {
      state: { storeId }
    });
  };

  return (
    <MainLayout header>
      <CampaignHeroSection
        title={parentCampaignName ?? ''}
        imgSrc={targetCampaign?.image_file_urls[0]?.image_file_url ?? ''}
        isLoaded={isDone}
      />

      <Stack mb="1rem" px="1rem" pb="1rem" gap="0.5rem" w="100%">
        <Heading size="md">選択中の商品</Heading>

        {isDone ? (
          lineProducts.length > 0 ? (
            lineProducts.map((product) => (
              <CartListItem
                key={product.id}
                product={product}
                initialQuantity={product.quantity}
                maxQuantity={MAX_QUANTITY}
                handleDecrement={() => {
                  addProduct({
                    id: product.id,
                    quantity: product.quantity > 1 ? -1 : 0
                  });
                }}
                handleIncrement={() => {
                  addProduct({
                    id: product.id,
                    quantity: product.quantity < MAX_QUANTITY ? 1 : 0
                  });
                }}
                handleRemoveFromCart={() => removeProduct({ id: product.id })}
              />
            ))
          ) : (
            <Flex flexDirection="column" alignItems="center" my="3rem">
              <EmptyCartIcon
                color="primary"
                opacity="0.3"
                boxSize="6rem"
                ml="-0.5rem"
              />
              <Text mt="1rem" fontSize="lg" fontWeight="400">
                カートに商品が入っていません
              </Text>
            </Flex>
          )
        ) : (
          <ProductListSkeletonBox />
        )}

        {/* HACK:画面左側側に親要素の padding 分余白ができてしまうため、calc(50% - 50vw) を追加 */}
        <Box mx="calc(50% - 50vw)">
          <Divider />
        </Box>

        <ReservedSummarySection
          total={totalAmount}
          isLoaded={isDone && isDoneCalculation}
          caveats={getReservedSummaryCaveatsByStatus(
            'cart',
            targetCampaign?.pre_paymentable ?? false
          )}
        />
      </Stack>

      {targetCampaign?.pre_paymentable && paymentDeadline && (
        <PrepaidInformationSection dueDate={paymentDeadline} />
      )}

      <Spacer h="3rem" />

      <Box
        className="c-bottom-area"
        w="100%"
        px="1rem"
        display="block"
        position="fixed"
        bottom="0.75rem"
      >
        <Button
          variant="primary-fullwidth-rounded-shadow"
          onClick={handleNavigateToCustomerForm}
          isLoading={!isDone}
          isDisabled={(lineProducts ?? []).length <= 0}
        >
          受取設定へ進む
        </Button>
      </Box>
    </MainLayout>
  );
};

export default ReservationCart;
