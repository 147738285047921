import { Button, ButtonProps, ChakraComponent } from '@chakra-ui/react';
import { SlenderChevronRightIcon } from 'assets/icons/miscs';

/**
 * メニュー画面で利用するボタン
 */
const MenuButton: ChakraComponent<'button', ButtonProps> = (props) => (
  <Button
    rightIcon={<SlenderChevronRightIcon boxSize="1.5rem" color="dark.500" />}
    bg="white"
    h="auto"
    w="100%"
    padding="1rem"
    justifyContent="space-between"
    borderRadius={0}
    borderBottomWidth="0.015rem"
    borderColor="dark.100"
    iconSpacing={0}
    fontSize="1rem"
    fontWeight={400}
    textAlign="left"
    wordBreak="break-all"
    whiteSpace="pre-wrap"
    {...props}
  />
);

export default MenuButton;
