import { Button, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import TermOfService from 'components/modules/TermOfService';
import dispatchToast from 'components/modules/Toast';
import { patchMarsMembershipIssueCard } from 'features/membership/api';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  GingerPagePageTemplateOptionKey,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { FEATURES } from 'libs/features';
import { membershipMemberResponseAtom } from 'libs/recoil/atom';
import { FC, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * VD+CSD カード再付番
 */
const GingerCardReissue: FC = () => {
  const navigate = useNavigate();

  const templateOptions = useRecoilValue<
    Record<GingerPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.GINGER));
  const setMembershipMemberResponse = useSetRecoilState(
    membershipMemberResponseAtom
  );

  const [{ loading: isMutating }, executePatchMarsMembershipIssueCard] =
    useAsyncFn(async () => {
      const res = await patchMarsMembershipIssueCard();

      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
        setMembershipMemberResponse(undefined); // 会員情報 のレスポンス のグローバルステートを削除
        navigate(
          `/${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.GINGER}/card-renewal/reissue/complete`
        );
        return;
      }

      dispatchToast({
        id: 'membership-member-api-error',
        toastText: res.errors.join('\n')
      });
    }, []);

  // 規約文言
  const termContent =
    templateOptions?.reissueTermsOfServiceContentOption?.content;
  // 規約チェックボックスの状態
  const [isChecked, setIsChecked] = useState(!termContent);

  return (
    <MainLayout header>
      <Stack
        justifyContent="space-between"
        px="1rem"
        pt="3rem"
        pb="0.75rem"
        minH="100%"
        gap="1rem"
      >
        <VStack gap="1rem" alignItems="start" w="100%">
          {!!templateOptions?.reissueTitleContentOption?.content && (
            <Heading
              fontSize="xl"
              fontWeight="700"
              wordBreak="break-all"
              whiteSpace="pre-wrap"
            >
              {templateOptions.reissueTitleContentOption.content}
            </Heading>
          )}

          {!!templateOptions?.reissueNoticeContentOption?.content && (
            <Text fontSize="md" wordBreak="break-all" whiteSpace="pre-wrap">
              {templateOptions.reissueNoticeContentOption.content}
            </Text>
          )}
        </VStack>

        <Stack>
          <Stack gap="1rem" alignItems="center">
            {!!termContent && (
              <TermOfService
                variant="secondary"
                content={termContent}
                onCheckboxChange={(e) => setIsChecked(e.target.checked)}
              />
            )}
            <Button
              variant="primary-fullwidth-rounded"
              whiteSpace="pre-wrap"
              wordBreak="break-all"
              onClick={executePatchMarsMembershipIssueCard}
              isDisabled={!isChecked} // チェックが入っていない場合、disabledにする
              isLoading={isMutating}
            >
              再発行する
            </Button>
          </Stack>

          <Button
            as={RouterLink}
            to={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.GINGER}/card-renewal`}
            variant="primary-outline-fullwidth-rounded"
            whiteSpace="pre-wrap"
            wordBreak="break-all"
            isLoading={isMutating}
          >
            キャンセル
          </Button>
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default GingerCardReissue;
