/** Cumin Gingerで利用するtemplate optionの中で member form と関連して切り抜く位置 */
export const MEMBER_FORM_PREFIX_POSITION = 12;

/** Cumin Ginger バリデーション用の文言 */
export const MEMBER_FORM_ERROR_MESSAGES = {
  REQUIRED_FIELD_MESSAGE: '入力してください',
  MAX_TEXT_MESSAGE: '文字以内で入力してください',
  FULL_KANA_MESSAGE: '全角カナで入力してください',
  SPACE_MESSAGE: 'スペースを含めることはできません',
  YEAR_MESSAGE: '年は4桁半角数字で入力してください',
  MONTH_MESSAGE: '月は1から12の間の半角数字で入力してください',
  DATE_MESSAGE: '日は1から31の間の半角数字で入力してください'
};
