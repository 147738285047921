import { Button, Container, Heading, Stack, Text } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import { createDigiclueplusToken } from 'features/digiclueplus/api';
import { ServiceCode } from 'features/digiclueplus/types';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { openWindow } from 'libs/line';
import { FC } from 'react';
import { useAsyncFn } from 'react-use';

type DigiclueplusPageProps = {
  serviceCode: ServiceCode;
  headding: string;
  description: string;
  buttonLabel: string;
};

const DigiclueplusPage: FC<DigiclueplusPageProps> = ({
  serviceCode,
  headding,
  description,
  buttonLabel
}) => {
  const [{ loading, error }, onClickButton] = useAsyncFn(
    // ボタンクリック時の処理
    async () => {
      const res = await createDigiclueplusToken(serviceCode);

      // interceptors 側の処理でエラー画面へ
      if (res.status === API_RESPONSE_STATUS.FAILED) return;

      openWindow(res.data.service_url, false);
    },
    []
  );

  return (
    <MainLayout bg="dark.50" header footer>
      <Container pt="3rem">
        <Stack spacing="2rem">
          <Stack spacing="0.5rem">
            {/* タイトル */}
            <Heading fontSize="lg">{headding}</Heading>
            {/* 説明 */}
            <Text fontSize="lg">{description}</Text>
          </Stack>
          {/* デジクスサービスへの遷移ボタン */}
          <Button
            variant="primary-fullwidth-rounded"
            onClick={onClickButton}
            // NOTE: エラーが発生してエラー画面に遷移するまでの間も余分なAPI呼び出し防止のため、ボタンクリックできないようにする
            isLoading={loading || !!error}
          >
            {buttonLabel}
          </Button>
        </Stack>
      </Container>
    </MainLayout>
  );
};

export default DigiclueplusPage;
