/**
 * Mars pluginにおけるfeature_codesのapiのキーをまとめた定数
 * VDとCSD両方に関わるplugin
 * Ginger template optionで利用
 * NOTE: 付番機能 issue はEarthのものを利用するため、除外
 * */
export const MARS_FEATURE_CODES = {
  // カード連携する機能
  LINK: 'link',
  // カード再発行する機能
  REISSUE: 'reissue',
  // カード再連携する機能
  RELINK: 'relink'
} as const;
