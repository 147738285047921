import { Alert, AlertIcon } from '@chakra-ui/react';
import { getBarcodeWidthByFormat } from 'libs/barcode';
import { useBarcode } from 'next-barcode';
import { FC } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

type BarcodeType = {
  code: string;
  format?: string;
  height: number;
  flat?: boolean;
  fontSize?: number;
  displayValue?: boolean;
};

const Barcode: FC<BarcodeType> = ({
  code,
  format,
  height,
  flat = false,
  fontSize = 20,
  displayValue = true
}) => {
  const width = getBarcodeWidthByFormat(format);

  const { inputRef } = useBarcode({
    value: code,
    options: {
      format,
      width,
      height,
      flat,
      fontSize,
      displayValue
    }
  });

  return <svg ref={inputRef} />;
};

// FIXME: 一時的にアプリクラッシュを防ぐためのものとなります
export const BarcodeWithErrorBoundary = withErrorBoundary(Barcode, {
  fallback: (
    <Alert status="error">
      <AlertIcon />
      番号に問題があるため、表示できません
    </Alert>
  )
});
export default Barcode;
