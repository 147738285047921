import MainLayout from 'components/layouts/MainLayout';
import { NOW_LOADING } from 'libs/constants';
import { tenantConfigurationAtom } from 'libs/recoil/atom';
import { activeFeaturesState } from 'libs/recoil/selector';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const Top: FC = () => {
  const navigate = useNavigate();
  const tenantConfiguration = useRecoilValue(tenantConfigurationAtom);
  const activeFeatures = useRecoilValue(activeFeaturesState);

  useEffect(() => {
    if (!tenantConfiguration.code && !tenantConfiguration.name) return; // テナント情報の取得完了まで待つ(code と name のどちらも空文字なら未取得とみなす)
    navigate(activeFeatures.length ? activeFeatures[0].path : '/not-found');
  }, [navigate, tenantConfiguration, activeFeatures]);

  return <MainLayout pageTitle={NOW_LOADING} isLoading />;
};

export default Top;
