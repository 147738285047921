import DigiclueplusPage from 'features/digiclueplus/components/DigiclueplusPage';
import { FC } from 'react';

const CouponTop: FC = () => {
  return (
    <DigiclueplusPage
      serviceCode="simple_coupons"
      headding="クーポン"
      description="お得なクーポンはこちらから"
      buttonLabel="クーポン一覧へ"
    />
  );
};

export default CouponTop;
