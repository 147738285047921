import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text
} from '@chakra-ui/react';
import ja from 'date-fns/locale/ja';
import dayjs from 'dayjs';
import { API_RESPONSE_STATUS } from 'libs/constants';
import {
  convertDayjsToDate,
  convertDayjsToDateArray,
  getDayjsArray
} from 'libs/react-select';
import React, { FC, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

type DatepickerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialSelectedDate: string | null;
  includeDates: string[];
  handleUpdatePickupDate: (date: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkAvailableDateForPickup: (date: string) => Promise<any>;
};

registerLocale('ja', ja);

const DatepickerModal: FC<DatepickerModalProps> = ({
  isOpen,
  onClose,
  initialSelectedDate = null,
  includeDates = [],
  handleUpdatePickupDate,
  checkAvailableDateForPickup
}) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(
    initialSelectedDate
  );
  const [isProceedable, setIsProceedable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx="1rem">
        <ModalHeader>受取日 選択</ModalHeader>
        <ModalBody>
          <DatePicker
            selected={
              selectedDate ? convertDayjsToDate(dayjs(selectedDate)) : null
            }
            includeDates={convertDayjsToDateArray(
              getDayjsArray(includeDates, 'YYYY-MM-DD')
            )}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                const dateStr = dayjs(value).format('YYYY-MM-DD');
                setSelectedDate(dateStr);
                setIsLoading(true);
                checkAvailableDateForPickup(dateStr).then((res) => {
                  setIsLoading(false);
                  setIsProceedable(
                    res.status === API_RESPONSE_STATUS.SUCCEEDED
                  );
                });
              }
            }}
            disabled={true}
            fixedHeight
            inline
            locale="ja"
            placeholderText="選択してください"
            dateFormat="yyyy/MM/dd"
            disabledKeyboardNavigation={true}
          />

          {selectedDate ? (
            <Flex mt="0.5rem" gap="0.5rem">
              <Box flexShrink="0">
                <Text>選択した日</Text>
                <Heading size="lg">
                  {dayjs(selectedDate).isValid()
                    ? dayjs(selectedDate).format('YYYY/MM/DD')
                    : '----/--/--'}
                </Heading>
              </Box>
              <Skeleton
                isLoaded={!isLoading}
                w="100%"
                textAlign="center"
                py="auto"
                display="grid"
                placeItems="center"
              >
                <Badge
                  variant="subtle"
                  fontSize="lg"
                  fontWeight="bold"
                  color={isProceedable ? 'green.500' : 'red.600'}
                  bg={isProceedable ? 'green.100' : 'red.100'}
                  my="auto"
                >
                  {isProceedable ? '受取可' : '受取不可'}
                </Badge>
              </Skeleton>
            </Flex>
          ) : (
            <Box my="0.5rem" w="100%" textAlign="center">
              <Heading size="md" mb="0.5rem">
                受取日を選択してください
              </Heading>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <HStack gap="0.5rem">
            <Button onClick={onClose} variant="dark-outline">
              閉じる
            </Button>
            <Button
              onClick={() => {
                if (selectedDate) {
                  handleUpdatePickupDate(selectedDate);
                }
                onClose();
              }}
              variant="primary"
              isDisabled={!isProceedable || isLoading}
            >
              OK
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(DatepickerModal);
