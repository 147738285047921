import { Center, Heading, Stack, Text } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import ReservationFormViewer from 'features/reservations/components/ReservationFormViewer';
import ReservedProductListSection from 'features/reservations/components/ReservedProductListSection';
import ReservedSummarySection from 'features/reservations/components/ReservedSummarySection';
import { useCampaignDetail } from 'features/reservations/hooks/useCampaign';
import { useReservation } from 'features/reservations/hooks/useReservation';
import { getReservedSummaryCaveatsByStatus } from 'features/reservations/libs/caveats';
import { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

/** 受取完了 */
const ReservationPickupCompleted: FC = () => {
  const { reservationId } = useParams();
  const { reservation, isDone } = useReservation(Number(reservationId));
  const { targetCampaign } = useCampaignDetail(reservation?.campaign_id);

  return (
    <MainLayout header>
      <Stack px="1rem" py="2.5rem" gap="1.5rem">
        <Heading textAlign="center" fontSize="24px" mt="0">
          ありがとうございます！
          <br />
          商品の受け取りが完了しました！
        </Heading>

        {reservation && (
          <ReservationFormViewer
            headding="予約内容"
            targetCampaign={targetCampaign}
            reservationForm={{ ...reservation }}
          />
        )}
        <ReservedProductListSection
          products={reservation?.products}
          roundingAlias={reservation?.tax_round ?? 'round_off'}
          isLoaded={isDone}
        />
        <ReservedSummarySection
          total={reservation?.total_with_tax}
          isLoaded={isDone}
          caveats={getReservedSummaryCaveatsByStatus('received', true)}
        />
        <Center>
          <RouterLink to="/reservations/">
            <Text fontWeight={700} fontSize="md" color="primary">
              予約注文トップに戻る
            </Text>
          </RouterLink>
        </Center>
      </Stack>
    </MainLayout>
  );
};

export default ReservationPickupCompleted;
