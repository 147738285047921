import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { FC } from 'react';

import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { ProductType } from 'features/reservations/types/ProductType';
import { FALLBACK_IMAGES } from 'libs/constants';
import ProductDetailModal from './ProductDetailModal';

dayjs.extend(isBetween);

type ProductListItemType = {
  product: ProductType;
  quantity?: number;
  existingQuantityInCart?: number;
  as: 'product' | 'reservation';
  addProduct?: ({ id, quantity }: { id: number; quantity: number }) => void;
};

const ProductListItem: FC<ProductListItemType> = ({
  product,
  quantity,
  existingQuantityInCart = 0,
  as,
  addProduct
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isAvailablePeriod = dayjs().isBetween(
    product.reservation_start_at,
    product.reservation_end_at,
    'millisecond',
    '[]' // NOTE: Specify "[]" in case of the boundary value is included as the condition
  );

  const productStatus = isAvailablePeriod
    ? 'available'
    : 'outOfReservationPeriod';

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        gap="0.5rem"
        w="100%"
        minH="5rem"
        onClick={onOpen}
        wordBreak="break-all"
      >
        <Box w="5rem" h="5rem" flexShrink={0}>
          <Image
            boxSize="100%"
            objectFit="cover"
            loading="lazy"
            // DIGICLUE_LINEMINIAPP-3589 対応のため、undefined を細かくチェック
            src={product?.image_file_urls?.[0]?.image_file_url ?? ''}
            fallbackSrc={FALLBACK_IMAGES.square}
            alt="商品画像"
          />
        </Box>
        <Box color="dark.500" w="100%">
          <Heading
            color={as === 'product' ? 'primary' : 'dark.500'}
            fontWeight="700"
            fontSize="md"
            mb="0.25rem"
          >
            {product.name}
          </Heading>
          <Flex m="0" w="100%" justifyContent="space-between">
            <Text mb="8px" _last={{ marginBottom: 0 }}>
              {product.price_with_tax.toLocaleString()}円（税込）
            </Text>
            {quantity && <Text>× {quantity}個</Text>}
          </Flex>

          {as === 'product' && <Text noOfLines={2}>{product.description}</Text>}
        </Box>
      </Flex>
      {as === 'product' && (
        <ProductDetailModal
          isOpen={isOpen}
          onClose={onClose}
          product={product}
          productStatus={productStatus}
          existingQuantityInCart={existingQuantityInCart}
          maxQuantity={20}
          addProduct={addProduct}
        />
      )}
    </>
  );
};

export default ProductListItem;
