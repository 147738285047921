/**
 * Earth pluginにおけるfeature_codesのapiのキーをまとめた定数
 * CSDに関わるplugin
 * Cumin / Ginger template optionで利用
 * （Gingerでは、LINK、REISSUE、RELINKはMarsを利用するため、Earthは利用しない）
 * */
export const EARTH_FEATURE_CODES = {
  // カード付番する機能
  ISSUE: 'issue',
  // カード連携する機能
  LINK: 'link',
  // カード再付番する機能
  REISSUE: 'reissue',
  // カード再連携する機能
  RELINK: 'relink',
  // 会員（ユーザー）情報を編集する機能
  UPDATE_MEMBER: 'update_member',
  // ユーザー情報表示する機能
  GET_MEMBER: 'get_member',
  // ポイント取得機能
  GET_POINT_BALANCE: 'get_point_balance'
} as const;
