import { Button, Container, Heading, Stack, Text } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import LabeledValue from 'components/modules/labeledValue/LabeledValue';
import { SimplePageTemplateOptionKey } from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FormPagePath } from 'features/membership/types/pagePath';
import { FC, MouseEventHandler } from 'react';
import { Navigate } from 'react-router-dom';

type SimpleConfirmPageProps = {
  pageTemplateOptions: Partial<
    Record<SimplePageTemplateOptionKey, PageTemplateOption>
  >;
  noticeTextOptionKey: Extract<
    SimplePageTemplateOptionKey,
    'confirmNoticeContentOption' | 'signupConfirmNoticeContentOption'
  >;
  cardNumber: string | null | unknown;
  isMutating?: boolean;
  onClickRegisterButton?: MouseEventHandler;
  onClickFixButton?: MouseEventHandler;
  // ツルハで「カード番号」のラベルを変更したいとの要望があったため、一時対応としてラベルをカスタマイズできるようにしています
  cardNumberLabel?: string;
} & FormPagePath;

/**
 * 会員連携入力確認ページ
 * Simple, Pepper, Mintのテンプレートで利用する共通コンポーネント
 */
const SimpleConfirmPage: FC<SimpleConfirmPageProps> = ({
  pageTemplateOptions,
  noticeTextOptionKey,
  cardNumber,
  formPagePath,
  isMutating,
  onClickRegisterButton,
  onClickFixButton,
  cardNumberLabel = 'カード番号'
}) => {
  if (typeof cardNumber !== 'string') return <Navigate to={formPagePath} />;
  return (
    <MainLayout header footer>
      <Container pt="3rem">
        <Stack spacing="2.5rem">
          {/* タイトル */}
          <Heading fontSize="xl" fontWeight="500" textAlign="center">
            入力確認
          </Heading>
          {/* カード番号 */}
          <LabeledValue
            label={cardNumberLabel}
            value={cardNumber.replace(/\w{4}(?=.)/g, '$& ')}
          />
          {/* 注意書き */}
          {pageTemplateOptions[noticeTextOptionKey]?.content && (
            <Text fontSize="sm" whiteSpace="pre-line">
              {pageTemplateOptions[noticeTextOptionKey]?.content}
            </Text>
          )}
          <Stack gap="1rem">
            {/* 「登録する」ボタン */}
            <Button
              variant="primary-fullwidth-rounded"
              isLoading={isMutating}
              onClick={onClickRegisterButton}
            >
              登録する
            </Button>
            {/* 「修正する」ボタン */}
            <Button
              variant="primary-link"
              fontSize="lg"
              isLoading={isMutating}
              onClick={onClickFixButton}
            >
              修正する
            </Button>
          </Stack>
        </Stack>
      </Container>
    </MainLayout>
  );
};

export default SimpleConfirmPage;
