import SimpleConfirmPage from 'features/membership/components/simple/SimpleConfirmPage';
import usePepperConfirm from 'features/membership/hooks/usePepperConfirm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  COMPLETE_PAGE_PATH,
  FORM_PAGE_PATH
} from 'features/membership/libs/const/memberPagePath';
import {
  membershipPluginPageTemplateOptionState,
  SimplePageTemplateOptionKey
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * 会員編集入力確認
 */
const PepperConfirm: FC = () => {
  const pageTemplateOptions = useRecoilValue<
    Record<SimplePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.PEPPER));
  // pepperの会員編集入力ページのパスを取得
  const formPagePath = FORM_PAGE_PATH.PEPPER_EDIT;
  const { isMutating, onClickRegisterButton, onClickFixButton, cardNumber } =
    usePepperConfirm({
      formPagePath,
      completePagePath: COMPLETE_PAGE_PATH.PEPPER_EDIT_COMPLETE
    });

  if (!pageTemplateOptions) {
    return <Navigate to="/" />;
  }

  return (
    <SimpleConfirmPage
      pageTemplateOptions={pageTemplateOptions}
      noticeTextOptionKey="confirmNoticeContentOption"
      cardNumber={cardNumber}
      formPagePath={formPagePath}
      isMutating={isMutating}
      onClickRegisterButton={onClickRegisterButton}
      onClickFixButton={onClickFixButton}
    />
  );
};

export default PepperConfirm;
