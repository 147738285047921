import {
  Box,
  Center,
  Container,
  Heading,
  Skeleton,
  Stack,
  Text
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ResponseType } from 'api/index';
import MainLayout from 'components/layouts/MainLayout';
import ConfirmSlider from 'components/modules/ConfirmSlider';
import dispatchToast from 'components/modules/Toast';
import CampaignReceiptNote from 'features/reservations/components/CampaignReceiptNote';
import CampaignReceiptTimeNotes from 'features/reservations/components/CampaignReceiptTimeNotes';
import ProductBarcodeModalButtonSection from 'features/reservations/components/ProductBarcodeModalButtonSection';
import ReservationFormViewer from 'features/reservations/components/ReservationFormViewer';
import ReservationNumber from 'features/reservations/components/ReservationNumber';
import ReservedProductListSection from 'features/reservations/components/ReservedProductListSection';
import ReservedSummarySection from 'features/reservations/components/ReservedSummarySection';
import { useCampaignDetail } from 'features/reservations/hooks/useCampaign';
import { useReservation } from 'features/reservations/hooks/useReservation';
import { getReservedSummaryCaveatsByStatus } from 'features/reservations/libs/caveats';
import { API_RESPONSE_STATUS, NOW_LOADING } from 'libs/constants';

/**
 * スライダーボタンと表示テキストをまとめたコンポーネント
 */
const PickupSliderSection: FC<{
  handlePickup: () => Promise<ResponseType>;
}> = ({ handlePickup }) => {
  const [isLoadingPickup, setIsLoadingPickup] = useState<boolean>(false);
  const [sliderValues, setSliderValues] = useState<number[]>([0]);
  const navigate = useNavigate();

  const handleCompletePickup = () => {
    setIsLoadingPickup(true);
    handlePickup().then((res) => {
      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
        navigate('completed', { replace: true });
      } else {
        setSliderValues([0]);
        dispatchToast({
          id: 'toast-reservation-handle-pickup-error',
          toastText: `エラー：${res.errors.join(', ')}`,
          center: true
        });
      }
      setIsLoadingPickup(false);
    });
  };

  return (
    <>
      <Text mb="8px" fontWeight="400" fontSize="lg">
        商品の受け取り後、店舗の方に画面を提示しながら下のボタンを右にスライドしてください
      </Text>
      <Text color="danger" fontWeight="400" fontSize="lg">
        一度操作を完了すると、取り消しできません。
      </Text>
      <ConfirmSlider
        values={sliderValues}
        setValues={setSliderValues}
        rangeText={isLoadingPickup ? NOW_LOADING : '商品を受け取る'}
        isDisabled={isLoadingPickup}
        handleComplete={handleCompletePickup}
      />
    </>
  );
};

/** 受取 */
const ReservationPickup: FC = () => {
  const { reservationId } = useParams();
  const { reservation, isDone, handlePickup } = useReservation(
    Number(reservationId)
  );
  const { targetCampaign } = useCampaignDetail(reservation?.campaign_id);

  // 予約ステータスがreservedなのかを確認
  const isReserved = reservation?.reservation_status === 'reserved';

  return (
    <MainLayout header footer>
      <Container
        px="1rem"
        pt="2.5rem"
        pb="1rem"
        mb="1rem"
        borderBottom="1px solid"
        borderBottomColor="dark.100"
      >
        <Heading>商品受取</Heading>
        <Skeleton isLoaded={isDone}>
          <Center
            alignItems="center"
            p="1rem"
            mt="1rem"
            mb="1rem"
            textAlign="center"
          >
            <ReservationNumber
              reservationNumber={reservation?.reservation_number}
              isDone={isDone}
            />
          </Center>
          {/** 事前支払いなし(受取画面表示時に支払う)の場合、STEP表示とする */}
          {isReserved ? (
            <>
              <Stack alignItems="center" mb="2rem" spacing="1rem">
                <Box
                  width="100vw"
                  px="1rem"
                  py="0.5rem"
                  fontSize="5xl"
                  bgColor="#EEEEEE"
                >
                  <Text fontSize="lg" fontWeight="700">
                    STEP 1
                  </Text>
                </Box>
                <ProductBarcodeModalButtonSection reservation={reservation} />
              </Stack>
              <Stack alignItems="center" mb="4rem" spacing="1rem">
                <Box
                  width="100vw"
                  px="1rem"
                  py="0.5rem"
                  fontSize="5xl"
                  bgColor="#EEEEEE"
                >
                  <Text fontSize="lg" fontWeight="700">
                    STEP 2
                  </Text>
                </Box>
                <PickupSliderSection handlePickup={handlePickup} />
              </Stack>
            </>
          ) : (
            <Stack alignItems="center" mb="4rem" spacing="1rem">
              <PickupSliderSection handlePickup={handlePickup} />
            </Stack>
          )}
        </Skeleton>
      </Container>
      {/* TODO: 画面全体の余白指定方法の見直し */}
      <Stack mx="1rem" gap="1.5rem">
        {reservation?.campaign_receipt_note && (
          <CampaignReceiptNote
            campaign_receipt_note={reservation.campaign_receipt_note}
          />
        )}
        {reservation && (
          <ReservationFormViewer
            headding="予約内容"
            targetCampaign={targetCampaign}
            reservationForm={{ ...reservation }}
          />
        )}
        {reservation && (
          <CampaignReceiptTimeNotes
            campaign_auto_cancel_waiting_hours={
              reservation.campaign_auto_cancel_waiting_hours
            }
          />
        )}
        <ReservedProductListSection
          products={reservation?.products}
          roundingAlias={reservation?.tax_round ?? 'round_off'}
          isLoaded={isDone}
        />
        <ReservedSummarySection
          total={reservation?.total_with_tax}
          isLoaded={isDone}
          caveats={getReservedSummaryCaveatsByStatus(
            reservation?.reservation_status ?? 'reserved',
            targetCampaign?.pre_paymentable ?? false
          )}
        />
      </Stack>
    </MainLayout>
  );
};

export default ReservationPickup;
