import { Accordion, Container, Flex, Heading } from '@chakra-ui/react';
import { FC } from 'react';

import MainLayout from 'components/layouts/MainLayout';
import { StoresAccordionSkeletonBox } from 'features/my-store/components/SkeletonBoxes';
import StoreListAccordion from 'features/my-store/components/StoreListAccordion';
import { useMyStore } from 'features/my-store/hooks/useMyStore';
import { useStore } from 'features/my-store/hooks/useStore';

/** マイ店舗登録 */
const MyStoreRegister: FC = () => {
  const { stores } = useStore();
  const { myStores, addMyStore, removeMyStore, isDone } = useMyStore();
  return (
    <MainLayout bg="dark.50" header footer>
      <Container mt="2rem" px="1rem">
        <Heading size="md" mb="0.5rem">
          エリア選択
        </Heading>
      </Container>
      {isDone ? (
        <Accordion allowToggle>
          {stores.map(({ prefecture, stores }) => (
            <StoreListAccordion
              key={`store-${prefecture}`}
              prefectureName={prefecture}
              stores={stores.map((store) => ({
                ...store,
                isMyStore: myStores.some((myStore) => myStore.id === store.id)
              }))}
              handleAddMyStore={addMyStore}
              handleRemoveMyStore={removeMyStore}
            />
          ))}
        </Accordion>
      ) : (
        <Flex flexDirection="column" gap="0.05rem">
          <StoresAccordionSkeletonBox />
          <StoresAccordionSkeletonBox />
          <StoresAccordionSkeletonBox />
        </Flex>
      )}
    </MainLayout>
  );
};

export default MyStoreRegister;
