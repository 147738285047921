import {
  Box,
  Button,
  Heading,
  HStack,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { ClockIcon, PhoneIcon } from 'assets/icons/miscs';
import Dialog from 'components/modules/Dialog';
import StringToJsx from 'components/modules/StringToJsx';
import dispatchToast from 'components/modules/Toast';
import { StoreType } from 'features/my-store/types/StoreType';
import { FC } from 'react';
import StoreExternalLinkList from './StoreExternalLinkList';

type StoreListItemType = StoreType & {
  isMyStore: boolean;
  handleAddMyStore: (storeId: number) => void;
  handleRemoveMyStore: (storeId: number) => void;
};

const StoreListItem: FC<StoreListItemType> = ({
  id,
  name,
  postal_code,
  address,
  business_hour,
  tel,
  external_links,
  isMyStore,
  handleAddMyStore,
  handleRemoveMyStore
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSubmit = () => {
    isMyStore ? handleRemoveMyStore(id) : handleAddMyStore(id);
    dispatchToast({
      id: isMyStore ? `toast-store-${id}-removed` : `toast-store-${id}-added`,
      toastText: `${name} を${isMyStore ? '削除しました' : '追加しました'}`,
      center: true
    });
    onClose();
  };
  return (
    <>
      <Box
        bg="white"
        border="1px solid"
        borderColor="dark.100"
        mb="0.5rem"
        p="1rem"
        borderRadius="4px"
        wordBreak="break-all"
        // HACK: マイ店舗一覧テスト実装の際１店舗ごとにテストを書きたいが、
        // 店舗一覧を取得できる仕組みになっていないためTest IDを追加
        data-testid="my-shop"
      >
        <VStack mb="1rem" alignItems="flex-start">
          <Heading fontWeight="500" fontSize="lg" mb="0">
            {name}
          </Heading>
          <Text fontSize="md" fontWeight="500">
            〒{postal_code} {address}
          </Text>
        </VStack>

        <HStack mb="0.25rem" alignItems="start">
          <ClockIcon color="dark.400" />
          <Box data-testid="my-shop-business-hour">
            <StringToJsx
              text={business_hour}
              fontWeight="400"
              color="dark.400"
            />
          </Box>
        </HStack>
        <HStack mb="1.2rem">
          <PhoneIcon color="dark.400" />
          <Text fontWeight="400" color="dark.400" data-testid="my-shop-tel">
            {tel}
          </Text>
        </HStack>

        <StoreExternalLinkList externalLinks={external_links} />

        <Button
          w="100%"
          variant={isMyStore ? 'dark-outline' : 'primary-outline'}
          onClick={onOpen}
        >
          {isMyStore ? 'マイ店舗から削除する' : 'マイ店舗に登録する'}
        </Button>
      </Box>

      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        title={name}
        description={
          isMyStore ? 'マイ店舗から削除しますか？' : 'マイ店舗に登録しますか？'
        }
        cancelText="キャンセル"
        submitText={isMyStore ? '削除する' : '登録する'}
        onSubmit={handleSubmit}
        isDestructive={isMyStore}
      />
    </>
  );
};

export default StoreListItem;
