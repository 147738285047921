import SimpleFormPage from 'features/membership/components/simple/SimpleFormPage';
import { useSimpleForm } from 'features/membership/hooks/useSimpleForm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { membershipSimpleSettingOptionsState } from 'features/membership/libs/recoil/selector';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * 会員登録入力
 */
const SimpleSignupNew: FC = () => {
  // TODO: 最終的にuseDefaultCardNumberに切り替わりますが、先に先方が要望するMintのみ修正する
  const { state: cardNumber } = useLocation();
  const { useFormReturn, toConfirmPage } = useSimpleForm(cardNumber);
  const confirmPagePath = `/membership/${TEMPLATE_ALIAS_CODE.SIMPLE}/signup/confirm`;
  const templateOptions = useRecoilValue(membershipSimpleSettingOptionsState);

  return (
    <SimpleFormPage
      useFormReturn={useFormReturn}
      onFormSubmit={toConfirmPage(confirmPagePath)}
      templateOptions={templateOptions}
      isSignUp
    />
  );
};

export default SimpleSignupNew;
