import type { ComponentStyleConfig } from '@chakra-ui/theme';

// common
export const fullRounded = {
  borderRadius: 9999
};

export const generateColorSchemeForFillButton = (variant: string) => ({
  bg: variant,
  _hover: {
    bg: variant,
    _disabled: {
      bg: variant
    }
  },
  _focus: {
    bg: variant,
    _disabled: {
      bg: variant
    }
  },
  _active: {
    bg: variant,
    _disabled: {
      bg: variant
    }
  }
});

export const generateColorSchemeForLinkButton = (variant: string) => ({
  color: variant,
  _hover: {
    color: variant
  },
  _focus: {
    color: variant
  },
  _active: {
    color: variant
  }
});

export const generateColorSchemeForOutlineButton = (variant: string) => ({
  border: '1px solid',
  color: variant,
  borderColor: variant,
  _hover: {
    color: variant,
    borderColor: variant,
    _disabled: {
      color: variant,
      borderColor: variant
    }
  },
  _focus: {
    color: variant,
    borderColor: variant,
    _disabled: {
      color: variant,
      borderColor: variant
    }
  },
  _active: {
    color: variant,
    borderColor: variant,
    _disabled: {
      color: variant,
      borderColor: variant
    }
  }
});

export const buttonPrimaryFullWidthRounded = {
  fontWeight: '700',
  fontSize: 'lg',
  color: 'white',
  width: '100%',
  py: '1.5rem',
  textAlign: 'center',
  ...fullRounded,
  ...generateColorSchemeForFillButton('primary')
};

export const buttonPrimaryOutlineFullWidthRounded = {
  fontWeight: '700',
  fontSize: 'lg',
  width: '100%',
  py: '1.5rem',
  textAlign: 'center',
  ...fullRounded,
  ...generateColorSchemeForOutlineButton('primary')
};

// Define components variant
export const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: '700',
    fontSize: 'lg',
    color: 'white',
    py: '1rem',
    textAlign: 'center',
    _disabled: {
      ...generateColorSchemeForFillButton('dark.100'),
      opacity: 1,
      pointerEvents: 'none'
    }
  },
  variants: {
    primary: generateColorSchemeForFillButton('primary'),
    'primary-fullwidth-rounded': buttonPrimaryFullWidthRounded,
    'primary-fullwidth-rounded-shadow': {
      ...buttonPrimaryFullWidthRounded,
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)'
    },
    danger: generateColorSchemeForFillButton('danger'),
    'primary-outline': generateColorSchemeForOutlineButton('primary'),
    'primary-outline-fullwidth-rounded': buttonPrimaryOutlineFullWidthRounded,
    'dark-outline': generateColorSchemeForOutlineButton('dark.400'),
    'primary-link': {
      textDecoration: 'none',
      ...generateColorSchemeForLinkButton('primary'),
      _disabled: {
        ...generateColorSchemeForLinkButton('dark.400'),
        backgroundColor: 'transparent',
        pointerEvents: 'none'
      }
    },
    'danger-link': {
      textDecoration: 'none',
      ...generateColorSchemeForLinkButton('danger')
    }
  }
};

export const generateColorSchemeForCheckBox = (variant: string) => ({
  control: {
    _checked: {
      bg: variant,
      borderColor: variant,
      _hover: {
        bg: variant,
        borderColor: variant
      }
    }
  }
});

export const Checkbox: ComponentStyleConfig = {
  defaultProps: {
    size: 'lg'
  },
  variants: {
    primary: generateColorSchemeForCheckBox('primary'),
    secondary: generateColorSchemeForCheckBox('secondary')
  }
};

export const Text: ComponentStyleConfig = {
  baseStyle: {
    fontSize: 'sm'
  }
};

export const Link: ComponentStyleConfig = {
  variants: {
    primary: generateColorSchemeForFillButton('primary'),
    'primary-fullwidth-rounded': buttonPrimaryFullWidthRounded,
    'primary-fullwidth-rounded-shadow': {
      ...buttonPrimaryFullWidthRounded,
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)'
    }
  }
};

export const Badge: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    px: '0.5rem',
    py: '0.125rem',
    me: '0.25rem',
    color: 'white',
    ...fullRounded
  },
  variants: {
    default: {
      backgroundColor: 'secondary'
    }
  },
  defaultProps: {
    variant: 'default'
  }
};

export const Accordion: ComponentStyleConfig = {
  variants: {
    default: {
      button: {
        bg: 'white',
        px: '1rem',
        _hover: {
          bg: 'white'
        },
        _focus: {
          boxShadow: 'none'
        },
        _disabled: {
          bg: 'dark.100'
        }
      }
    }
  },
  defaultProps: {
    variant: 'default'
  }
};

export const Heading: ComponentStyleConfig = {
  sizes: {
    md: {
      fontSize: 'md',
      fontWeight: '700',
      lineHeight: 1.4
    }
  }
};
