import liff from '@line/liff';
import axios, { AxiosRequestHeaders } from 'axios';
import {
  CENTRAL_API_ENDPOINT,
  FEATURES_API_ENDPOINT,
  TENANT_CODE
} from 'libs/constants';
import { ResponseTenantConfigurationType } from 'types/TenantType';

export interface ResponseType {
  status: 'succeeded' | number;
  data: object;
  errors: [];
}

export type RegisterUTMAccountType = {
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
};

interface GetRequestHeaderType {
  hasAccessToken?: boolean;
  hasIdToken?: boolean;
}

export const getRequestHeader = ({
  hasAccessToken = false,
  hasIdToken = false
}: GetRequestHeaderType) => {
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json; charset=UTF-8'
  };

  if (hasAccessToken) {
    const accessToken = liff.getAccessToken();
    if (!accessToken) {
      handleResetLocalStorage();
      handleMoveToErrorPage('session-expired');
      return;
    }
    headers['Access-Token'] = accessToken;
  }

  if (hasIdToken) {
    const idToken = liff.getIDToken();
    if (!idToken) {
      handleResetLocalStorage();
      handleMoveToErrorPage('session-expired');
      return;
    }
    headers['Token-Id'] = idToken;
  }
  return headers;
};

export const centralApi = axios.create({
  baseURL: `${CENTRAL_API_ENDPOINT}/${TENANT_CODE}`
});

export const featuresApi = axios.create({
  baseURL: `${FEATURES_API_ENDPOINT}/${TENANT_CODE}`
});

export const handleResetLocalStorage = () => {
  localStorage.clear();
};

export const handleMoveToErrorPage = (code: string) => {
  window.location.replace(`/${TENANT_CODE}/error?error_code=${code}`);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatErrorObject = (error: any) =>
  ({
    ...error.response.data,
    status: error.response.status
  } as ResponseType);

// TODO: 今後の対応でも構いませんので、responseとerrorのformattingはinterceptorの方でやって、共通にしましょう。
centralApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // NOTE: Move the user to /error page in case of the error response with 500 status.
    if (error.response?.status === 500) {
      handleMoveToErrorPage('server-error');
      return;
    }

    // NOTE: Move the user to session expired page in case of the error response with "IdToken expired." message
    // NOTE: Extract cart value in localstorage to prevent remove the value on reset localstorage.
    if (error.response) {
      const errorObject = error.response.data?.errors;
      if (errorObject.includes('IdToken expired.')) {
        handleResetLocalStorage();
        handleMoveToErrorPage('session-expired');
        return;
      }
    }
    return Promise.reject(error);
  }
);

featuresApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // NOTE: Move the user to /error page in case of the error response with 500 status.
    if (error.response?.status === 500) {
      handleMoveToErrorPage('server-error');
      return;
    }

    // NOTE: Move the user to session expired page in case of the error response with "IdToken expired." message
    // NOTE: Extract cart value in localstorage to prevent remove the value on reset localstorage.
    if (error.response) {
      const errorObject = error.response.data?.errors;
      if (errorObject.includes('IdToken expired.')) {
        handleResetLocalStorage();
        handleMoveToErrorPage('session-expired');
        return;
      }
    }
    handleMoveToErrorPage('invalid-tenant-code');
    return;
  }
);

/** テナント取得API */
export const getTenantConfiguration = () =>
  featuresApi
    .request<ResponseTenantConfigurationType>({
      url: '/',
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: false })
    })
    .then((res) => res.data)
    .catch((error) =>
      Promise.reject({
        ...error.response.data,
        status: error.response.status
      })
    );

/** ユーザ取得API */
export const signinUser = () =>
  centralApi
    .request<ResponseType>({
      url: '/users/current',
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status
    }));

/** ユーザ作成API */
export const signupUser = (utm_data: RegisterUTMAccountType) =>
  centralApi
    .request<ResponseType>({
      url: '/users',
      method: 'POST',
      data: utm_data,
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      status: error.response.status
    }));
