import dispatchToast from 'components/modules/Toast';
import { getJupiterPrepaidBalance } from 'features/membership/api';
import { JUPITER_FEATURE_CODES } from 'features/membership/libs/const/plugins/jupiterFeatureCodes';
import { membershipPluginFeatureState } from 'features/membership/libs/recoil/selector';
import { JupiterPrepaidBalanceResponse } from 'features/membership/types';
import { API_RESPONSE_STATUS } from 'libs/constants';
import _ from 'lodash';
import { useAsyncFn } from 'react-use';
import { useRecoilValue } from 'recoil';

type UsePrepaidBalanceReturns = {
  prepaidBalanceResponseData: JupiterPrepaidBalanceResponse['data'] | undefined;
  isMutating: boolean;
  executeGetPrepaidBalance: () => Promise<
    JupiterPrepaidBalanceResponse['data'] | undefined
  >;
};

/** マネー残高取得API（VD）を実行するhook */
// TODO: 画面テンプレートに基づいているので、配置フォルダ改善要検討
// DIGICLUE_LINEMINIAPP-5088 【パッケージ_VD】[FE] プラグイン・画面テンプレートのエイリアスコードに関する命名規則検討
const usePrepaidBalance = (): UsePrepaidBalanceReturns => {
  const isPrepaidBalanceActive = useRecoilValue(
    membershipPluginFeatureState
  ).jupiter?.includes(_.camelCase(JUPITER_FEATURE_CODES.GET_BALANCE));

  const [
    { value: prepaidBalanceResponseData, loading: isMutating },
    executeGetPrepaidBalance
  ] = useAsyncFn(async () => {
    if (!isPrepaidBalanceActive) return;

    const res = await getJupiterPrepaidBalance();

    if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
      return res.data;
    }

    // その他エラー
    dispatchToast({
      id: 'membership-balance',
      toastText: res.errors.join('\n')
    });
  }, [isPrepaidBalanceActive]);

  return {
    prepaidBalanceResponseData,
    isMutating,
    executeGetPrepaidBalance
  };
};

export default usePrepaidBalance;
