import dispatchToast from 'components/modules/Toast';
import {
  patchMembershipCard,
  postMembershipMember
} from 'features/membership/api';
import {
  CompletePagePath,
  FormPagePath
} from 'features/membership/types/pagePath';
import { membershipMemberResponseAtom } from 'libs/recoil/atom';
import { MouseEventHandler, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useSetRecoilState } from 'recoil';

type UseSimpleConfirmReturn = {
  onClickRegisterButton: MouseEventHandler;
  onClickFixButton: MouseEventHandler;
  cardNumber: string | unknown;
  isMutating?: boolean;
};

const useSimpleConfirm: (
  props: CompletePagePath & FormPagePath
) => UseSimpleConfirmReturn = ({ formPagePath, completePagePath }) => {
  const { state: card_number, pathname } = useLocation();
  const navigate = useNavigate();
  const setMembershipMemberResponse = useSetRecoilState(
    membershipMemberResponseAtom
  );

  const [{ loading: isMutating }, onClickRegisterButton] =
    useAsyncFn(async () => {
      if (typeof card_number !== 'string') return;
      const res = await (completePagePath ===
        '/membership/simple/signup/complete'
        ? postMembershipMember
        : patchMembershipCard)(card_number, false);

      // エラー
      if (res.status === 'failed') {
        dispatchToast({
          id: 'membership-member-api-error',
          toastText: res.errors.join('\n')
        });
        navigate(formPagePath, { state: card_number });
        return;
      }

      setMembershipMemberResponse(undefined); // MBS-API003 会員情報照会API のレスポンス のグローバルステートを削除
      navigate(pathname, { replace: true }); // state を削除
      navigate(completePagePath); // 完了ページへ
    }, [card_number, pathname]);
  const onClickFixButton = useCallback<MouseEventHandler>(() => {
    navigate(formPagePath, { state: card_number });
  }, [card_number, formPagePath, navigate]);

  return {
    onClickRegisterButton,
    isMutating,
    onClickFixButton,
    cardNumber: card_number
  };
};
export default useSimpleConfirm;
