import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  ListItem,
  Skeleton,
  SkeletonText,
  Stack,
  UnorderedList,
  VStack
} from '@chakra-ui/react';
import { FC } from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';
import CampaignReceiptNote from 'features/reservations/components/CampaignReceiptNote';
import PreReservedUndueCaution from 'features/reservations/components/PreReservedUndueCaution';
import ReservationFormViewer from 'features/reservations/components/ReservationFormViewer';
import ReservationNumber from 'features/reservations/components/ReservationNumber';
import ReservedProductListSection from 'features/reservations/components/ReservedProductListSection';
import ReservedSummarySection from 'features/reservations/components/ReservedSummarySection';
import { useCampaignDetail } from 'features/reservations/hooks/useCampaign';
import { useCart } from 'features/reservations/hooks/useCart';
import { useReservation } from 'features/reservations/hooks/useReservation';
import {
  getCaveatForCancelReservation,
  getReservedSummaryCaveatsByStatus
} from 'features/reservations/libs/caveats';
import { useEffectOnce } from 'react-use';

/** 予約完了 */
const ReservationCompleted: FC = () => {
  const { campaignId } = useParams();
  const { targetCampaign, isDone: isDoneTargetCampaign } = useCampaignDetail(
    Number(campaignId)
  );

  const { removeCart } = useCart(Number(campaignId));

  const [searchParams] = useSearchParams();
  const reservationId = searchParams.get('reservationId') || '';
  const isPrepaidReservation = (searchParams.get('isPrepaid') || '') === 'true';
  const { reservation, isDone: isDoneReservation } = useReservation(
    Number(reservationId)
  );

  const navigate = useNavigate();

  useEffectOnce(() => {
    removeCart(Number(campaignId));
    window.addEventListener('popstate', () => {
      navigate('/reservations/', { replace: true });
    });
  });

  return (
    <MainLayout header>
      <Container mt="2.5rem" mb="1rem" px="1rem" pb="1rem">
        <VStack
          alignItems="center"
          textAlign="center"
          rowGap="1rem"
          mb="1.5rem"
        >
          <Heading>
            お申し込み
            <br />
            ありがとうございます！
          </Heading>

          {isPrepaidReservation && reservation?.payment_deadline_at && (
            <PreReservedUndueCaution
              paymentDeadLineAt={reservation.payment_deadline_at}
            />
          )}
          <ReservationNumber
            reservationNumber={reservation?.reservation_number}
            isDone={isDoneReservation}
          />
          <Skeleton isLoaded={isDoneReservation && isDoneTargetCampaign}>
            <Box w="100%" display="block">
              <RouterLink to={`/reservations/history/${reservation?.id}`}>
                <Button variant="primary-fullwidth-rounded">
                  予約内容を確認する
                </Button>
              </RouterLink>
              <RouterLink to="/reservations/">
                <Button mt="1rem" variant="primary-link">
                  予約注文トップに戻る
                </Button>
              </RouterLink>
            </Box>
          </Skeleton>

          <Divider w="100vw" />
        </VStack>

        {/* TODO: DIGICLUE_LINEMINIAPP-3918 【パッケージ_商品予約】UI変更 で余白の指定方法見直す */}
        {reservation?.campaign_receipt_note && (
          <Box mb="1.5rem">
            <CampaignReceiptNote
              campaign_receipt_note={reservation.campaign_receipt_note}
            />
          </Box>
        )}

        <SkeletonText
          isLoaded={isDoneReservation && isDoneTargetCampaign}
          noOfLines={8}
        >
          <Stack gap="1.5rem">
            {reservation && (
              <ReservationFormViewer
                headding="予約内容"
                targetCampaign={targetCampaign}
                reservationForm={{ ...reservation }}
              />
            )}
            <UnorderedList fontSize="sm" mt="0.5rem">
              <ListItem>
                受取日時になったら、受取店舗のサービスカウンターでこちらの画面をお見せください。
              </ListItem>
              <ListItem>
                {getCaveatForCancelReservation(
                  targetCampaign?.cancellation_deadline_days ?? null
                )}
              </ListItem>
            </UnorderedList>
            <ReservedProductListSection
              products={reservation?.products}
              roundingAlias={reservation?.tax_round ?? 'round_off'}
              isLoaded={isDoneReservation}
            />
            <ReservedSummarySection
              total={reservation?.total_with_tax}
              isLoaded={isDoneReservation}
              caveats={getReservedSummaryCaveatsByStatus(
                'cart',
                targetCampaign?.pre_paymentable ?? false
              )}
            />
          </Stack>
        </SkeletonText>
      </Container>
    </MainLayout>
  );
};

export default ReservationCompleted;
