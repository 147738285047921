import {
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack
} from '@chakra-ui/react';
import FormInputField from 'components/modules/FormInputField';
import LabeledValue from 'components/modules/labeledValue/LabeledValue';
import CuminNameFieldGroup from 'features/membership/components/cumin/CuminNameFieldGroup';
import useStoreOptions from 'features/membership/hooks/cumin/useStoreOptions';
import {
  BIRTHDAY_DATE_OPTIONS,
  BIRTHDAY_MONTH_OPTIONS,
  GENDER_OPTIONS
} from 'features/membership/libs/const/cuminCardIssueOption';
import { CuminPagePageTemplateOptionKey } from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { CuminIssueCardFormInput } from 'features/membership/types/form';
import { FC } from 'react';
import { Controller, FieldPath, useFormContext } from 'react-hook-form';

type CuminMemberProfileFormProps = {
  isBirthdayEditable: boolean;
  showEntryStoreCodeField?: boolean;
  visibleFields?: string[];
  requiredFields?: string[];
  templateOptions?: Record<CuminPagePageTemplateOptionKey, PageTemplateOption>;
  // NOTE: 会員情報編集の場合、useFormから生年月日を排除してしまうので親コンポーネントから取得
  birthdayTextForDisplay?: string;
};

const CuminMemberProfileForm: FC<CuminMemberProfileFormProps> = ({
  isBirthdayEditable,
  showEntryStoreCodeField,
  visibleFields,
  requiredFields,
  templateOptions,
  birthdayTextForDisplay
}) => {
  const {
    register,
    formState: { errors },
    clearErrors,
    getValues,
    trigger,
    control
  } = useFormContext<Partial<CuminIssueCardFormInput>>();
  const storeOptions = useStoreOptions();

  /**
   * 操作しているフォームと相互関係にあるフォームの必須エラーを解除する
   * @param groupName 相互関係にあるフォームグループの名前 ex. birthday
   * @param propertyNames 確認したいフォームの名前リスト
   */
  const clearMutualRequiredError =
    (groupName: string, propertyNames: FieldPath<CuminIssueCardFormInput>[]) =>
    () => {
      {
        const values = getValues(propertyNames);
        // 対象のpropertyNamesの値が1つでも存在する、もしくは必須項目リストに含まれている場合は処理を中断
        if (values.some((e) => !!e) || requiredFields?.includes(groupName))
          return;

        // 入力条件が任意の場合、必須チェックエラー表示を削除する
        clearErrors(propertyNames);
      }
    };

  return (
    <>
      {visibleFields?.includes('full_name') && (
        <CuminNameFieldGroup
          lastNameField={{
            inputComponent: (
              <FormInputField
                label="姓"
                isRequired={Boolean(requiredFields?.includes('full_name'))}
              >
                <Input
                  type="text"
                  placeholder="山田"
                  {...register('full_name.lastName', {
                    // NOTE: useFormのモードと同じイベント処理タイミングだとうまくいかないので、onBlurを利用
                    onBlur: () => {
                      const others: FieldPath<CuminIssueCardFormInput> =
                        'full_name.firstName';
                      clearMutualRequiredError('full_name', [others]);
                      // 入力した後に、相互関係にあるフォームもバリデーションチェックする
                      trigger(others);
                    }
                  })}
                />
              </FormInputField>
            ),
            error: errors.full_name?.lastName?.message
          }}
          firstNameField={{
            inputComponent: (
              <FormInputField
                label="名"
                isRequired={Boolean(requiredFields?.includes('full_name'))}
              >
                <Input
                  type="text"
                  placeholder="太郎"
                  {...register('full_name.firstName', {
                    // NOTE: useFormのモードと同じイベント処理タイミングだとうまくいかないので、onBlurを利用
                    onBlur: () => {
                      const others: FieldPath<CuminIssueCardFormInput> =
                        'full_name.lastName';
                      clearMutualRequiredError('full_name', [others]);
                      // 入力した後に、相互関係にあるフォームもバリデーションチェックする
                      trigger(others);
                    }
                  })}
                />
              </FormInputField>
            ),
            error: errors.full_name?.firstName?.message
          }}
          noticeContent={
            templateOptions?.memberFormFullNameNoticeContentOption?.content
          }
        />
      )}
      {visibleFields?.includes('full_name_kana') && (
        <CuminNameFieldGroup
          lastNameField={{
            inputComponent: (
              <FormInputField
                label="セイ"
                isRequired={Boolean(requiredFields?.includes('full_name_kana'))}
              >
                <Input
                  type="text"
                  placeholder="ヤマダ"
                  {...register('full_name_kana.lastName', {
                    // NOTE: useFormのモードと同じイベント処理タイミングだとうまくいかないので、onBlurを利用
                    onBlur: () => {
                      const others: FieldPath<CuminIssueCardFormInput> =
                        'full_name_kana.firstName';
                      clearMutualRequiredError('full_name_kana', [others]);
                      // 入力した後に、相互関係にあるフォームもバリデーションチェックする
                      trigger(others);
                    }
                  })}
                />
              </FormInputField>
            ),
            error: errors.full_name_kana?.lastName?.message
          }}
          firstNameField={{
            inputComponent: (
              <FormInputField
                label="メイ"
                isRequired={Boolean(requiredFields?.includes('full_name_kana'))}
              >
                <Input
                  type="text"
                  placeholder="タロウ"
                  {...register('full_name_kana.firstName', {
                    // NOTE: useFormのモードと同じイベント処理タイミングだとうまくいかないので、onBlurを利用
                    onBlur: () => {
                      const others: FieldPath<CuminIssueCardFormInput> =
                        'full_name_kana.lastName';
                      clearMutualRequiredError('full_name_kana', [others]);
                      // 入力した後に、相互関係にあるフォームもバリデーションチェックする
                      trigger(others);
                    }
                  })}
                />
              </FormInputField>
            ),
            error: errors.full_name_kana?.firstName?.message
          }}
          noticeContent={
            templateOptions?.memberFormFullNameKanaNoticeContentOption?.content
          }
        />
      )}
      {isBirthdayEditable && visibleFields?.includes('birthday') && (
        <FormInputField
          label="生年月日"
          warning={
            templateOptions?.memberFormBirthdayNoticeContentOption?.content
          }
          error={
            errors.birthday?.year?.message ??
            errors.birthday?.month?.message ??
            errors.birthday?.date?.message
          }
          isRequired={Boolean(requiredFields?.includes('birthday'))}
        >
          <HStack>
            <Input
              type="tel"
              placeholder="年"
              {...register('birthday.year', {
                // NOTE: useFormのモードと同じイベント処理タイミングだとうまくいかないので、onBlurを利用
                onBlur: () => {
                  const others: FieldPath<CuminIssueCardFormInput>[] = [
                    'birthday.month',
                    'birthday.date'
                  ];
                  clearMutualRequiredError('birthday', others);
                  // 入力した後に、相互関係にあるフォームもバリデーションチェックする
                  trigger(others);
                }
              })}
            />
            <Select
              placeholder="月"
              {...register('birthday.month', {
                // NOTE: useFormのモードと同じイベント処理タイミングだとうまくいかないので、onBlurを利用
                onBlur: () => {
                  const others: FieldPath<CuminIssueCardFormInput>[] = [
                    'birthday.year',
                    'birthday.date'
                  ];
                  clearMutualRequiredError('birthday', others);
                  // 入力した後に、相互関係にあるフォームもバリデーションチェックする
                  trigger(others);
                }
              })}
            >
              {BIRTHDAY_MONTH_OPTIONS.map(({ key, label, value }) => (
                <option key={key} value={value}>
                  {label}
                </option>
              ))}
            </Select>
            <Select
              placeholder="日"
              {...register('birthday.date', {
                // NOTE: useFormのモードと同じイベント処理タイミングだとうまくいかないので、onBlurを利用
                onBlur: () => {
                  const others: FieldPath<CuminIssueCardFormInput>[] = [
                    'birthday.year',
                    'birthday.month'
                  ];
                  clearMutualRequiredError('birthday', others);
                  // 入力した後に、相互関係にあるフォームもバリデーションチェックする
                  trigger(others);
                }
              })}
            >
              {BIRTHDAY_DATE_OPTIONS.map(({ key, label, value }) => (
                <option key={key} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </HStack>
        </FormInputField>
      )}
      {/* NOTE: isBirthdayEditableがfalseの場合は、会員情報編集画面と判断し、親コンポーネントから誕生日データを取得 */}
      {!isBirthdayEditable && visibleFields?.includes('birthday') && (
        <LabeledValue label="生年月日" value={birthdayTextForDisplay} />
      )}
      {visibleFields?.includes('gender') && (
        <FormInputField
          label="性別"
          warning={
            templateOptions?.memberFormGenderNoticeContentOption?.content
          }
          error={errors.gender?.message}
          isRequired={Boolean(requiredFields?.includes('gender'))}
        >
          <Controller
            name="gender"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RadioGroup
                onBlur={onBlur}
                onChange={onChange}
                value={value as string}
              >
                <Stack direction="row" gap="2rem">
                  {GENDER_OPTIONS.map((gender) => (
                    <Radio
                      key={gender.key}
                      fontSize="1rem"
                      fontWeight={400}
                      color="black"
                      value={gender.value}
                    >
                      {gender.label}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          />
        </FormInputField>
      )}
      {visibleFields?.includes('phone_number') && (
        <FormInputField
          label="電話番号"
          warning={
            templateOptions?.memberFormPhoneNumberNoticeContentOption?.content
          }
          error={errors.phone_number?.message}
          isRequired={Boolean(requiredFields?.includes('phone_number'))}
        >
          <Input
            type="tel"
            placeholder="00000000000"
            zIndex={0}
            {...register('phone_number')}
          />
        </FormInputField>
      )}
      {visibleFields?.includes('postal_code') && (
        <FormInputField
          label="郵便番号"
          warning={
            templateOptions?.memberFormPostalCodeNoticeContentOption?.content
          }
          error={errors.postal_code?.message}
          isRequired={Boolean(requiredFields?.includes('postal_code'))}
        >
          <Input
            type="tel"
            placeholder="0000000"
            {...register('postal_code')}
          />
        </FormInputField>
      )}
      {visibleFields?.includes('address_1') && (
        <FormInputField
          label="都道府県・市区町村"
          warning={
            templateOptions?.memberFormAddress1NoticeContentOption?.content
          }
          error={errors.address_1?.message}
          isRequired={Boolean(requiredFields?.includes('address_1'))}
        >
          <Input
            type="text"
            placeholder="○○県○○市○○"
            {...register('address_1')}
          />
        </FormInputField>
      )}
      {visibleFields?.includes('address_2') && (
        <FormInputField
          label="番地以降"
          warning={
            templateOptions?.memberFormAddress2NoticeContentOption?.content
          }
          error={errors.address_2?.message}
          isRequired={Boolean(requiredFields?.includes('address_2'))}
        >
          <Input type="text" placeholder="1-1-1" {...register('address_2')} />
        </FormInputField>
      )}
      {visibleFields?.includes('occupation') && (
        <FormInputField
          label="職業"
          warning={
            templateOptions?.memberFormOccupationNoticeContentOption?.content
          }
          error={errors.occupation?.message}
          isRequired={Boolean(requiredFields?.includes('occupation'))}
        >
          <Select
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            placeholder="選択してください"
            {...register('occupation', { valueAsNumber: true })}
          >
            {templateOptions?.memberFormOccupationObjectListOption?.object_list?.map(
              ({ label, value }) => (
                <option key={`occupation_${value}`} value={value}>
                  {label}
                </option>
              )
            )}
          </Select>
        </FormInputField>
      )}
      {visibleFields?.includes('household_size') && (
        <FormInputField
          label="ご家族人数"
          warning={
            templateOptions?.memberFormHouseholdSizeNoticeContentOption?.content
          }
          error={errors.household_size?.message}
          isRequired={Boolean(requiredFields?.includes('household_size'))}
        >
          <Input
            type="tel"
            placeholder="1"
            {...register('household_size', { valueAsNumber: true })}
          />
        </FormInputField>
      )}
      {/* 実際は入会店コードなので、編集はできない */}
      {showEntryStoreCodeField && visibleFields?.includes('entry_store_id') && (
        <FormInputField
          label="よく行く店舗"
          warning={
            templateOptions?.memberFormEntryStoreIdNoticeContentOption?.content
          }
          error={errors.entry_store_id?.message}
          isRequired={Boolean(requiredFields?.includes('entry_store_id'))}
        >
          <Select
            placeholder="選択してください"
            {...register('entry_store_id', { valueAsNumber: true })}
          >
            {storeOptions.map(({ key, label, value }) => (
              <option key={key} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </FormInputField>
      )}
    </>
  );
};
export default CuminMemberProfileForm;
