import { Box, Flex, HStack, Skeleton, SkeletonText } from '@chakra-ui/react';
import { FC } from 'react';

export const CampaignListSkeletonBox: FC = () => (
  <Box borderRadius="0.5rem" w="100%" bg="white" overflow="hidden">
    <Skeleton h="7rem" w="100%" />
    <Box px="0.75rem" py="1.5rem">
      <SkeletonText noOfLines={1} />
    </Box>
  </Box>
);

// NOTE: Phase 2 以降でCampaignListItemと違うものになる可能性あり
export const CampaignGroupListSkeletonBox: FC = () => (
  <Box borderRadius="0.5rem" w="100%" bg="white" overflow="hidden">
    <Skeleton h="7rem" w="100%" />
    <Box px="0.75rem" py="1.5rem">
      <SkeletonText noOfLines={1} />
    </Box>
  </Box>
);

export const ProductListSkeletonBox: FC = () => (
  <Flex flexDir="row" alignItems="flex-start" gap="0.5rem" w="100%" minH="5rem">
    <Skeleton w="5rem" h="5rem" flexShrink={0} />
    <Box w="100%">
      <SkeletonText noOfLines={1} mt="0.25rem" mb="1rem" />
      <SkeletonText noOfLines={3} w="40%" />
    </Box>
  </Flex>
);

export const ReservationHistorySkeletonBox: FC = () => (
  <Box bg="white" w="100%" borderRadius="0.25rem">
    <HStack alignItems="center" position="relative">
      <Skeleton
        h="7rem"
        w="7.5rem"
        bg="gray.400"
        flexShrink={0}
        borderTopLeftRadius="0.25rem"
        borderBottomLeftRadius="0.25rem"
      />
      <Box w="100%" px="0.25rem" pe="0.5em" py="0.25rem">
        <Skeleton w="90%" h="20px" mb="1rem" />
        <SkeletonText w="90%" noOfLines={3} />
      </Box>
    </HStack>
  </Box>
);
