import 'react-datepicker/dist/react-datepicker.css';

import {
  Box,
  Button,
  Heading,
  ListItem,
  Skeleton,
  Spacer,
  Stack,
  UnorderedList
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { createReservation } from 'features/reservations/api';

import { useStore } from 'features/my-store/hooks/useStore';
import { useCampaignDetail } from 'features/reservations/hooks/useCampaign';
import { useInvoice } from 'features/reservations/hooks/useInvoice';

import MainLayout from 'components/layouts/MainLayout';
import dispatchToast from 'components/modules/Toast';
import ReservedSummarySection from 'features/reservations/components/ReservedSummarySection';
import { API_RESPONSE_STATUS } from 'libs/constants';

import 'assets/styles/date-picker.scss';
import { ReservationPrepaidNotification } from 'features/reservations/components/CaveatsSections';
import ProductListItem from 'features/reservations/components/ProductListItem';
import ReservationFormViewer from 'features/reservations/components/ReservationFormViewer';
import { ProductListSkeletonBox } from 'features/reservations/components/SkeletonBoxes';
import { useCart } from 'features/reservations/hooks/useCart';
import {
  getCaveatForCancelReservation,
  getReservedSummaryCaveatsByStatus
} from 'features/reservations/libs/caveats';
import { ReservationConfirmationParameter } from 'features/reservations/types/ReservationType';

/** 予約内容確認 */
const ReservationFinalConfirmation: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { campaignId } = useParams();
  const { targetCampaign, isDone: isDoneTargetCampaign } = useCampaignDetail(
    Number(campaignId)
  );

  const { cartProducts } = useCart(Number(campaignId));

  const { state } = location as ReservationConfirmationParameter;
  const { targetStore } = useStore(state.storeId);

  const additionalStateParams = {
    receipt_at: state.receipt_at,
    receipt_hour_width: state.receipt_hour_width,
    card_number: state.card_number ?? '',
    tel_number: state.tel_number ?? '',
    staff_comment: state.staff_comment ?? '',
    option_1_answer: state.option_1_answer ?? '',
    option_2_answer: state.option_2_answer ?? '',
    option_3_answer: state.option_3_answer ?? ''
  };

  const {
    lineProducts,
    totalAmount,
    isDoneCalculation,
    paymentDeadline,
    requestToken
  } = useInvoice(
    Number(campaignId),
    Number(state.storeId),
    cartProducts,
    additionalStateParams,
    true
  );

  const formatTime = (time: string) => {
    return dayjs(`2000/01/01 ${time}`).format('H:mm');
  };

  const [isLoadingSubmission, setIsLoadingSubmission] =
    useState<boolean>(false);

  const handleSubmit = () => {
    setIsLoadingSubmission(true);

    createReservation({
      store_id: state.storeId ?? 0,
      campaign_id: Number(campaignId),
      products: cartProducts.map(({ id, quantity }) => ({
        product_id: id,
        quantity
      })),
      request_token: requestToken,
      ...additionalStateParams
    }).then((res) => {
      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
        setIsLoadingSubmission(false);
        navigate(
          `/reservations/${campaignId}/completed?reservationId=${res.data.id}&isPrepaid=${targetCampaign?.pre_paymentable}`
        );
      } else {
        dispatchToast({
          id: 'toast-reservation-create-error',
          toastText: `エラー：${res.errors.join(', ')}`,
          center: true
        });
        setIsLoadingSubmission(false);
      }
    });
  };

  return (
    <MainLayout header>
      {/* TODO: 画面全体の余白指定方法の見直し */}
      <Stack px="1rem" py="2.5rem" gap="1.5rem">
        {targetCampaign?.pre_paymentable && paymentDeadline && (
          // HACK:画面左側側に親要素の padding 分余白ができてしまうため、calc(50% - 50vw) を追加
          <Box mx="calc(50% - 50vw)">
            <ReservationPrepaidNotification dueDate={paymentDeadline} />
          </Box>
        )}

        {targetStore && (
          <ReservationFormViewer
            headding="予約内容の確認"
            targetCampaign={targetCampaign}
            reservationForm={{
              store: targetStore,
              receipt_at: state?.receipt_at || '',
              receipt_hour_width_start_at: formatTime(
                state?.receipt_hour_width.split('~')[0] || ''
              ),
              receipt_hour_width_end_at: formatTime(
                state?.receipt_hour_width.split('~')[1] || ''
              ),
              card_number: state?.card_number || '',
              tel_number: state?.tel_number || '',
              staff_comment: state?.staff_comment || '',
              option_1_answer: state?.option_1_answer || '',
              option_2_answer: state?.option_2_answer || '',
              option_3_answer: state?.option_3_answer || ''
            }}
          />
        )}

        <Stack gap="1rem" w="100%">
          <Heading size="md">予約商品</Heading>
          {isDoneCalculation ? (
            lineProducts.map((product) => (
              <ProductListItem
                key={product.id}
                as="reservation"
                product={product}
                quantity={product.quantity}
              />
            ))
          ) : (
            <ProductListSkeletonBox />
          )}
        </Stack>

        <ReservedSummarySection
          total={totalAmount}
          isLoaded={isDoneCalculation}
          caveats={getReservedSummaryCaveatsByStatus(
            'cart',
            targetCampaign?.pre_paymentable ?? false
          )}
        />

        <Stack>
          <Heading size="md">返品・予約の取り消しについて</Heading>
          <Skeleton isLoaded={isDoneTargetCampaign}>
            <UnorderedList fontSize="sm">
              <ListItem>
                {getCaveatForCancelReservation(
                  targetCampaign?.cancellation_deadline_days ?? null
                )}
              </ListItem>
              <ListItem>商品受け取り後の返品は承りかねます。</ListItem>
              <ListItem>商品購入契約は来店時に成立いたします。</ListItem>
            </UnorderedList>
          </Skeleton>
        </Stack>
      </Stack>

      <Spacer h="4rem" />
      <Box
        className="c-bottom-area"
        w="100%"
        px="1rem"
        display="block"
        position="fixed"
        bottom="0.75rem"
      >
        <Button
          isLoading={isLoadingSubmission}
          loadingText="確定中…"
          variant="primary-fullwidth-rounded-shadow"
          isDisabled={!isDoneCalculation}
          onClick={handleSubmit}
        >
          この内容で予約を申し込む
        </Button>
      </Box>
    </MainLayout>
  );
};

export default ReservationFinalConfirmation;
