import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { ExternalDefaultIcon } from 'assets/icons/FooterIcons';
import { FEATURES } from 'libs/features';
import { openWindow } from 'libs/line';
import {
  membershipSettingAtom,
  tenantConfigurationAtom,
  unreadNotificationAtom
} from 'libs/recoil/atom';
import { activeFeaturesState } from 'libs/recoil/selector';
import _, { LoDashStatic } from 'lodash';
import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import IconWithBadge from './IconWithBadge';

type FooterItemType = {
  label: string;
  url: string;
  icon: JSX.Element;
  activeIcon?: JSX.Element;
  hasBadge?: boolean;
  handleClick: () => void | ReturnType<LoDashStatic['throttle']>;
};

const FooterItem: FC<FooterItemType> = ({
  label,
  url,
  icon,
  activeIcon,
  hasBadge,
  handleClick
}) => {
  const location = useLocation();
  const isActiveLink = (href: string) =>
    location.pathname.split('/')[1] === href.split('/')[1];

  return (
    <Box
      as="button"
      ms="0"
      width="100%"
      flexGrow="1"
      color={isActiveLink(url) ? 'primary' : 'dark.500'}
      textAlign="center"
      height="4.5rem"
      onClick={handleClick}
    >
      <IconWithBadge
        icon={isActiveLink(url) && activeIcon ? activeIcon : icon}
        hasBadge={hasBadge}
      />
      <Text fontWeight="700">{label}</Text>
    </Box>
  );
};

const Footer: FC = () => {
  const navigate = useNavigate();
  const tenantConfiguration = useRecoilValue(tenantConfigurationAtom);
  const activeFeatures = useRecoilValue(activeFeaturesState);
  const {
    page_template: { alias_code: aliasCode }
  } = useRecoilValue(membershipSettingAtom);
  const unreadNotification = useRecoilValue(unreadNotificationAtom);
  // NOTE: throttle 利用するにはmemo化する必要ある
  const footerRenderData = useMemo(
    () =>
      activeFeatures.map(({ label, path, icon, activeIcon }) => ({
        label,
        url: path,
        icon,
        activeIcon,
        hasBadge:
          // お知らせ用の未読バッジ
          !!unreadNotification.hasUnread &&
          label === FEATURES['notice']['label'],
        handleClick: _.throttle(
          () => {
            navigate(
              // NOTE: /membership と /membership/[alias_code] の違いによって、componentが unmountされるので、それを防ぎたい
              `${path}${
                path === FEATURES.membership.path && aliasCode
                  ? `/${aliasCode}`
                  : ''
              }`
            );
          },
          // NOTE: ボタン連打しても1.5秒間1回しか発火しない
          1500,
          { leading: true, trailing: false }
        )
      })),
    [activeFeatures, unreadNotification.hasUnread, navigate, aliasCode]
  );

  return (
    <Box w="100%" h="4.5rem" bg="white" className="c-bottom-area">
      <Flex gap="0" flex="1 0 auto">
        {tenantConfiguration.external_links.map((link) => (
          <FooterItem
            key={link.url}
            label={link.label}
            url={link.url}
            icon={
              link.icon ? (
                <Image
                  src={link.icon}
                  height="2rem"
                  width="100%"
                  objectFit="contain"
                  alt="リンクアイコン"
                />
              ) : (
                <ExternalDefaultIcon boxSize="2rem" />
              )
            }
            handleClick={() => openWindow(link.url)}
          />
        ))}
        {footerRenderData.map((renderData) => (
          <FooterItem key={renderData.url} {...renderData} />
        ))}
      </Flex>
    </Box>
  );
};

export default Footer;
