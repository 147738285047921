import { yupResolver } from '@hookform/resolvers/yup';
import useDebounce from 'features/membership/hooks/useDebounce';
import { createValidationRule } from 'features/membership/libs/validate';
import {
  CuminIssueCardFormInput,
  CuminUserProfileFormInput
} from 'features/membership/types/form';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { usePostalJp } from 'use-postal-jp';

/**
 * 会員情報共通フォームカスタムフック
 * 会員情報編集フォームの場合、生年月日とよく行く店舗はフォームの入力項目に含まれない
 * カード番号付番/入力画面、会員情報の変更画面で使用
 * @param defaultValues フォームの初期値
 * @returns methods: フォームメソッド, setServerErrors: サーバーエラー設定メソッド
 */
export const useMemberForm = ({
  defaultValues,
  formFields,
  requiredFormFields
}: {
  defaultValues: CuminUserProfileFormInput | undefined;
  formFields: string[] | undefined;
  requiredFormFields: string[] | undefined;
}) => {
  const { state } = useLocation();

  const methods = useForm<CuminIssueCardFormInput | CuminUserProfileFormInput>({
    mode: 'onChange',
    resolver: yupResolver(
      createValidationRule(formFields ?? [], requiredFormFields ?? [])
    ),
    shouldFocusError: true,
    defaultValues: defaultValues ?? {}
  });

  const setServerErrors = useCallback<
    (errors: Record<string, string[]>) => void
  >(
    (errors) => {
      Object.entries(errors).forEach(([fieldName, errorsArr]) => {
        switch (fieldName) {
          case 'full_name':
            methods.setError('full_name.lastName', {
              message: (errorsArr as string[]).join('、')
            });
            break;
          case 'full_name_kana':
            methods.setError('full_name_kana.lastName', {
              message: (errorsArr as string[]).join('、')
            });
            break;
          case 'birthday':
            methods.setError('birthday.year', {
              message: (errorsArr as string[]).join('、')
            });
            break;
          default:
            methods.setError(fieldName as keyof CuminUserProfileFormInput, {
              message: (errorsArr as string[]).join('、')
            });
        }
      });
    },
    [methods]
  );

  //  郵便番号のフォームに変更があった場合 かつ 7桁である場合に住所を自動入力する
  const { watch, getFieldState, setValue, trigger } = methods;
  const { debouncedValue: debouncedPostalCode } = useDebounce(
    watch('postal_code'),
    500
  );
  const { isDirty } = getFieldState('postal_code');
  // 郵便番号から住所取得
  const [address] = usePostalJp(
    debouncedPostalCode ?? '',
    isDirty && debouncedPostalCode?.length === 7 // 入力内容に変更がある かつ 7桁 であれば住所取得を実行
  );

  useEffect(() => {
    // フォームの全ての値を取得
    const allFormValues = methods.getValues();

    // フォームに表示されていない項目をフォーム入力から削除
    Object.keys(allFormValues).forEach((key) => {
      if (!formFields?.includes(key)) {
        methods.unregister(key as keyof CuminUserProfileFormInput);
      }
    });
  }, [formFields, methods]);

  useEffect(() => {
    if (!address) return;

    // 都道府県・市区町村を設定する
    setValue(
      'address_1',
      `${address.prefecture}${address.address1}${address.address2}`
    );
    trigger('address_1');
  }, [setValue, address, trigger]);

  useEffect(() => {
    if (!state?.errors) {
      return;
    }
    setServerErrors(state.errors);
  }, [state?.errors, setServerErrors]);

  return { methods, setServerErrors };
};
