import { Divider, Heading, Stack } from '@chakra-ui/react';
import HorizontalLabeledValue from 'components/modules/labeledValue/HorizontalLabeledValue';
import dayjs from 'dayjs';
import { CampaignType } from 'features/reservations/types/CampaignType';
import { ReservationFormType } from 'features/reservations/types/ReservationType';
import { FC } from 'react';

type ReservationFormViewerProps = {
  headding: '予約内容の確認' | '予約内容';
  targetCampaign: CampaignType | undefined;
  reservationForm: ReservationFormType;
};

/** 予約フォーム画面（ReservationCustomerForm）の入力内容を表示するコンポーネント */
const ReservationFormViewer: FC<ReservationFormViewerProps> = ({
  headding,
  targetCampaign,
  reservationForm
}) => {
  return (
    <Stack w="100%" gap="1.5rem">
      <Heading fontSize="xl" fontWeight="bold">
        {headding}
      </Heading>

      <Stack>
        <HorizontalLabeledValue
          label="受取店舗"
          value={reservationForm.store.name}
        />
        <Divider />
        <HorizontalLabeledValue
          label="受取日"
          value={dayjs(reservationForm.receipt_at).format('YYYY/MM/DD (ddd)')}
        />
        <Divider />
        <HorizontalLabeledValue
          label="受取時間"
          value={`${reservationForm.receipt_hour_width_start_at}〜${reservationForm.receipt_hour_width_end_at}`}
        />
        {(targetCampaign?.collect_card_number === 'required' ||
          targetCampaign?.collect_card_number === 'optional') && (
          <>
            <Divider />
            <HorizontalLabeledValue
              label="会員番号"
              value={reservationForm.card_number || ''}
            />
          </>
        )}
        {(targetCampaign?.collect_tel_number === 'required' ||
          targetCampaign?.collect_tel_number === 'optional') && (
          <>
            <Divider />
            <HorizontalLabeledValue
              label="電話番号"
              value={reservationForm.tel_number || ''}
            />
          </>
        )}
        {(targetCampaign?.collect_staff_comment === 'required' ||
          targetCampaign?.collect_staff_comment === 'optional') && (
          <>
            <Divider />
            <HorizontalLabeledValue
              label="受付スタッフ記入欄"
              value={reservationForm.staff_comment || ''}
            />
          </>
        )}
        {(targetCampaign?.option_1_status === 'required' ||
          targetCampaign?.option_1_status == 'optional') && (
          <>
            <Divider />
            <HorizontalLabeledValue
              label={targetCampaign?.option_1_label || '任意項目1'}
              value={reservationForm?.option_1_answer || ''}
            />
          </>
        )}
        {(targetCampaign?.option_2_status === 'required' ||
          targetCampaign?.option_2_status == 'optional') && (
          <>
            <Divider />
            <HorizontalLabeledValue
              label={targetCampaign?.option_2_label || '任意項目2'}
              value={reservationForm?.option_2_answer || ''}
            />
          </>
        )}
        {(targetCampaign?.option_3_status === 'required' ||
          targetCampaign?.option_3_status == 'optional') && (
          <>
            <Divider />
            <HorizontalLabeledValue
              label={targetCampaign?.option_3_label || '任意項目3'}
              value={reservationForm?.option_3_answer || ''}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default ReservationFormViewer;
