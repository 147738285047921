import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react';
import Barcode from 'components/modules/Barcode';
import Qrcode from 'components/modules/Qrcode';
import { ReservationStatusAliasKeys } from 'features/reservations/libs/reservationStatus';
import { FC, Fragment, memo, ReactNode } from 'react';
import ReservationNumber from './ReservationNumber';

/**
 * 丸角カードコンポーネント
 * バーコードが存在する商品表示と予約番号の表示に利用する
 */
const RadiusCard: FC<{
  headerContent: ReactNode;
  bodyContent?: ReactNode;
}> = ({ headerContent, bodyContent }) => (
  <Card
    boxShadow="xs"
    borderWidth="1px"
    borderColor="#E3E3E3"
    // bodyがない場合はbackground colorを設定する
    bgColor={bodyContent ? '' : '#F7F7F7'}
    rounded="lg"
  >
    {bodyContent ? (
      <>
        <CardHeader
          bgColor="#F7F7F7"
          alignItems="center"
          // NOTE: SquareとRadiusのコンポーネントの切り替え時にPaddingを可変にするため0に設定
          padding="0"
          borderTopRadius="8px"
          // bodyがない場合はradiusを設定する
          borderBottomRadius={bodyContent ? '0' : '8px'}
        >
          {headerContent}
        </CardHeader>
        <CardBody p="0.25rem">{bodyContent}</CardBody>
      </>
    ) : (
      headerContent
    )}
  </Card>
);

/**
 * 商品名・数表示コンポーネント
 * RadiusCardもしくはSquareCardで利用する
 */
const ItemDetail: FC<{
  name: string;
  quantity: string | number;
}> = ({ name, quantity }) => (
  <Flex
    padding="10px 16px"
    alignItems="center"
    justifyContent="space-between"
    gap="0.5rem"
  >
    <Text fontSize="md" fontWeight="bold" wordBreak="break-all">
      {name}
    </Text>
    <Text fontSize="xl">
      x
      <Text fontSize="3xl" fontWeight="bold" ml="0.5rem" as="span">
        {quantity}
      </Text>
    </Text>
  </Flex>
);

/**
 * 四角カードコンポーネント
 * バーコードが存在しない商品表示に利用する
 */
const SquareCard: FC<{ children: ReactNode }> = ({ children }) => (
  <Card
    boxShadow="xs"
    borderWidth="1px"
    borderColor="#E3E3E3"
    bgColor="#F7F7F7"
    rounded="xs"
  >
    {children}
  </Card>
);

type ProductBarcodeModalType = {
  isOpen: boolean;
  onClose: () => void;
  status?: ReservationStatusAliasKeys;
  reservationNumber?: string;
  isScanPayment: boolean;
  products: Array<{
    name: string;
    code: string;
    format: string;
    quantity: number;
  }>;
};

/** 商品バーコードモーダル */
const ProductBarcodeModal: FC<ProductBarcodeModalType> = ({
  isOpen,
  onClose,
  status,
  reservationNumber,
  products,
  isScanPayment = false
}) => {
  // リストの中にバーコード情報が一つでもあれば、RadiusCardを利用するように判断するフラグ
  const isIncludeBarcode = products.some((e) => e.code);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered autoFocus={false}>
      <ModalOverlay />
      <ModalContent maxH="80vh" mx="1rem" position="relative">
        <ModalHeader textAlign="center">
          <Text fontSize="xl">
            店舗の方にこの画面を
            <br />
            提示してください
          </Text>
        </ModalHeader>
        <Box maxH="70vh" overflowY="scroll">
          <ModalBody>
            <Stack spacing="1rem" mb="2rem">
              {products.map((product, ind) => (
                <Fragment key={ind}>
                  {isIncludeBarcode ? (
                    <RadiusCard
                      headerContent={
                        <ItemDetail
                          name={product.name}
                          quantity={product.quantity}
                        />
                      }
                      bodyContent={
                        product.code !== '' && (
                          <Center>
                            <Barcode
                              code={product.code}
                              format={product.format}
                              height={60}
                            />
                          </Center>
                        )
                      }
                    />
                  ) : (
                    <SquareCard>
                      <ItemDetail
                        name={product.name}
                        quantity={product.quantity}
                      />
                    </SquareCard>
                  )}
                </Fragment>
              ))}
            </Stack>
            {/* 仮予約 かつ 予約番号がある場合は、表示 */}
            {status === 'pre_reserved' && reservationNumber && (
              <RadiusCard
                headerContent={
                  <Flex padding="10px 20px" alignItems="center" gap="0.5rem">
                    <CheckCircleIcon color="green.400" boxSize="1.25rem" />
                    <Text fontSize="md" fontWeight="bold">
                      支払いを完了する
                    </Text>
                  </Flex>
                }
                bodyContent={
                  <>
                    <ReservationNumber
                      reservationNumber={reservationNumber}
                      isDone={true}
                    />
                    {isScanPayment && (
                      <>
                        <Center>
                          <Barcode
                            code={reservationNumber}
                            format="CODE128"
                            height={60}
                            displayValue={false}
                          />
                        </Center>
                        <Center>
                          <Qrcode text={reservationNumber} />
                        </Center>
                      </>
                    )}
                  </>
                }
              />
            )}
          </ModalBody>
          <ModalFooter w="100%">
            <Button
              mb="1rem"
              variant="dark-outline"
              py="0.25rem"
              w="100%"
              borderRadius="20px"
              onClick={onClose}
            >
              閉じる
            </Button>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default memo(ProductBarcodeModal);
