import { typedKeys } from 'libs/typedKeys';

const reservationStatuses = {
  reserved: {
    label: '予約済み',
    bgColor: 'secondary',
    color: 'white',
    isAvailableForPickup: true,
    isAvailableForCancel: true,
    reservationNumberColor: 'primary'
  },
  pre_reserved: {
    label: '仮予約済み',
    bgColor: 'secondary',
    color: 'white',
    isAvailableForPickup: false,
    isAvailableForCancel: true,
    reservationNumberColor: 'primary'
  },
  paid_reserved: {
    label: '支払い済み',
    bgColor: 'secondary',
    color: 'white',
    isAvailableForPickup: true,
    isAvailableForCancel: true,
    reservationNumberColor: 'primary'
  },
  received: {
    label: '受け取り済み',
    bgColor: '#fafafa',
    color: 'secondary',
    isAvailableForPickup: false,
    isAvailableForCancel: false,
    reservationNumberColor: 'dark.300'
  },
  canceled: {
    label: '取り消し済み',
    bgColor: 'dark.400',
    color: 'white',
    isAvailableForPickup: false,
    isAvailableForCancel: false,
    reservationNumberColor: 'dark.300'
  },
  auto_canceled: {
    label: '自動取り消し済み',
    bgColor: 'dark.400',
    color: 'white',
    isAvailableForPickup: false,
    isAvailableForCancel: false,
    reservationNumberColor: 'dark.300'
  },
  receipt_overdue: {
    label: '自動取り消し済み',
    bgColor: 'dark.400',
    color: 'white',
    isAvailableForPickup: false,
    isAvailableForCancel: false,
    reservationNumberColor: 'dark.300'
  },
  payment_overdue: {
    label: '自動取り消し済み',
    bgColor: 'dark.400',
    color: 'white',
    isAvailableForPickup: false,
    isAvailableForCancel: false,
    reservationNumberColor: 'dark.300'
  },
  manual_canceled: {
    label: '取り消し済み',
    bgColor: 'dark.400',
    color: 'white',
    isAvailableForPickup: false,
    isAvailableForCancel: false,
    reservationNumberColor: 'dark.300'
  }
} as const;

export type ReservationStatusAliasKeys = keyof typeof reservationStatuses;
export type ReservationStatusObjectType =
  (typeof reservationStatuses)[ReservationStatusAliasKeys];

export const getReservationStatusObjectByAlias = (
  alias: ReservationStatusAliasKeys
) => reservationStatuses[alias];

const reservationStatusAlias = typedKeys(reservationStatuses);

export const isAvailableReservationStatusAlias = (
  alias: string
): alias is ReservationStatusAliasKeys =>
  (reservationStatusAlias as string[]).includes(alias);
