import DigiclueplusPage from 'features/digiclueplus/components/DigiclueplusPage';
import { FC } from 'react';

const PointCouponTop: FC = () => {
  return (
    <DigiclueplusPage
      serviceCode="point_coupons"
      headding="ポイントクーポン"
      description="対象商品の確認はこちらから"
      buttonLabel="ポイントクーポン一覧へ"
    />
  );
};

export default PointCouponTop;
