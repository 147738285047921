import { yupResolver } from '@hookform/resolvers/yup';
import dispatchToast from 'components/modules/Toast';
import { MEMBER_FORM_ERROR_MESSAGES } from 'features/membership/libs/const/cuminMemberFormValidation';
import { earthFormatDataForLinkApi } from 'features/membership/libs/formatDataForApi';
import {
  defaultValidationRules,
  HALF_WIDTH_NUMBER_VALIDATION_MESSAGE
} from 'features/membership/libs/validate';
import {
  ErrorResponse,
  PostMembershipMemberResponse
} from 'features/membership/types';
import {
  CardLinkFormInputs,
  CuminCardLinkRequestParams
} from 'features/membership/types/form';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { BaseSyntheticEvent, useCallback, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { object, string } from 'yup';

type UseCardLinkFormReturn = {
  isMutating: boolean;
  methods: UseFormReturn<CardLinkFormInputs>;
  formSubmitHandler: (event?: BaseSyntheticEvent) => Promise<void>;
};

/**
 * Cuminのカード連携・再連携で利用するhooksをまとめたCustom Hook
 *
 * @param confirmPagePath 確認API実行後に遷移する確認画面のpath
 * @param confirmLinkCard 確認API実行関数
 */
export const useCardLinkForm = (
  confirmPagePath: string,
  confirmLinkCard: (
    data: CuminCardLinkRequestParams
  ) => Promise<PostMembershipMemberResponse | ErrorResponse>
): UseCardLinkFormReturn => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [{ loading: isMutating }, confirmFormData] = useAsyncFn<
    (formData: CardLinkFormInputs) => Promise<void>
  >(
    async (formData) => {
      if (typeof formData === 'undefined') return;

      const res = await confirmLinkCard(earthFormatDataForLinkApi(formData));
      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
        navigate(confirmPagePath, {
          state: { formData }
        });
        return;
      }

      if (res.error_fields && Object.keys(res.error_fields).length) {
        setServerErrors(res.error_fields);
        return;
      }

      // 個別な項目じゃない問題でエラーした場合
      dispatchToast({
        id: 'membership-member-api-error',
        toastText: res.errors?.join('\n')
      });
    },
    [confirmLinkCard]
  );

  const schema = object({
    card_number: string()
      .required(MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE)
      .matches(/^\d{1,256}$/, HALF_WIDTH_NUMBER_VALIDATION_MESSAGE),
    birthday: defaultValidationRules.birthday.concat(
      object({
        year: string().required(
          MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE
        ),
        month: string().required(
          MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE
        ),
        date: string().required(
          MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE
        )
      })
    )
  });

  const methods = useForm<CardLinkFormInputs>({
    // NOTE: onBlurだと初回入力時にバリデーショントリガーされないので、onChangeに設定
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      ...(state?.formData ?? {
        card_number: '',
        birthday: { year: '', month: '', date: '' }
      })
    }
  });

  const setServerErrors = useCallback<
    (errors: Record<string, string[]>) => void
  >(
    (errors) => {
      Object.entries(errors).forEach(([fieldName, errorsArr]) => {
        switch (fieldName) {
          case 'card_number':
            methods.setError('card_number', {
              message: (errorsArr as string[]).join('、')
            });
            break;
          case 'birthday':
            methods.setError('birthday.year', {
              message: (errorsArr as string[]).join('、')
            });
            break;
          case 'base':
            methods.setError('root', {
              message: (errorsArr as string[]).join('、')
            });
        }
      });
    },
    [methods]
  );

  const formSubmitHandler = methods.handleSubmit(
    async (formData) => await confirmFormData(formData)
  );

  useEffect(() => {
    if (state?.formData) {
      methods.reset(state.formData);
    }
  }, [state, methods]);

  useEffect(() => {
    if (!state?.errors) {
      return;
    }
    setServerErrors(state.errors);
  }, [state?.errors, setServerErrors]);

  return {
    isMutating,
    methods,
    formSubmitHandler
  };
};
