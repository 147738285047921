import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Heading,
  Stack,
  Text
} from '@chakra-ui/react';
import GingerBalanceDetailItem from 'features/membership/components/ginger/GingerBalanceDetailItem';
import { cashFormat } from 'features/membership/libs/numberFormat';
import { FC } from 'react';

type GingerBalanceDetailBoxProps = {
  balanceTotal: number | null;
  balanceBasic: {
    value: number | null;
    expireDate: string | null;
  };
  balanceBonus: {
    value: number | null;
    expireDate: string | null;
  };
  balanceCoupon: {
    value: number | null;
    expireDate: string | null;
  };
  unit?: string | null;
};

const GingerBalanceDetailAccordion: FC<GingerBalanceDetailBoxProps> = ({
  balanceTotal,
  balanceBasic,
  balanceBonus,
  balanceCoupon,
  unit
}) => (
  <Card p="1rem" boxShadow="none">
    <Accordion allowToggle>
      <AccordionItem borderStyle="none">
        <AccordionButton justifyContent="space-between" padding={0}>
          <Stack spacing=".25rem" alignItems="baseline">
            <Heading
              as="h3"
              lineHeight="1em"
              fontSize="md"
              wordBreak="break-all"
              whiteSpace="pre-wrap"
              fontWeight={400}
            >
              残高
            </Heading>

            {typeof balanceTotal === 'number' && (
              <Text
                lineHeight="1em"
                fontSize="3xl"
                textAlign="left"
                fontWeight={700}
                wordBreak="break-all"
              >
                {cashFormat(balanceTotal)}
                {!!unit && (
                  <Text
                    as="span"
                    fontSize="md"
                    fontWeight={700}
                    wordBreak="break-all"
                    whiteSpace="pre-wrap"
                  >
                    {unit}
                  </Text>
                )}
              </Text>
            )}
          </Stack>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel padding="1rem 0 0 0">
          {typeof balanceBasic.value === 'number' && (
            <GingerBalanceDetailItem
              title="チャージ残高"
              amount={balanceBasic.value}
              expireDate={balanceBasic.expireDate}
              unit={unit}
            />
          )}
          {typeof balanceBonus.value === 'number' && (
            <GingerBalanceDetailItem
              title="ボーナス残高"
              amount={balanceBonus.value}
              expireDate={balanceBonus.expireDate}
              unit={unit}
            />
          )}
          {typeof balanceCoupon.value === 'number' && (
            <GingerBalanceDetailItem
              title="クーポン残高"
              amount={balanceCoupon.value}
              expireDate={balanceCoupon.expireDate}
              unit={unit}
            />
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </Card>
);

export default GingerBalanceDetailAccordion;
