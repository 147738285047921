import dispatchToast from 'components/modules/Toast';
import { getEarthPointBalance } from 'features/membership/api';
import { EARTH_FEATURE_CODES } from 'features/membership/libs/const/plugins/earthFeatureCodes';
import { membershipPluginFeatureState } from 'features/membership/libs/recoil/selector';
import { EarthPointBalanceResponse } from 'features/membership/types';
import { API_RESPONSE_STATUS } from 'libs/constants';
import _ from 'lodash';
import { useState } from 'react';
import { useAsyncFn } from 'react-use';
import { useRecoilValue } from 'recoil';

type UsePointBalanceReturns = {
  // ポイントや有効期限などの時間のオブジェクト
  pointBalanceResponseData: EarthPointBalanceResponse['data'] | undefined;
  // サービス時間外の時間とサービス時間のオブジェクト
  serviceTime:
    | {
        available_hour?: string;
        unavailable_hour?: string;
      }
    | undefined;
  isMutating: boolean;
  // API実行
  executeGetPointBalance: () => Promise<
    EarthPointBalanceResponse['data'] | undefined
  >;
};

/**
 * ポイント残高表示API（CSD連携）を実行するhook
 * @param cardNumber 会員番号
 * @param onUnavailableTimeError API実行が503エラーした際に実行する関数
 */
const usePointBalance = (
  cardNumber: string,
  onUnavailableTimeError?: VoidFunction
): UsePointBalanceReturns => {
  // 時間外エラーのみを保存する
  const [serviceTime, setServiceTime] = useState<
    | {
        available_hour?: string;
        unavailable_hour?: string;
      }
    | undefined
  >();
  const isPointBalanceActive = useRecoilValue(
    membershipPluginFeatureState
  ).earth?.includes(_.camelCase(EARTH_FEATURE_CODES.GET_POINT_BALANCE));

  // ポイント残高取得APIを叩く
  const [
    { value: pointBalanceResponseData, loading: isMutating },
    executeGetPointBalance
  ] = useAsyncFn(async () => {
    if (!cardNumber || !isPointBalanceActive) return;
    const res = await getEarthPointBalance(cardNumber);
    // 保存しているサービス時間を一度削除
    setServiceTime(undefined);

    if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
      // 正常系の場合
      return res.data;
    }
    // http statusが503の場合かつサービス時間外
    if (
      res.httpStatus === 503 &&
      res?.error_fields &&
      Object.keys(res.error_fields).length
    ) {
      setServiceTime({
        // サービス時間
        available_hour: res.error_fields?.available_hour ?? '',
        // サービス時間外
        unavailable_hour: res.error_fields?.unavailable_hour ?? ''
      });
      onUnavailableTimeError?.();
      return;
    }

    // その他エラー、http statusが503の場合も(ネガカード)
    dispatchToast({
      id: 'membership-member-api-error',
      toastText: res.errors.join('\n')
    });
  }, [cardNumber, isPointBalanceActive]);

  return {
    // ポイントや有効期限などの時間のオブジェクト
    pointBalanceResponseData,
    // サービス時間外の時間とサービス時間のオブジェクト
    serviceTime,
    isMutating,
    // API実行
    executeGetPointBalance
  };
};

export default usePointBalance;
