import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

type UseInfiniteScrollReturns<P> = {
  boxRef: RefObject<HTMLDivElement>;
  displayData: P | null;
};

/**
 * 無限スクロール
 * @param dataArray 無限スクロールの対象データ
 * @param totalCount 無限スクロールの対象の総数
 * @param updateData 無限スクロールを更新するメソッド
 * @param incrementEachScroll 無限スクロール度に追加する最大数
 * @param isDataInvalid 無限スクロールで取得したデータ異常はあるか検証
 * @param isLoading 無限スクロールを更新中
 * @return boxRef IntersectionObserverの観察対象のref
 * @return displayData 無限スクロールで管理されてるデータ
 * */
const useInfiniteScroll = <T extends Array<object>>(
  dataArray: T,
  totalCount: number,
  updateData: (limit?: number, offset?: number) => Promise<undefined | unknown>,
  incrementEachScroll = 50,
  isDataInvalid?: (dataArray: T, displayData: T) => boolean,
  isLoading?: boolean
): UseInfiniteScrollReturns<T> => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [displayData, setDisplayData] = useState<T | null>(null);
  const scrollFunc = useCallback<IntersectionObserverCallback>(
    (entries) => {
      if (
        entries[0].isIntersecting &&
        displayData &&
        totalCount > displayData?.length &&
        !isLoading
      ) {
        // NOTE: offset は0からなので、4つ目以降（4つ目含める）のレコード取得する時offsetは3
        updateData(incrementEachScroll, displayData?.length);
      }
    },
    [totalCount, displayData, updateData, incrementEachScroll, isLoading]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(scrollFunc);
    if (boxRef.current) observer.observe(boxRef.current);
    return () => {
      observer.disconnect();
    };
  }, [scrollFunc]);

  useEffect(() => {
    if (!dataArray?.length) {
      return;
    }
    setDisplayData((prevData) => {
      if (!prevData?.length) {
        return [...dataArray] as T;
      }
      // NOTE: Dataが変と感知したら、0から取り直し
      if (prevData && isDataInvalid && isDataInvalid(dataArray, prevData)) {
        updateData(prevData.length, 0);
        return null;
      }
      return [...prevData, ...dataArray] as T;
    });
  }, [dataArray, isDataInvalid, updateData]);

  return {
    boxRef,
    displayData
  };
};

export default useInfiniteScroll;
