import { Button, Heading, Stack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import LabeledValue from 'components/modules/labeledValue/LabeledValue';
import { CardLinkFormInputs } from 'features/membership/types/form';
import { FC, MouseEventHandler, ReactElement } from 'react';

type CuminCardLinkConfirmPageProps = {
  onClickRegisterButton: MouseEventHandler;
  onClickBackButton: MouseEventHandler;
  isMutating?: boolean;
  // pin_code は親コンポーネントで additionalLabeledValue に渡されるため、
  // 型は GingerCardLinkFormInputs を含まない
  formData: CardLinkFormInputs;
  additionalLabeledValue?: ReactElement;
};

/**
 * カード連携・再連携確認コンポーネント
 * Cumin / Ginger テンプレートで利用する共通コンポーネント
 */
const CuminCardLinkConfirmPage: FC<CuminCardLinkConfirmPageProps> = ({
  onClickRegisterButton,
  onClickBackButton,
  isMutating,
  formData,
  additionalLabeledValue
}) => {
  return (
    <MainLayout header>
      <Stack pt="3rem" px="1rem" h="100%" justifyContent="space-between">
        <Stack spacing="1.5rem">
          {/* タイトル */}
          <Heading fontSize="xl" fontWeight="700">
            入力内容を確認してください
          </Heading>

          {/* カード番号 */}
          <LabeledValue label="カード番号" value={formData.card_number} />

          {/* 生年月日 */}
          <LabeledValue
            label="生年月日"
            value={`${formData.birthday.year}年${formData.birthday.month}月${formData.birthday.date}日`}
          />

          {/* その他の項目 */}
          {additionalLabeledValue}
        </Stack>

        <Stack gap="1rem">
          <Button
            variant="primary-fullwidth-rounded"
            isLoading={isMutating}
            onClick={onClickRegisterButton}
          >
            登録する
          </Button>

          <Button
            variant="primary-outline-fullwidth-rounded"
            isLoading={isMutating}
            onClick={onClickBackButton}
          >
            修正する
          </Button>
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default CuminCardLinkConfirmPage;
