import SimpleTopPage from 'features/membership/components/simple/SimpleTopPage';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { VENUS_FEATURE_CODES } from 'features/membership/libs/const/plugins/venusFeatureCodes';
import {
  membershipPluginFeatureState,
  membershipPluginPageTemplateOptionState,
  SimplePageTemplateOptionKey
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import _ from 'lodash';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * トップ
 */
const MintTop: FC = () => {
  const templateOptions = useRecoilValue<
    Record<SimplePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.MINT));
  const venusFeatureCodes = useRecoilValue(membershipPluginFeatureState).venus;
  const isChangeCardNumberFeatureActive = venusFeatureCodes?.includes(
    _.camelCase(VENUS_FEATURE_CODES.CHANGE_CARD_NUMBER)
  );

  return (
    <SimpleTopPage
      templateAliasCode={TEMPLATE_ALIAS_CODE.MINT}
      templateOptions={templateOptions}
      isChangeCardNumberFeatureActive={Boolean(isChangeCardNumberFeatureActive)}
      cardNumberLabel="会員番号"
      cardNumberChangeLabel="会員番号再連携"
    />
  );
};

export default MintTop;
