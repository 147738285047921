import SimpleCompletePage from 'features/membership/components/simple/SimpleCompletePage';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  membershipPluginPageTemplateOptionState,
  SimplePageTemplateOptionKey
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * 会員登録完了
 */
const SimpleSignupComplete: FC = () => {
  const pageTemplateOptions = useRecoilValue<
    Record<SimplePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.SIMPLE));

  if (!pageTemplateOptions) {
    return <Navigate to="/" />;
  }
  return (
    <SimpleCompletePage
      templateOptions={pageTemplateOptions}
      isSignupCompletePage
    />
  );
};

export default SimpleSignupComplete;
