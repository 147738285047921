import { CuminIssueCardFormInput } from 'features/membership/types/form';
import { telRegExp } from 'libs/regularExpressions';
import { BaseSchema, number, object, string, StringSchema } from 'yup';
import { MEMBER_FORM_ERROR_MESSAGES } from './const/cuminMemberFormValidation';

/** 半角数字チェックの際のバリデーションメッセージ */
export const HALF_WIDTH_NUMBER_VALIDATION_MESSAGE =
  '半角数字で入力してください';

/** first name + last name + 全角/半角スペース < 24
 * そして、 first name と last nameどちらも必須
 * ルールは first name/ last name max 22, 両者合わせて max 23
 **/
const validateName = (nameVal: string, schema: StringSchema) => {
  if (!nameVal || nameVal === '') {
    return schema.max(22, `22${MEMBER_FORM_ERROR_MESSAGES.MAX_TEXT_MESSAGE}`);
  }
  if (nameVal.length <= 22) {
    return schema
      .required(MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE)
      .max(
        23 - nameVal.length,
        `姓、名合わせて、23${MEMBER_FORM_ERROR_MESSAGES.MAX_TEXT_MESSAGE}`
      );
  }
  return schema
    .required(MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE)
    .max(
      22,
      `姓、名合わせて、22${MEMBER_FORM_ERROR_MESSAGES.MAX_TEXT_MESSAGE}`
    );
};

/**
 * 会員機能のフォームで利用する共通バリデーションルール
 */
export const defaultValidationRules: Record<
  keyof Partial<CuminIssueCardFormInput>,
  BaseSchema
> = {
  // サーバーだとlastName + firstName 24文字まで
  full_name: object().shape(
    {
      lastName: string()
        .matches(/^\S+$/, {
          excludeEmptyString: true,
          message: MEMBER_FORM_ERROR_MESSAGES.SPACE_MESSAGE
        })
        .when('firstName', validateName),
      firstName: string()
        .matches(/^\S+$/, {
          excludeEmptyString: true,
          message: MEMBER_FORM_ERROR_MESSAGES.SPACE_MESSAGE
        })
        .when('lastName', validateName)
    },
    [['lastName', 'firstName']]
  ),
  // サーバーだとlastName + firstName 24文字まで
  // 全角カナと長音記号（ー）のみ入力できる正規表現を設定
  full_name_kana: object().shape(
    {
      lastName: string()
        .matches(/^[\u30A1-\u30F6\u30FC]+$/, {
          excludeEmptyString: true,
          message: MEMBER_FORM_ERROR_MESSAGES.FULL_KANA_MESSAGE
        })
        .when('firstName', validateName),
      firstName: string()
        .matches(/^[\u30A1-\u30F6\u30FC]+$/, {
          excludeEmptyString: true,
          message: MEMBER_FORM_ERROR_MESSAGES.FULL_KANA_MESSAGE
        })
        .when('lastName', validateName)
    },
    [['lastName', 'firstName']]
  ),
  birthday: object().shape(
    {
      year: string()
        .matches(/^\d{4}$/, {
          excludeEmptyString: true,
          message: MEMBER_FORM_ERROR_MESSAGES.YEAR_MESSAGE
        })
        .when(['month', 'date'], {
          is: (month: string, date: string) => Boolean(month) || Boolean(date),
          then: (schema) =>
            schema.required(
              `年を${MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE}`
            )
        }),
      month: string().when(['year', 'date'], {
        is: (year: string, date: string) => Boolean(year) || Boolean(date),
        then: (schema) =>
          schema.required(
            `月を${MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE}`
          )
      }),
      date: string().when(['year', 'month'], {
        is: (year: string, month: string) => Boolean(year) || Boolean(month),
        then: (schema) =>
          schema.required(
            `日を${MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE}`
          )
      })
    },
    [
      ['month', 'date'],
      ['year', 'date'],
      ['year', 'month']
    ]
  ),
  gender: string(),
  phone_number: string().matches(telRegExp, {
    excludeEmptyString: true,
    message: '0から始まる10桁または11桁半角数字で入力してください'
  }),
  postal_code: string().matches(/^\d{7}$/, {
    excludeEmptyString: true,
    message: 'ハイフンなしの半角数字7文字以内で入力してください'
  }),
  address_1: string().max(
    40,
    `40${MEMBER_FORM_ERROR_MESSAGES.MAX_TEXT_MESSAGE}`
  ),
  address_2: string().max(
    40,
    `40${MEMBER_FORM_ERROR_MESSAGES.MAX_TEXT_MESSAGE}`
  ),
  occupation: number()
    .nullable(true)
    .transform((_: string, val: string | number) =>
      val === Number(val) ? val : null
    ),
  household_size: number()
    .integer('整数を入力してください')
    .min(0, '半角数字0以上で入力してください')
    .max(99, '半角数字99以内で入力してください'),
  entry_store_id: number()
    .nullable(true)
    .transform((_: string, val: string | number) =>
      val === Number(val) ? val : null
    )
} as const satisfies Record<keyof Partial<CuminIssueCardFormInput>, BaseSchema>;

/**
 * 姓名の必須バリデーション
 */
const validateNoneSpaceRequiredName = object().shape(
  {
    lastName: string()
      .matches(/^\S+$/, {
        excludeEmptyString: true,
        message: MEMBER_FORM_ERROR_MESSAGES.SPACE_MESSAGE
      })
      .required(MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE)
      .when('firstName', validateName),
    firstName: string()
      .matches(/^\S+$/, {
        excludeEmptyString: true,
        message: MEMBER_FORM_ERROR_MESSAGES.SPACE_MESSAGE
      })
      .required(MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE)
      .when('lastName', validateName)
  },
  [['firstName', 'lastName']]
);

// 姓名（カナ）の必須＋全角カナバリデーション
const validateNoneSpaceRequiredKanaName = defaultValidationRules[
  'full_name_kana'
].concat(validateNoneSpaceRequiredName);

/**
 * フィールドの必須・任意を元に必須条件を追加するための関数
 */
export const createValidationRule = (
  visibleFields: string[],
  requiredFields: string[]
) => {
  return object(
    visibleFields.reduce((previousValue, fieldName) => {
      const newRule: Partial<
        Record<keyof CuminIssueCardFormInput, BaseSchema>
      > = {};
      if (fieldName === 'full_name' && requiredFields.includes('full_name')) {
        newRule[fieldName] = validateNoneSpaceRequiredName;
      } else if (
        fieldName === 'full_name_kana' &&
        requiredFields.includes('full_name_kana')
      ) {
        newRule[fieldName] = validateNoneSpaceRequiredKanaName;
      } else if (fieldName === 'birthday') {
        newRule[fieldName] = requiredFields.includes('birthday')
          ? defaultValidationRules[fieldName].concat(
              object({
                year: string().required(
                  MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE
                ),
                month: string().required(
                  MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE
                ),
                date: string().required(
                  MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE
                )
              })
            )
          : defaultValidationRules[fieldName];
      } else if (fieldName === 'household_size') {
        // 空欄のnumberどうしてもエラーが吐いてしまうので、任意の場合わざと空のエラー吐いてもらう
        newRule[fieldName] = requiredFields.includes('household_size')
          ? defaultValidationRules[
              fieldName as keyof CuminIssueCardFormInput
            ].typeError('半角数字を入力してください')
          : defaultValidationRules[fieldName as keyof CuminIssueCardFormInput]
              .nullable(true)
              .transform((_: string, val: string | number) =>
                val === Number(val) ? val : null
              );
      } else {
        newRule[fieldName as keyof CuminIssueCardFormInput] =
          requiredFields.includes(fieldName)
            ? defaultValidationRules[
                fieldName as keyof CuminIssueCardFormInput
              ].required(MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE)
            : defaultValidationRules[fieldName as keyof CuminIssueCardFormInput]
                .notRequired()
                .nullable(true);
      }

      return {
        ...previousValue,
        ...newRule
      };
    }, {})
  );
};
