import { Badge, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

type FormInputFieldProps = {
  label: string;
  warning?: string | null;
  error?: string;
  isRequired: boolean;
  children?: ReactNode;
};

const FormInputField: FC<FormInputFieldProps> = ({
  label,
  warning,
  error,
  isRequired,
  children
}) => {
  return (
    <FormControl>
      <FormLabel>
        {label}
        <Badge
          fontSize="xx-small"
          bg={isRequired ? 'danger' : 'dark.50'}
          color={isRequired ? 'white' : 'dark.400'}
          ml="0.5rem"
          mt="-0.125rem"
          fontWeight=" 600"
        >
          {isRequired ? '必須' : '任意'}
        </Badge>
      </FormLabel>
      {children}
      {warning && (
        <Text
          my="0.5rem"
          fontSize="md"
          wordBreak="break-all"
          whiteSpace="pre-wrap"
        >
          {warning}
        </Text>
      )}
      {error && <Text color="#EA5B5E">{error}</Text>}
    </FormControl>
  );
};

export default FormInputField;
