import { FEATURES, isFeatureCodeActive } from 'libs/features';
import { tenantConfigurationAtom } from 'libs/recoil/atom';
import { selector } from 'recoil';

/**
 * 有効に設定されてる機能識別子一覧
 *
 * サブ機能がない場合: 機能識別子
 * サブ機能がある場合: 機能識別子_サブ機能識別子
 * ※ 現状サブ機能を1つのフッターアイテムとして扱うが変わる可能性はあるので今後見直しは必須
 */
export const activeFeatureCodesState = selector({
  key: 'activeFeatureCodes',
  get: ({ get }) => {
    const { feature_objects } = get(tenantConfigurationAtom);
    const featureCodes: string[] = feature_objects
      .map(
        (obj) =>
          obj.sub_features.length === 0
            ? obj.code // サブ機能がない場合: 機能識別子
            : obj.sub_features.map((sub) => `${obj.code}_${sub.code}`) // サブ機能がある場合: 機能識別子_サブ機能識別子
      )
      .flat();
    return featureCodes.filter(isFeatureCodeActive);
  }
});

/**
 * 有効に設定されてる機能一覧
 */
export const activeFeaturesState = selector({
  key: 'activeFeatures',
  get: ({ get }) => get(activeFeatureCodesState).map((code) => FEATURES[code])
});
