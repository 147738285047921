import {
  CouponFilledIcon,
  CouponIcon,
  MembershipFilledIcon,
  MembershipIcon,
  MenuFilledIcon,
  MenuIcon,
  MystoreFilledIcon,
  MystoreIcon,
  NotificationFilledIcon,
  NotificationIcon,
  ReservationFilledIcon,
  ReservationIcon,
  StampFilledIcon,
  StampIcon
} from 'assets/icons/FooterIcons';

/**
 * 機能一覧
 *
 * キーが識別子
 * それぞれが1つのフッターアイテムになる
 */
export const FEATURES = {
  reserve: {
    label: '予約注文',
    icon: <ReservationIcon boxSize={8} />,
    activeIcon: <ReservationFilledIcon boxSize={8} />,
    path: '/reservations'
  },
  store: {
    label: 'マイ店舗',
    icon: <MystoreIcon boxSize={8} />,
    activeIcon: <MystoreFilledIcon boxSize={8} />,
    path: '/my-store'
  },
  notice: {
    label: 'お知らせ',
    icon: <NotificationIcon boxSize={8} />,
    activeIcon: <NotificationFilledIcon boxSize={8} />,
    path: '/notifications'
  },
  // シンプルクーポンもポイントクーポンもタブのアイコンと文言は同じ
  // 2023年8月末時点では、ひとつのテナントがシンプルクーポンとポイントクーポンを同時にミニアプリ上で提供することはない
  digiclueplus_simple_coupons: {
    label: 'クーポン',
    icon: <CouponIcon boxSize={8} />,
    activeIcon: <CouponFilledIcon boxSize={8} />,
    path: '/coupon'
  },
  digiclueplus_point_coupons: {
    label: 'クーポン',
    icon: <CouponIcon boxSize={8} />,
    activeIcon: <CouponFilledIcon boxSize={8} />,
    path: '/point-coupon'
  },
  digiclueplus_stamps: {
    label: 'キャンペーン',
    icon: <StampIcon boxSize={8} />,
    activeIcon: <StampFilledIcon boxSize={8} />,
    path: '/stamps'
  },
  membership: {
    label: '会員証',
    icon: <MembershipIcon boxSize={8} />,
    activeIcon: <MembershipFilledIcon boxSize={8} />,
    path: '/membership'
  },
  // メニューリンクを表示
  link: {
    label: 'メニュー',
    icon: <MenuIcon boxSize={8} />,
    activeIcon: <MenuFilledIcon boxSize={8} />,
    path: '/menu'
  }
} as const;

// 機能一覧の識別子の型
export type FeatureCode = keyof typeof FEATURES;

// 利用可能な機能一覧の識別子かどうが判定
export const isFeatureCodeActive = (code: string): code is FeatureCode =>
  Object.keys(FEATURES).includes(code);
