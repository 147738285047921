import {
  Button,
  Heading,
  HStack,
  Image,
  Input,
  Select,
  Stack,
  Text
} from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import FormInputField from 'components/modules/FormInputField';
import TermOfService from 'components/modules/TermOfService';
import BarcodeScanBox from 'features/membership/components/BarcodeScanBox';
import {
  BIRTHDAY_DATE_OPTIONS,
  BIRTHDAY_MONTH_OPTIONS
} from 'features/membership/libs/const/cuminCardIssueOption';
import { CuminPagePageTemplateOptionKey } from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { CardLinkFormInputs } from 'features/membership/types/form';
import { BaseSyntheticEvent, FC, ReactElement, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

type CuminCardLinkFormPageProps = {
  isMutating: boolean;
  methods: UseFormReturn<CardLinkFormInputs>;
  onFormSubmit: (event?: BaseSyntheticEvent) => Promise<void>;
  templateOptions?: Record<CuminPagePageTemplateOptionKey, PageTemplateOption>;
  isTermActive: boolean;
  additionalField?: ReactElement;
};

/**
 * カード連携フォームコンポーネント
 * cuminテンプレートで利用する共通コンポーネント
 */
const CuminCardLinkFormPage: FC<CuminCardLinkFormPageProps> = ({
  isMutating,
  methods,
  onFormSubmit,
  templateOptions,
  isTermActive,
  additionalField
}) => {
  // 規約文言
  const termOfServiceContent =
    templateOptions?.signupFormTermsOfServiceContentOption?.content;
  // 利用規約テンプレートオプションが存在する かつ 規約表示
  const showTerm = !!termOfServiceContent && isTermActive;
  // 規約チェックボックスの状態
  const [isChecked, setIsChecked] = useState(!showTerm);
  const { formState, register, setValue } = methods;

  return (
    <MainLayout header>
      <Stack
        as="form"
        onSubmit={onFormSubmit}
        pt="3rem"
        px="1rem"
        justifyContent="space-between"
        minH="100%"
        gap="1rem"
      >
        <Stack gap="1rem">
          {/* タイトル */}
          {!!templateOptions?.cardFormTitleContentOption?.content && (
            <Heading
              fontSize="xl"
              fontWeight="700"
              wordBreak="break-all"
              whiteSpace="pre-wrap"
            >
              {templateOptions.cardFormTitleContentOption.content}
            </Heading>
          )}

          {/* カード画像 */}
          {templateOptions?.cardFormTopImageOption?.image_url && (
            <Image
              src={templateOptions?.cardFormTopImageOption?.image_url}
              width="100%"
              height="12rem"
              objectFit="contain"
              alt="カード画像"
              borderRadius="10px"
            />
          )}

          {/** グローバルエラー表示欄 */}
          {!!formState.errors?.root && (
            <Text color="danger" wordBreak="break-all" whiteSpace="pre-wrap">
              {formState.errors.root?.message}
            </Text>
          )}

          {/* カード番号入力欄 */}
          {/* TODO: あおきPhase1~3では対象外だが、いずれカード番号カメラで読み取り機能を実装 */}
          <FormInputField
            label="カード番号"
            warning={
              templateOptions?.cardFormCardNumberNoticeContentOption?.content ??
              ''
            }
            error={formState.errors.card_number?.message}
            isRequired={true}
          >
            <Input
              type="tel"
              placeholder={
                templateOptions?.cardFormCardNumberPlaceholderContentOption
                  ?.content ?? '0000000000000000'
              }
              {...register('card_number')}
            />
          </FormInputField>

          {/* カメラ読み取り */}
          {templateOptions?.cardFormCameraReadingOption && (
            <BarcodeScanBox
              onDetected={(code) =>
                setValue('card_number', code, { shouldValidate: true })
              }
            />
          )}

          {/* 生年月日入力欄 */}
          <FormInputField
            label="生年月日"
            warning={
              templateOptions?.cardFormBirthdayNoticeContentOption?.content ??
              ''
            }
            error={
              formState.errors.birthday?.year?.message ??
              formState.errors.birthday?.month?.message ??
              formState.errors.birthday?.date?.message
            }
            isRequired={true}
          >
            <HStack>
              <Input
                type="tel"
                placeholder="年"
                {...register('birthday.year')}
              />
              <Select placeholder="月" {...register('birthday.month')}>
                {BIRTHDAY_MONTH_OPTIONS.map(({ key, label, value }) => (
                  <option key={key} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <Select placeholder="日" {...register('birthday.date')}>
                {BIRTHDAY_DATE_OPTIONS.map(({ key, label, value }) => (
                  <option key={key} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </HStack>
          </FormInputField>
          {/* その他入力欄 */}
          {additionalField}
        </Stack>

        <Stack gap="1rem" alignItems="center">
          {/* 規約チェックボックス */}
          {showTerm && (
            <TermOfService
              variant="secondary"
              content={termOfServiceContent}
              onCheckboxChange={(e) => setIsChecked(e.target.checked)}
            />
          )}

          <Button
            type="submit"
            variant="primary-fullwidth-rounded"
            isDisabled={!formState.isValid || !isChecked} // チェックが入っていないまたはフォームがinvalidの場合、disabledにする
            isLoading={isMutating}
          >
            確認画面へ
          </Button>
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default CuminCardLinkFormPage;
