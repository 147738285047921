import SimpleSignUpTopPage from 'features/membership/components/simple/SimpleSignupTopPage';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  membershipPluginPageTemplateOptionState,
  SimplePageTemplateOptionKey
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * 会員登録トップ
 */
const MintSignupTop: FC = () => {
  const templateOptions = useRecoilValue<
    Record<SimplePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.MINT));

  return (
    <SimpleSignUpTopPage
      templateAliasCode={TEMPLATE_ALIAS_CODE.MINT}
      templateOptions={templateOptions}
      cardNumberLabel="会員番号"
    />
  );
};

export default MintSignupTop;
