import { Link, List, ListItem } from '@chakra-ui/react';
import { LinkIcon } from 'assets/icons/miscs';
import {
  ExternalLinksType,
  ExternalLinkType
} from 'features/my-store/types/StoreType';
import { FC } from 'react';

type StoreExternalLinkListType = {
  externalLinks: ExternalLinksType;
};

const StoreExternalLinkList: FC<StoreExternalLinkListType> = ({
  externalLinks
}) => {
  if (!externalLinks) return null;
  return (
    <List mb="1.2rem" color="primary">
      {externalLinks.map((link: ExternalLinkType, index: number) => (
        <ListItem key={index} fontSize="md" fontWeight="400" mb="0.5rem">
          <LinkIcon />
          <Link href={link.url} isExternal ms="0.5rem">
            {link.label}
          </Link>
        </ListItem>
      ))}
    </List>
  );
};

export default StoreExternalLinkList;
