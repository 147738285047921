import {
  Button,
  Center,
  Container,
  Image,
  Stack,
  Text
} from '@chakra-ui/react';
import { CardCheckedIcon } from 'assets/icons/miscs';
import { SimplePageTemplateOptionKey } from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';

import MainLayout from 'components/layouts/MainLayout';
import LinkText from 'components/modules/LinkText';
import { ComponentProps, FC } from 'react';
import { Link } from 'react-router-dom';

type CompletePageProps = {
  isSignupCompletePage?: boolean;
  templateOptions?: Record<
    Extract<
      SimplePageTemplateOptionKey,
      | 'signupLinkCompleteTitleContentOption'
      | 'signupLinkCompleteExplanationContentOption'
      | 'signupLinkCompleteImageOption'
      | 'relinkCompleteTitleContentOption'
      | 'relinkCompleteExplanationContentOption'
      | 'relinkCompleteImageOption'
    >,
    PageTemplateOption | undefined
  >;
} & Pick<ComponentProps<typeof MainLayout>, 'pageTitle'>;

/**
 * 会員連携完了ページ
 * Simple, Pepper, Mintのテンプレートで利用する共通コンポーネント
 */
const SimpleCompletePage: FC<CompletePageProps> = ({
  isSignupCompletePage,
  templateOptions,
  pageTitle
}) => {
  const titleTemplateOption = isSignupCompletePage
    ? templateOptions?.signupLinkCompleteTitleContentOption
    : templateOptions?.relinkCompleteTitleContentOption;
  const title = titleTemplateOption?.content || '登録が完了しました！';
  const explanation = isSignupCompletePage
    ? templateOptions?.signupLinkCompleteExplanationContentOption?.content
    : templateOptions?.relinkCompleteExplanationContentOption?.content;
  const imageUrl = isSignupCompletePage
    ? templateOptions?.signupLinkCompleteImageOption?.image_url
    : templateOptions?.relinkCompleteImageOption?.image_url;

  return (
    <MainLayout header footer pageTitle={pageTitle}>
      <Container pt="3rem">
        <Stack spacing="2.5rem">
          <Stack spacing="1.25rem">
            {/* タイトル */}
            <Text fontSize="3xl" fontWeight="bold" align="center">
              {title}
            </Text>
            {/* 画像 */}
            {imageUrl ? (
              <Image
                alignSelf="center"
                src={imageUrl}
                width="100%"
                objectFit="contain"
                alt="完了画像"
                borderRadius="10px"
              />
            ) : (
              <Center>
                <CardCheckedIcon boxSize="8rem" color="primary" />
              </Center>
            )}
            {/* 説明文 */}
            {explanation && (
              <LinkText
                variant="primary"
                content={explanation}
                fontSize="lg"
                fontWeight="400"
              />
            )}
          </Stack>
          {/* 「ホームに戻る」ボタン */}
          <Button as={Link} variant="primary-fullwidth-rounded" to="/">
            ホームに戻る
          </Button>
        </Stack>
      </Container>
    </MainLayout>
  );
};

export default SimpleCompletePage;
