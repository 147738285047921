import { getCampaignGroupsByCampaignId } from 'features/reservations/api';
import { SimpleCampaignGroupType } from 'features/reservations/types/CampaignType';
import { useEffect, useState } from 'react';

export const useCampaignGroup = (campaignId: number) => {
  const [parentCampaignName, setParentCampaignName] = useState<string>('');
  const [campaignGroups, setCampaignGroups] = useState<
    SimpleCampaignGroupType[]
  >([]);
  const [isDone, setIsDone] = useState<boolean>(false);

  const filterCampaignGroupsByGroupId = (groupId: number) =>
    campaignGroups.filter((group) => group.id === groupId);

  const getHeroImageSrc = (groupId: number) =>
    filterCampaignGroupsByGroupId(groupId)[0]?.image_file_urls[0]
      ?.image_file_url ?? '';

  useEffect(() => {
    getCampaignGroupsByCampaignId(campaignId).then((res) => {
      setParentCampaignName(res.campaign_name);
      setCampaignGroups(res.groups);
      setIsDone(true);
    });
  }, [campaignId]);

  return {
    campaignGroups,
    parentCampaignName,
    isDone,
    filterCampaignGroupsByGroupId,
    getHeroImageSrc
  };
};
