import CuminCompletePage from 'features/membership/components/cumin/CuminCompletePage';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  GingerPagePageTemplateOptionKey,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FEATURES } from 'libs/features';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * VD+CSD カード連携/完了
 */
const GingerCardLinkComplete: FC = () => {
  const templateOptions = useRecoilValue<
    Record<GingerPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.GINGER));

  const title =
    templateOptions?.signupLinkCompleteTitleContentOption?.content ?? '';
  const explanation =
    templateOptions?.signupLinkCompleteExplanationContentOption?.content ?? '';
  const imageUrl =
    templateOptions?.signupLinkCompleteImageOption?.image_url ?? '';

  return (
    <CuminCompletePage
      title={title}
      explanation={explanation}
      imageUrl={imageUrl}
      topPagePath={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.GINGER}`}
    />
  );
};
export default GingerCardLinkComplete;
