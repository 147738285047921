import { getAllStores, getStoreById } from 'features/my-store/api';
import {
  StoresInPrefectureType,
  StoreType
} from 'features/my-store/types/StoreType';
import { PREFECTURES } from 'libs/constants';
import { activeFeatureCodesState } from 'libs/recoil/selector';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

export const useStore = (targetStoreId: number | undefined = undefined) => {
  const [isDone, setIsDone] = useState<boolean>(false);
  const [stores, setStores] = useState<StoresInPrefectureType[]>([]);
  const [targetStore, setTargetStore] = useState<StoreType | null>(null);
  const activeFeatureCodes = useRecoilValue(activeFeatureCodesState);
  const isFeatureActive = activeFeatureCodes.includes('store');

  useEffect(() => {
    if (!isFeatureActive) return;
    if (targetStoreId) {
      getStoreById(targetStoreId).then((res) => {
        if (typeof res === 'object') {
          setTargetStore(res);
        }
      });
    } else {
      getAllStores().then((res) => {
        const allStores = PREFECTURES.map(({ value }) => ({
          prefecture: value,
          stores: res.filter((store) => store.prefecture === value)
        })).filter((prefecture) => prefecture.stores.length > 0);
        setStores(allStores);
        setIsDone(true);
      });
    }
  }, [isFeatureActive, targetStoreId]);

  return { isFeatureActive, targetStore, stores, isDone };
};
