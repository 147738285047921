import dispatchToast from 'components/modules/Toast';
import { getNotifications, readNotifications } from 'features/notification/api';
import { NotificationListItemType } from 'features/notification/types/NotificationType';
import { API_RESPONSE_STATUS, TOAST_ERROR_MESSAGE } from 'libs/constants';
import { unreadNotificationAtom } from 'libs/recoil/atom';
import { activeFeatureCodesState } from 'libs/recoil/selector';
import { useCallback, useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export const useNotificationList = (
  { firstExecutionWithoutCall }: { firstExecutionWithoutCall: boolean } = {
    firstExecutionWithoutCall: true
  }
) => {
  const setUnreadNotification = useSetRecoilState(unreadNotificationAtom);
  const [currentStoreId, setCurrentStoreId] = useState<number | null>(null);
  const [allNotificationList, setAllNotificationList] = useState<
    NotificationListItemType[]
  >([]);
  const [displayedNotificationList, setDisplayedNotificationList] = useState<
    NotificationListItemType[]
  >([]);
  const [unreadIds, setUnreadIds] = useState<number[]>([]);
  const activeFeatureCodes = useRecoilValue(activeFeatureCodesState);

  // 一覧取得
  const [{ loading: isNotificationListMutating }, getNotificationList] =
    useAsyncFn(async () => {
      if (!activeFeatureCodes.includes('notice')) return;
      const res = await getNotifications();

      if (res?.status === API_RESPONSE_STATUS.SUCCEEDED) {
        // 全お知らせ
        setAllNotificationList(res.data.notifications);
        const currUnreadIds = res.data.notifications
          .filter((item) => !item.read)
          .map((item) => item.id);
        // 未読お知らせのグローバル状態を更新
        setUnreadNotification({ hasUnread: !!currUnreadIds.length });
        return;
      }

      dispatchToast({
        id: 'membership-member-api-error',
        toastText: TOAST_ERROR_MESSAGE
      });
    }, [activeFeatureCodes]);

  // 既読する
  const [{ loading: isReadNotificationMutating }, readNotification] =
    useAsyncFn(
      async (ids: number[]) => {
        if (!ids || ids.length === 0 || !activeFeatureCodes.includes('notice'))
          return;

        const res = await readNotifications(ids);

        if (res?.status === API_RESPONSE_STATUS.SUCCEEDED) {
          // 未読お知らせのグローバル状態を更新するため、再取得する
          getNotificationList();
          return;
        }

        dispatchToast({
          id: 'membership-member-api-error',
          toastText: TOAST_ERROR_MESSAGE
        });
      },
      [activeFeatureCodes]
    );

  // 店舗で絞り込んだお知らせ
  const filterByStore = useCallback(
    (currentStoreId: number) =>
      allNotificationList.filter(
        (item) => !!item.stores?.some((store) => store.id === currentStoreId)
      ),
    [allNotificationList]
  );

  useEffect(() => {
    if (firstExecutionWithoutCall) getNotificationList();
  }, [getNotificationList, firstExecutionWithoutCall]);

  useEffect(() => {
    // 店舗IDが指定されているかどうかによって、表示用のお知らせリストを更新
    let list: NotificationListItemType[];
    if (currentStoreId) {
      list = filterByStore(currentStoreId);
    } else {
      list = allNotificationList;
    }
    setDisplayedNotificationList(list);

    // 一覧に表示されている未読お知らせIDs
    const unreadIds = list.filter((item) => !item.read).map((item) => item.id);
    setUnreadIds(unreadIds);
  }, [allNotificationList, currentStoreId, filterByStore]);

  return {
    displayedNotificationList,
    unreadIds,
    setCurrentStoreId,
    readNotification,
    isReadNotificationMutating,
    getNotificationList,
    isNotificationListMutating
  };
};
