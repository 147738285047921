import { CartType } from 'features/reservations/types/CartType';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist(); // 追加

export const reservationCartsAtom = atom<CartType[]>({
  key: 'reservation-carts',
  default: [],
  effects_UNSTABLE: [persistAtom]
});
