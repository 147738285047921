import dispatchToast from 'components/modules/Toast';
import { CARD_NUMBER_PARAM_KEY } from 'features/membership/libs/const/paramInsertion';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * パラメータから自動入力する際、ミニアプリ上既にカード番号登録してある場合
 * 現在登録の番号と一致しなかったらエラーを吐く
 */
const useDispatchCardNumberError = (currentCardNumber?: string) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (!query.has(CARD_NUMBER_PARAM_KEY) || !currentCardNumber) {
      return;
    }
    if (currentCardNumber !== query.get(CARD_NUMBER_PARAM_KEY)) {
      dispatchToast({
        id: 'membership-member-api-error',
        toastText: '別のカード番号が登録されています'
      });
    }
    navigate(pathname, {
      replace: true
    });
  }, [search, pathname, navigate, currentCardNumber]);
};
export default useDispatchCardNumberError;
