import {
  Heading,
  HStack,
  ListItem,
  Skeleton,
  Stack,
  UnorderedList
} from '@chakra-ui/react';
import React, { FC } from 'react';

type ReservedSummarySectionType = {
  total?: number;
  isLoaded?: boolean;
  caveats: string[];
};

/** 予約合計金額と支払い注意事項を表示するコンポーネント */
const ReservedSummarySection: FC<ReservedSummarySectionType> = ({
  total = 0,
  isLoaded = false,
  caveats
}) => {
  return (
    <Stack>
      <HStack justifyContent="space-between">
        <Heading fontSize="md" display="inline" mb="0">
          合計
        </Heading>
        <Skeleton isLoaded={isLoaded}>
          <Heading fontSize="2xl" display="inline" mb="0">
            {total.toLocaleString()}
          </Heading>
          <Heading fontSize="sm" display="inline" ms="0.25rem" mb="0">
            円 税込
          </Heading>
        </Skeleton>
      </HStack>

      <UnorderedList>
        {caveats.map((caveat, index) => (
          <ListItem key={`caveat-${index}`} fontSize="sm">
            {caveat}
          </ListItem>
        ))}
      </UnorderedList>
    </Stack>
  );
};

export default React.memo(ReservedSummarySection);
