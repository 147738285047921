import { ReservationStatusAliasKeys } from './reservationStatus';

const PAYMENT_AT_STORE = '支払いは商品受け取り時に現地決済となります。';
const ERROR_FOR_TAX =
  '小計ごとに税率を計算しているため見た目の合計額と実際のお支払い額に誤差が生じる場合がございます。';

/**
 * 予約の取り消しについての一文
 * @param {number | null} days 予約取り消しまでの猶予日数
 * @returns {string} 商品受け取り前に限り、受取時間帯の開始日時 or 受取時間帯の開始日時の${days}日前までは本サービス上で予約の取り消しが可能です。
 * or 本サービス上で予約の取り消しはできかねますので、あらかじめご了承ください。
 */
export const getCaveatForCancelReservation = (days: number | null) => {
  if (days === null) {
    return '本サービス上で予約の取り消しはできかねますので、あらかじめご了承ください。';
  } else if (days === 0) {
    return '商品受け取り前に限り、受取時間帯の開始日時までは本サービス上で予約の取り消しが可能です。';
  } else {
    return `商品受け取り前に限り、受取時間帯の開始日時の${days}日前までは本サービス上で予約の取り消しが可能です。`;
  }
};

export const getReservedSummaryCaveatsByStatus = (
  status: ReservationStatusAliasKeys | 'cart',
  isPrepaid: boolean
) => {
  return (status === 'cart' || status === 'reserved') && !isPrepaid
    ? [PAYMENT_AT_STORE, ERROR_FOR_TAX]
    : [ERROR_FOR_TAX];
};
