import {
  Accordion,
  Badge,
  Button,
  Checkbox,
  Heading,
  Link,
  Text
} from './components';

export const theme = {
  styles: {
    global: {
      body: {
        color: '#333333',
        bg: '#ffffff',
        fontWeight: 300
      }
    }
  },
  components: { Button, Text, Link, Checkbox, Heading, Badge, Accordion },
  fonts: {
    heading: 'Inter, Noto Sans JP, system-ui, sans-serif',
    body: 'Inter, Noto Sans JP, system-ui, sans-serif'
  },
  fontSizes: {
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
    '2xl': '1.25rem',
    '3xl': '1.5rem',
    '4xl': '1.75rem',
    '5xl': '2rem',
    '6xl': '2.25rem'
  },
  colors: {
    primary: '#009de0',
    primary30: '#009de030',
    primary90: '#009de090',
    message: '#FDF7E0',
    secondary: '#ffb504',
    dark: {
      50: '#ededed',
      100: '#dfdfdf',
      200: '#acacac',
      300: '#8b8b8b',
      400: '#646464',
      500: '#333333'
    },
    danger: '#EA5B5E',
    warning: '#EFAB00',
    white: '#ffffff'
  }
};
