import DigiclueplusPage from 'features/digiclueplus/components/DigiclueplusPage';
import { FC } from 'react';

const StampTop: FC = () => {
  return (
    <DigiclueplusPage
      serviceCode="stamps"
      headding="キャンペーン"
      description="キャンペーンのエントリーはこちらから"
      buttonLabel="キャンペーン一覧へ"
    />
  );
};

export default StampTop;
