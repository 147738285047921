import {
  Button,
  Center,
  Container,
  Divider,
  Image,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import { SimplePageTemplateOptionKey } from 'features/membership/libs/recoil/selector';
import {
  PageTemplateOption,
  TemplateAliasCode
} from 'features/membership/types';
import { openWindow } from 'libs/line';
import { FC } from 'react';
import { Link } from 'react-router-dom';

type SignUpTopPageProps = TemplateAliasCode & {
  templateOptions?: Record<
    Extract<
      SimplePageTemplateOptionKey,
      | 'signupTopImageOption'
      | 'signupTopNoticeContentOption'
      | 'signupTopLinkOption'
    >,
    PageTemplateOption | undefined
  >;
  // ツルハで「カード番号」のラベルを変更したいとの要望があったため、一時対応としてラベルをカスタマイズできるようにしています
  cardNumberLabel?: string;
};

/**
 * 会員登録トップページ
 * Simple, Pepper, Mintの両方のテンプレートで利用する共通コンポーネント
 */
const SimpleSignUpTopPage: FC<SignUpTopPageProps> = ({
  templateAliasCode,
  templateOptions,
  cardNumberLabel = 'カード番号'
}) => {
  // 注意文言オプション
  const signupTopNoticeContentOption =
    templateOptions?.signupTopNoticeContentOption;
  // 注意文言
  const noticeContent = signupTopNoticeContentOption?.content;
  // リンクオプション
  const signupTopLinkOption = templateOptions?.signupTopLinkOption;
  // リンクテキスト
  const linkLabel = signupTopLinkOption?.link_label;
  // リンクURL
  const linkUrl = signupTopLinkOption?.link_url;
  return (
    <MainLayout header footer>
      <Container pt="3rem" height="100%">
        <Center height="100%">
          <Stack spacing="1rem" width="100%">
            {/* トップ画像 */}
            {templateOptions?.signupTopImageOption?.image_url && (
              <Image
                px="1rem"
                width="100%"
                height="13rem"
                objectFit="contain"
                src={templateOptions.signupTopImageOption.image_url}
                alt="トップ"
              />
            )}
            <VStack gap="1.5rem">
              {/* 「カード番号を登録する」ボタン */}
              <Button
                as={Link}
                variant="primary-fullwidth-rounded"
                to={`/membership/${templateAliasCode}/signup/new`}
              >
                {cardNumberLabel}を登録する
              </Button>
              {/* 説明もしくはリンクがある際は区切り線を表示する */}
              {(noticeContent || (linkLabel && linkUrl)) && (
                <>
                  {/* 区切り線 */}
                  <Divider borderColor="dark.100" opacity={1} width="50%" />
                  <VStack gap="0.75rem">
                    {/* 説明 */}
                    {noticeContent && (
                      <Text
                        fontWeight="400"
                        fontSize="sm"
                        whiteSpace="pre-line"
                      >
                        {noticeContent}
                      </Text>
                    )}
                    {/* リンク */}
                    {linkLabel && linkUrl && (
                      <Button
                        type="button"
                        variant="primary-link"
                        size="xl"
                        fontWeight="700"
                        padding="0"
                        onClick={
                          linkUrl ? () => openWindow(linkUrl) : undefined
                        }
                      >
                        {linkLabel}
                      </Button>
                    )}
                  </VStack>
                </>
              )}
            </VStack>
          </Stack>
        </Center>
      </Container>
    </MainLayout>
  );
};

export default SimpleSignUpTopPage;
