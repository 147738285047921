import {
  PLUGIN_ALIAS_CODE,
  TEMPLATE_ALIAS_CODE
} from 'features/membership/libs/const/aliasCode';
import { MEMBER_FORM_PREFIX_POSITION } from 'features/membership/libs/const/cuminMemberFormValidation';
import { PageTemplateOption } from 'features/membership/types';
import { allStoresAtom, membershipSettingAtom } from 'libs/recoil/atom';
import _ from 'lodash';
import { selector, selectorFamily } from 'recoil';
export type SimplePageTemplateOptionKey =
  | 'signupTopImageOption'
  | 'signupTopNoticeContentOption'
  | 'signupTopLinkOption'
  | 'signupFormTermsOfServiceContentOption'
  | 'formTopImageOption'
  | 'formCardNumberLabelContentOption'
  | 'formCardNumberPlaceholderContentOption'
  | 'formCameraReadingOption'
  | 'formCameraReadingLabelContentOption'
  | 'formNoticeContentOption'
  | 'signupFormLinkOption'
  | 'editFormLinkOption'
  | 'signupConfirmNoticeContentOption'
  | 'topNoticeContentOption'
  | 'topLinkOption'
  | 'confirmNoticeContentOption'
  | 'signupLinkCompleteTitleContentOption'
  | 'signupLinkCompleteExplanationContentOption'
  | 'signupLinkCompleteImageOption'
  | 'relinkCompleteTitleContentOption'
  | 'relinkCompleteExplanationContentOption'
  | 'relinkCompleteImageOption';

export type CuminPagePageTemplateOptionKey =
  | 'signupTopImageOption'
  | 'signupTopTitleContentOption'
  | 'signupTopNoticeContentOption'
  | 'signupTopLinkButtonContentOption'
  | 'signupTopIssueButtonContentOption'
  | 'cardFormTitleContentOption'
  | 'cardFormTopImageOption'
  | 'cardFormCardNumberPlaceholderContentOption'
  | 'cardFormCardNumberNoticeContentOption'
  | 'cardFormCameraReadingOption'
  | 'cardFormBirthdayNoticeContentOption'
  | 'signupFormTermsOfServiceContentOption'
  | 'signupLinkCompleteTitleContentOption'
  | 'signupLinkCompleteExplanationContentOption'
  | 'signupLinkCompleteImageOption'
  | 'memberFormFullNameRequiredOption'
  | 'memberFormFullNameNoticeContentOption'
  | 'memberFormFullNameKanaRequiredOption'
  | 'memberFormFullNameKanaNoticeContentOption'
  | 'memberFormBirthdayRequiredOption'
  | 'memberFormBirthdayNoticeContentOption'
  | 'memberFormGenderRequiredOption'
  | 'memberFormGenderNoticeContentOption'
  | 'memberFormPhoneNumberRequiredOption'
  | 'memberFormPhoneNumberNoticeContentOption'
  | 'memberFormPostalCodeRequiredOption'
  | 'memberFormPostalCodeNoticeContentOption'
  | 'memberFormAddress1RequiredOption'
  | 'memberFormAddress1NoticeContentOption'
  | 'memberFormAddress2RequiredOption'
  | 'memberFormAddress2NoticeContentOption'
  | 'memberFormOccupationRequiredOption'
  | 'memberFormOccupationNoticeContentOption'
  | 'memberFormOccupationObjectListOption'
  | 'memberFormHouseholdSizeRequiredOption'
  | 'memberFormHouseholdSizeNoticeContentOption'
  | 'memberFormEntryStoreIdRequiredOption'
  | 'memberFormEntryStoreIdNoticeContentOption'
  | 'issueTermsOfServiceContentOption'
  | 'topImageOption'
  | 'topPointExpiryDateVisibleOption'
  | 'signupMemberCompleteTitleContentOption'
  | 'signupMemberCompleteExplanationContentOption'
  | 'signupMemberCompleteImageOption'
  | 'renewalReissueTitleContentOption'
  | 'renewalReissueExplanationContentOption'
  | 'renewalReissueButtonContentOption'
  | 'renewalReissueNoticeContentOption'
  | 'renewalRelinkTitleContentOption'
  | 'renewalRelinkExplanationContentOption'
  | 'renewalRelinkButtonContentOption'
  | 'renewalRelinkNoticeContentOption'
  | 'reissueTitleContentOption'
  | 'reissueNoticeContentOption'
  | 'reissueTermsOfServiceContentOption'
  | 'reissueCompleteTitleContentOption'
  | 'reissueCompleteExplanationContentOption'
  | 'reissueCompleteImageOption'
  | 'relinkCompleteTitleContentOption'
  | 'relinkCompleteExplanationContentOption'
  | 'relinkCompleteImageOption';

export type GingerPagePageTemplateOptionKey =
  | CuminPagePageTemplateOptionKey
  | 'cardFormPinCodePlaceholderContentOption'
  | 'cardFormPinCodeNoticeContentOption'
  | 'topBarcodeTypeOption'
  | 'houseMoneyNameContentOption'
  | 'houseMoneyUnitContentOption'
  | 'pointNameContentOption'
  | 'pointUnitContentOption';

/** 会員連携画面テンプレート: シンプル のテナント設定オプション */
export const membershipSimpleSettingOptionsState = selector<{
  [key in SimplePageTemplateOptionKey]: PageTemplateOption | undefined;
}>({
  key: 'membershipSimpleSettingOptions',
  get: ({ get }) => {
    const {
      page_template: { alias_code, options }
    } = get(membershipSettingAtom);

    if (alias_code !== TEMPLATE_ALIAS_CODE.SIMPLE)
      return {
        signupTopImageOption: undefined,
        signupTopNoticeContentOption: undefined,
        signupTopLinkOption: undefined,
        signupFormTermsOfServiceContentOption: undefined,
        formTopImageOption: undefined,
        formCardNumberLabelContentOption: undefined,
        formCardNumberPlaceholderContentOption: undefined,
        formCameraReadingOption: undefined,
        formCameraReadingLabelContentOption: undefined,
        formNoticeContentOption: undefined,
        signupFormLinkOption: undefined,
        editFormLinkOption: undefined,
        signupConfirmNoticeContentOption: undefined,
        topNoticeContentOption: undefined,
        topLinkOption: undefined,
        confirmNoticeContentOption: undefined,
        signupLinkCompleteTitleContentOption: undefined,
        signupLinkCompleteExplanationContentOption: undefined,
        signupLinkCompleteImageOption: undefined,
        relinkCompleteTitleContentOption: undefined,
        relinkCompleteExplanationContentOption: undefined,
        relinkCompleteImageOption: undefined
      };

    return {
      signupTopImageOption: options.find(
        ({ code }) => code === 'signup_top_image'
      ),
      signupTopNoticeContentOption: options.find(
        ({ code }) => code === 'signup_top_notice_content'
      ),
      signupTopLinkOption: options.find(
        ({ code }) => code === 'signup_top_link'
      ),
      signupFormTermsOfServiceContentOption: options.find(
        ({ code }) => code === 'signup_form_terms_of_service_content'
      ),
      formTopImageOption: options.find(({ code }) => code === 'form_top_image'),
      formCardNumberLabelContentOption: options.find(
        ({ code }) => code === 'form_card_number_label_content'
      ),
      formCardNumberPlaceholderContentOption: options.find(
        ({ code }) => code === 'form_card_number_placeholder_content'
      ),
      formCameraReadingOption: options.find(
        ({ code }) => code === 'form_camera_reading'
      ),
      formCameraReadingLabelContentOption: options.find(
        ({ code }) => code === 'form_camera_reading_label_content'
      ),
      formNoticeContentOption: options.find(
        ({ code }) => code === 'form_notice_content'
      ),
      signupFormLinkOption: options.find(
        ({ code }) => code === 'signup_form_link'
      ),
      editFormLinkOption: options.find(({ code }) => code === 'edit_form_link'),
      signupConfirmNoticeContentOption: options.find(
        ({ code }) => code === 'signup_confirm_notice_content'
      ),
      topNoticeContentOption: options.find(
        ({ code }) => code === 'top_notice_content'
      ),
      topLinkOption: options.find(({ code }) => code === 'top_link'),
      confirmNoticeContentOption: options.find(
        ({ code }) => code === 'confirm_notice_content'
      ),
      signupLinkCompleteTitleContentOption: options.find(
        ({ code }) => code === 'signup_link_complete_title_content'
      ),
      signupLinkCompleteExplanationContentOption: options.find(
        ({ code }) => code === 'signup_link_complete_explanation_content'
      ),
      signupLinkCompleteImageOption: options.find(
        ({ code }) => code === 'signup_link_complete_image'
      ),
      relinkCompleteTitleContentOption: options.find(
        ({ code }) => code === 'relink_complete_title_content'
      ),
      relinkCompleteExplanationContentOption: options.find(
        ({ code }) => code === 'relink_complete_explanation_content'
      ),
      relinkCompleteImageOption: options.find(
        ({ code }) => code === 'relink_complete_image'
      )
    };
  }
});

/** 会員連携画面テンプレート: 全てのプラグイン対応 のテナント設定オプション **/
export const membershipPluginPageTemplateOptionState = selectorFamily<
  Record<string, PageTemplateOption> | undefined,
  (typeof TEMPLATE_ALIAS_CODE)[keyof typeof TEMPLATE_ALIAS_CODE]
>({
  key: 'membershipPluginPageTemplateOption',
  get:
    (templateAliasCode) =>
    ({ get }) => {
      const {
        page_template: { alias_code, options }
      } = get(membershipSettingAtom);
      if (alias_code !== templateAliasCode) {
        return undefined;
      }
      // template Option名を snake case から camel caseに
      return options.reduce((accumVal, currentVal) => {
        const valObj: Record<string, PageTemplateOption> = {};
        valObj[`${_.camelCase(currentVal.code)}Option`] = currentVal;
        return { ...accumVal, ...valObj };
      }, {});
    }
});

/** プラグイン利用可能機能 <プラグイン名> : [<feature名（camel case）>] **/
export const membershipPluginFeatureState = selector<
  Partial<
    Record<(typeof PLUGIN_ALIAS_CODE)[keyof typeof PLUGIN_ALIAS_CODE], string[]>
  >
>({
  key: 'membershipPluginFeature',
  get: ({ get }) => {
    const { plugins } = get(membershipSettingAtom);
    // 利用中のpluginのfeatureCodeをcamel caseに変更し、plugin名をkeyにする objectに変換
    return plugins.reduce((accumVal, currentPlugin) => {
      const valObj: Partial<
        Record<
          (typeof PLUGIN_ALIAS_CODE)[keyof typeof PLUGIN_ALIAS_CODE],
          string[]
        >
      > = {};
      valObj[currentPlugin.alias_code] = currentPlugin.feature_codes.map(
        (featureCode) => _.camelCase(featureCode)
      );
      return { ...accumVal, ...valObj };
    }, {});
  }
});

/**
 * 店舗一覧選択肢
 */
export const storeOptionsState = selector<
  { key: string; label: string; value: number }[]
>({
  key: 'storeOptions',
  get: ({ get }) =>
    get(allStoresAtom).map((store, index) => ({
      key: `store_option_${index}`,
      label: store.name,
      value: store.id
    }))
});

/** Cumin Ginger template の中で表示する項目名一覧 [<フィルド名（snake case）>]
 * 各項目は3種類の状態があり（非表示、任意、必須）
 * 非表示の場合、サーバーから返ってこない。任意はrequiredの項目がfalse
 **/
export const memberFormVisibleFieldsState = selector<string[] | undefined>({
  key: 'cuminProfileVisibleField',
  get: ({ get }) => {
    const {
      page_template: { alias_code, options }
    } = get(membershipSettingAtom);
    if (
      alias_code !== TEMPLATE_ALIAS_CODE.CUMIN &&
      alias_code !== TEMPLATE_ALIAS_CODE.GINGER
    ) {
      return undefined;
    }
    return options.reduce((previousValue, { code }) => {
      // member_form_始まりの項目だと notice_contentもあるため、requiredも条件にしないといけない
      if (!code.includes('member_form_') || !code.includes('_required')) {
        return previousValue;
      }
      return [
        ...previousValue,
        // option名は member_form_<フィルド名>_requiredとなるので、これでフィルド名が取得できる
        code.substring(
          MEMBER_FORM_PREFIX_POSITION,
          code.lastIndexOf('_required')
        )
      ];
    }, [] as string[]);
  }
});

/** Cumin Ginger template の中で必須入力項目名一覧 [<フィルド名（snake case）>] **/
export const memberFormRequiredFieldsState = selector<string[] | undefined>({
  key: 'cuminProfileRequiredFields',
  get: ({ get }) => {
    const {
      page_template: { alias_code, options }
    } = get(membershipSettingAtom);
    if (
      alias_code !== TEMPLATE_ALIAS_CODE.CUMIN &&
      alias_code !== TEMPLATE_ALIAS_CODE.GINGER
    ) {
      return undefined;
    }
    return options.reduce((previousValue, { code, required }) => {
      if (
        !code.includes('member_form_') ||
        !code.includes('_required') ||
        !required
      ) {
        return previousValue;
      }
      return [
        ...previousValue,
        code.substring(
          MEMBER_FORM_PREFIX_POSITION,
          code.lastIndexOf('_required')
        )
      ];
    }, [] as string[]);
  }
});
