import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import liff from '@line/liff';
import { theme } from 'libs/chakra';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  getTenantConfiguration,
  handleMoveToErrorPage,
  handleResetLocalStorage,
  signinUser,
  signupUser
} from './api';
import { API_RESPONSE_STATUS, TENANT_CODE } from './libs/constants';

import App from './App';
import './assets/styles/App.scss';
import ErrorPage from './pages/error/ErrorPage';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const PAGES_PREVENT_REGISTER_USER = [`/${TENANT_CODE}/error`];

/** ユーザ登録状況確認用関数
 * - 登録済みの場合、registered を返す
 * - 未登録の場合、登録してリロードする
 */
const checkUserRegistration = async (): Promise<void | 'registered'> => {
  const signinUserRes = await signinUser();
  // ユーザ照会成功（ユーザ登録済み）の場合、registered を返す
  if (signinUserRes.status === API_RESPONSE_STATUS.SUCCEEDED)
    return 'registered';

  // ユーザ未登録以外のエラーの場合、エラー画面へ
  if (!signinUserRes.errors.includes('登録されていないユーザです'))
    return handleMoveToErrorPage('');

  // ユーザ未登録の場合、ユーザ登録を行う
  const paramStr = window.location.search;
  const searchParams = new URLSearchParams(paramStr);
  const utmParam = {
    utm_source: searchParams.get('utm_source') || '',
    utm_campaign: searchParams.get('utm_campaign') || '',
    utm_content: searchParams.get('utm_content') || '',
    utm_medium: searchParams.get('utm_medium') || ''
  };
  const signupUserRes = await signupUser(utmParam);
  // ユーザ登録成功の場合、リロードして最初から処理をやり直す
  if (signupUserRes.status === API_RESPONSE_STATUS.SUCCEEDED) {
    // TODO:setTimeout の必要性の精査
    setTimeout(() => {
      location.reload();
    }, 500);
    return;
  }
  // ユーザ登録失敗の場合、エラー画面へ
  return handleMoveToErrorPage('create-user-failure');
};

// NOTE: await を使うため、closure にしないと怒られる
(async () => {
  if (
    TENANT_CODE &&
    !PAGES_PREVENT_REGISTER_USER.includes(window.location.pathname)
  ) {
    const tenantConfigResponse = await getTenantConfiguration();

    // テナント情報取得ができなかった場合、何も処理をしない。
    if (!tenantConfigResponse?.data) return;

    const { data: tenantConfig } = tenantConfigResponse;
    await liff
      .init({
        liffId: tenantConfig.liff_id,
        withLoginOnExternalBrowser: true
      })
      .catch(() => {
        handleResetLocalStorage();
        handleMoveToErrorPage('');
      });
    const res = await checkUserRegistration();
    // ユーザ照会成功（ユーザ登録済み）しない限り後続の処理は行わない
    if (res !== 'registered') return;

    root.render(
      <React.StrictMode>
        <App tenantConfig={tenantConfig} />
      </React.StrictMode>
    );
  } else {
    root.render(
      <React.StrictMode>
        <ChakraProvider theme={extendTheme(theme)}>
          <ErrorPage />
        </ChakraProvider>
      </React.StrictMode>
    );
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
