import { Button, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { ReservationType } from 'features/reservations/types/ReservationType';
import { FC } from 'react';
import ProductBarcodeModal from './ProductBarcodeModal';

const ProductBarcodeModalButtonSection: FC<{
  reservation: ReservationType | null;
  isScanPayment?: boolean;
}> = ({ reservation, isScanPayment = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    reservation_number: reservationNumber,
    reservation_status: status,
    products
  } = reservation || {
    reservation_number: undefined,
    reservation_status: undefined,
    products: []
  };

  return (
    <Stack gap="1rem" textAlign="start">
      <Text fontWeight="400" fontSize="lg">
        店舗で支払う際に、こちらのボタンから対象商品を表示してください
      </Text>
      <Button
        w="100%"
        h="2.85rem"
        variant="dark-outline"
        borderRadius="9999px"
        onClick={onOpen}
      >
        商品を表示する
      </Button>
      <ProductBarcodeModal
        isOpen={isOpen}
        onClose={onClose}
        status={status}
        reservationNumber={reservationNumber}
        isScanPayment={isScanPayment}
        products={(products || []).map((product) => {
          return {
            name: product.name,
            code: product.jan_code,
            format: product.jan_code_format,
            quantity: product.quantity
          };
        })}
      />
    </Stack>
  );
};

export default ProductBarcodeModalButtonSection;
