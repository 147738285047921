import { Box, Flex, Image, Tag, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { NotificationListItemType } from 'features/notification/types/NotificationType';
import { FALLBACK_IMAGES } from 'libs/constants';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type NotificationListItemProps = {
  notificationListItem: NotificationListItemType;
  unreadIds: number[];
  readNotification: (ids: number[]) => void;
};

const NotificationListItem: FC<NotificationListItemProps> = ({
  notificationListItem,
  unreadIds,
  readNotification
}) => {
  return (
    <RouterLink
      style={{ display: 'block', width: '100%' }}
      to={notificationListItem.id.toString()}
      onClick={() => {
        // 既読の場合はAPI叩かない
        unreadIds.includes(notificationListItem.id) &&
          readNotification([notificationListItem.id]);
      }}
    >
      <Flex
        w="100%"
        minH="3.75rem"
        bg="white"
        overflow="hidden"
        border="1px solid #C4C4C4"
        borderRadius="0.25rem"
      >
        {notificationListItem?.main_image && (
          <Image
            w="3.75rem"
            h="3.75rem"
            my="auto"
            objectFit="contain"
            loading="lazy"
            src={notificationListItem?.main_image}
            fallbackSrc={FALLBACK_IMAGES.square}
            alt="お知らせ画像"
          />
        )}
        <Box flex={1} p="0.62rem">
          <Flex h="1rem" mb="0.5rem">
            {!notificationListItem.read && (
              <Tag
                w="2.62rem"
                minHeight="1rem"
                mr="1rem"
                borderRadius="full"
                variant="solid"
                backgroundColor="#F25E5E"
                justifyContent="center"
                fontSize="0.62rem"
                fontWeight="700"
              >
                未読
              </Tag>
            )}
            <Text fontSize="0.62rem" fontWeight="400">
              {dayjs(notificationListItem.publish_at).format(
                'YYYY/MM/DD HH:mm'
              )}
            </Text>
          </Flex>
          <Text fontWeight="400" noOfLines={2} wordBreak="break-all">
            {notificationListItem.title}
          </Text>
        </Box>
      </Flex>
    </RouterLink>
  );
};

export default NotificationListItem;
