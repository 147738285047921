import {
  getRequestHeader,
  handleMoveToErrorPage,
  handleResetLocalStorage
} from 'api';
import axios, { ResponseType } from 'axios';
import { StoreType } from 'features/my-store/types/StoreType';
import { STORES_API_ENDPOINT, TENANT_CODE } from 'libs/constants';

export const storesApi = axios.create({
  baseURL: `${STORES_API_ENDPOINT}/${TENANT_CODE}/store`
});

// TODO: 今後の対応でも構いませんので、responseとerrorのformattingはinterceptorの方でやって、共通にしましょう。
storesApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    // NOTE: Move the user to /error page in case of the error response with 500 status.
    if (error.response?.status === 500) {
      handleMoveToErrorPage('server-error');
    }

    // NOTE: Move the user to session expired page in case of the error response with "IdToken expired." message
    if (error.response) {
      const errorObject = error.response.data?.errors;
      if (errorObject.includes('IdToken expired.')) {
        handleResetLocalStorage();
        handleMoveToErrorPage('session-expired');
      }
    }
    return Promise.reject(error);
  }
);

/** 全店舗を取得API */
export const getAllStores = () =>
  storesApi
    .request({
      url: '/stores',
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data.data.stores as StoreType[]);

/** 店舗を取得API */
export const getStoreById = (id: number) =>
  storesApi
    .request({
      url: `/stores/${id}`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data.data as StoreType)
    .catch(
      (error) =>
        ({
          ...error.response.data,
          status: error.response.status
        } as ResponseType)
    );

/** マイ店舗一覧を取得API */
export const getMyStores = () =>
  storesApi
    .request({
      url: '/my_stores',
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data.data.stores as StoreType[]);

/** マイ店舗を作成API */
export const createMyStore = (id: number) =>
  storesApi
    .request({
      url: `/my_stores/${id}`,
      method: 'POST',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data as ResponseType);

/** マイ店舗を削除API */
export const deleteMyStore = (id: number) =>
  storesApi
    .request({
      url: `/my_stores/${id}`,
      method: 'DELETE',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data as ResponseType);
