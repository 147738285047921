import SimpleFormPage from 'features/membership/components/simple/SimpleFormPage';
import useDefaultCardNumber from 'features/membership/hooks/useDefaultCardNumber';
import { useSimpleForm } from 'features/membership/hooks/useSimpleForm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  membershipPluginPageTemplateOptionState,
  SimplePageTemplateOptionKey
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * 会員登録入力
 */
const MintSignupNew: FC = () => {
  // NOTE: 最終的にsimple, pepperも下記のhook追加するが、先に先方が要望するテンプレートのみ修正する
  const defaultCardNumber = useDefaultCardNumber();
  const { useFormReturn, toConfirmPage } = useSimpleForm(defaultCardNumber);
  const confirmPagePath = `/membership/${TEMPLATE_ALIAS_CODE.MINT}/signup/confirm`;
  const templateOptions = useRecoilValue<
    Record<SimplePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.MINT));

  return (
    <SimpleFormPage
      useFormReturn={useFormReturn}
      onFormSubmit={toConfirmPage(confirmPagePath)}
      templateOptions={templateOptions}
      isSignUp
      cardNumberLabel="会員番号"
    />
  );
};

export default MintSignupNew;
