import { EarthUserProfileResponse } from 'features/membership/types';
import { CuminUserProfileFormInput } from 'features/membership/types/form';
import cuminFormatBirthdayForForm from './cuminFormatBirthdayForForm';

/**
 * ユーザー情報表示APIから返却されたデータを編集フォーム用に変換する関数
 * @param data ユーザー情報表示APIから返却されたユーザー情報
 *
 * @returns APIから取得したユーザー情報をフォーム用に変換したオブジェクト
 * @return full_name - APIから取得した姓名を分割しまとめたオブジェクト
 * @return full_name.lastName - 姓
 * @return full_name.firstName - 名
 * @return full_name_kana - APIから取得した姓名（カナ）を分割しまとめたオブジェクト
 * @return full_name_kana.lastName - 姓（カナ）
 * @return full_name_kana.firstName - 名（カナ）
 * @return birthday - APIから取得した誕生日を分割しまとめたオブジェクト
 * @return birthday.year - 年
 * @return birthday.month - 月
 * @return birthday.date - 日
 * @return gender - 性別
 * @return phone_number - APIから取得した電話番号からハイフンを除いた値\
 *    NOTE: サーバー側で自動で正規化されてしまう関係で、ハイフンありの電話番号がBEから返却されてしまうが、\
 *          FEではハイフンなしの表示がしたいというデジクルさんの要望があるため変換を行う
 * @return postal_code - 郵便番号
 * @return address_1 - 都道府県・市区町村
 * @return address_2 - 番地以降
 * @return occupation - 職業
 * @return household_size - ご家族人数
 * @return entry_store_id - よく行く店舗
 */
const cuminFormatDataForForm = (
  data: EarthUserProfileResponse['data']
): CuminUserProfileFormInput => {
  // 初めての全角スペース前が姓、他は全部名
  const full_name = data.full_name
    ? {
        lastName: data.full_name.substring(0, data.full_name.indexOf('　')),
        firstName: data.full_name.substring(data.full_name.indexOf('　') + 1)
      }
    : {
        lastName: '',
        firstName: ''
      };
  // 初めての全角スペース前が姓、他は全部名
  const full_name_kana = data.full_name_kana
    ? {
        lastName: data.full_name_kana.substring(
          0,
          data.full_name_kana.indexOf(' ')
        ),
        firstName: data.full_name_kana.substring(
          data.full_name_kana.indexOf(' ') + 1
        )
      }
    : {
        lastName: '',
        firstName: ''
      };
  // 誕生日を年月日それぞれに分ける
  const birthday = data.birthday
    ? cuminFormatBirthdayForForm(data.birthday)
    : null;
  // 電話番号からハイフンを除去
  const phoneNumberWithoutHyphen = data.phone_number
    ? data.phone_number.replace(/-/g, '')
    : null;

  return {
    ...data,
    phone_number: phoneNumberWithoutHyphen,
    full_name,
    full_name_kana,
    birthday
  };
};

export default cuminFormatDataForForm;
