import LabeledValueWithVisibility from 'components/modules/labeledValue/LabeledValueWithVisibility';
import useStoreOptions from 'features/membership/hooks/cumin/useStoreOptions';
import { GENDER_OPTIONS } from 'features/membership/libs/const/cuminCardIssueOption';
import { OccupationOption } from 'features/membership/types';
import { CuminIssueCardFormInput } from 'features/membership/types/form';
import { FC } from 'react';

type CuminMemberProfileListProps = {
  visibleFields?: string[];
  memberProfile: Partial<CuminIssueCardFormInput>;
  showEntryStoreCodeField?: boolean;
  occupationOptions?: OccupationOption[] | null;
};

const CuminMemberProfileList: FC<CuminMemberProfileListProps> = ({
  memberProfile: { birthday, full_name, full_name_kana, ...profile },
  showEntryStoreCodeField,
  visibleFields,
  occupationOptions
}) => {
  const storeOptions = useStoreOptions();

  return (
    <>
      <LabeledValueWithVisibility
        isVisible={visibleFields?.includes('full_name')}
        label="お名前"
        value={`${full_name?.lastName ?? ''} ${full_name?.firstName ?? ''}`}
      />
      <LabeledValueWithVisibility
        isVisible={visibleFields?.includes('full_name_kana')}
        label="お名前(カナ)"
        value={`${full_name_kana?.lastName ?? ''} ${
          full_name_kana?.firstName ?? ''
        }`}
      />
      <LabeledValueWithVisibility
        isVisible={visibleFields?.includes('birthday')}
        label="生年月日"
        value={
          !birthday || Object.values(birthday).some((val) => !val)
            ? ''
            : `${birthday.year}年${birthday.month}月${birthday.date}日`
        }
      />
      <LabeledValueWithVisibility
        isVisible={visibleFields?.includes('gender')}
        label="性別"
        value={
          GENDER_OPTIONS.find(({ value }) => value === profile.gender)?.label ??
          ''
        }
      />
      <LabeledValueWithVisibility
        isVisible={visibleFields?.includes('phone_number')}
        label="電話番号"
        value={profile?.phone_number ?? ''}
      />
      <LabeledValueWithVisibility
        isVisible={visibleFields?.includes('postal_code')}
        label="郵便番号"
        value={profile?.postal_code ?? ''}
      />
      <LabeledValueWithVisibility
        isVisible={visibleFields?.includes('address_1')}
        label="都道府県・市区町村"
        value={profile?.address_1 ?? ''}
      />
      <LabeledValueWithVisibility
        isVisible={visibleFields?.includes('address_2')}
        label="番地以降"
        value={profile?.address_2 ?? ''}
      />
      <LabeledValueWithVisibility
        isVisible={visibleFields?.includes('occupation')}
        label="職業"
        value={
          occupationOptions?.find(
            (option) => option.value === profile?.occupation
          )?.label ?? ''
        }
      />
      <LabeledValueWithVisibility
        isVisible={visibleFields?.includes('household_size')}
        label="ご家族人数"
        value={
          typeof profile.household_size === 'number'
            ? `${profile?.household_size}人`
            : ''
        }
      />
      {showEntryStoreCodeField && (
        <LabeledValueWithVisibility
          isVisible={visibleFields?.includes('entry_store_id')}
          label="よく行く店舗"
          value={
            storeOptions.find(
              (option) => option.value === profile?.entry_store_id
            )?.label ?? ''
          }
        />
      )}
    </>
  );
};
export default CuminMemberProfileList;
