import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { FC } from 'react';

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  submitText: string;
  cancelText: string;
  onSubmit: () => void;
  isDestructive?: boolean;
};

const Dialog: FC<DialogProps> = ({
  isOpen,
  onClose,
  title,
  description,
  cancelText,
  submitText,
  onSubmit,
  isDestructive
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent mx="1rem" pb="0.5rem">
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{description}</ModalBody>

        <ModalFooter w="100%" borderBottomRadius="1rem">
          <HStack gap="0.5rem">
            <Button onClick={onClose} variant="dark-outline">
              {cancelText}
            </Button>
            <Button
              onClick={onSubmit}
              variant={isDestructive ? 'danger' : 'primary'}
            >
              {submitText}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Dialog;
