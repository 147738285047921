import { Skeleton, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

const ReservationNumber: FC<{
  reservationNumber?: string;
  isDone: boolean;
}> = ({ reservationNumber, isDone }) => {
  if (!reservationNumber) return <></>;
  return (
    <VStack mt="0.25rem" spacing="0">
      <Text fontWeight={400}>予約番号</Text>
      <Skeleton
        isLoaded={isDone}
        className="u-readableAlphaNumerals"
        fontSize="5xl"
        fontWeight="bold"
        minH="3.75rem"
        minW="10rem"
        letterSpacing="0.1rem"
      >
        {reservationNumber}
      </Skeleton>
    </VStack>
  );
};

export default ReservationNumber;
