import { TemplateAliasCode } from 'features/membership/types';
import { membershipSettingAtom } from 'libs/recoil/atom';
import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

type AliasCodeVerifierProps = TemplateAliasCode;

/**
 * 会員連携画面テンプレート: ルートコンポーネント
 *
 * アクセス可能かどうかをチェックする
 */
const AliasCodeVerifier: FC<AliasCodeVerifierProps> = ({
  templateAliasCode
}) => {
  const {
    page_template: { alias_code }
  } = useRecoilValue(membershipSettingAtom);

  // トップへ
  if (alias_code !== templateAliasCode) return <Navigate to="/" />;

  return <Outlet />;
};

export default AliasCodeVerifier;
