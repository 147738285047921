import dispatchToast from 'components/modules/Toast';
import {
  patchMembershipPluginCard,
  postMembershipPluginLink
} from 'features/membership/api';
import { PLUGIN_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { membershipPluginFeatureState } from 'features/membership/libs/recoil/selector';
import {
  CompletePagePath,
  FormPagePath
} from 'features/membership/types/pagePath';
import { membershipMemberResponseAtom } from 'libs/recoil/atom';
import { MouseEventHandler, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';

const usePepperConfirm = ({
  formPagePath,
  completePagePath
}: CompletePagePath & FormPagePath) => {
  const { state: cardNumber, pathname } = useLocation();
  const navigate = useNavigate();
  const setMembershipMemberResponse = useSetRecoilState(
    membershipMemberResponseAtom
  );
  const mercuryPluginFeatures = useRecoilValue(
    membershipPluginFeatureState
  ).mercury;

  // カード連携もしくは番号変更かのチェックとplugin optionの存在チェックを行いAPI実行関数を返却する
  const updateCardNumber = useMemo(() => {
    if (
      completePagePath.includes('/signup/complete') &&
      mercuryPluginFeatures?.includes('linkMember')
    ) {
      return postMembershipPluginLink; // MBS-API005 会員連携API（プラグイン）
    }
    if (
      completePagePath.includes('/complete') &&
      mercuryPluginFeatures?.includes('changeCardNumber')
    ) {
      return patchMembershipPluginCard; // MBS-API006 カード番号変更API（プラグイン）
    }
    return null;
  }, [mercuryPluginFeatures, completePagePath]);

  const [{ loading: isMutating }, onClickRegisterButton] =
    useAsyncFn(async () => {
      if (typeof cardNumber !== 'string' || !updateCardNumber) return;
      const res = await updateCardNumber(PLUGIN_ALIAS_CODE.MERCURY, cardNumber);
      // エラー
      if (res.status === 'failed') {
        dispatchToast({
          id: 'membership-member-api-error',
          toastText: res.errors.join('\n')
        });
        navigate(formPagePath, { state: cardNumber });
        return;
      }
      setMembershipMemberResponse(undefined); // MBS-API003 会員情報照会API のレスポンス のグローバルステートを削除
      navigate(pathname, { replace: true }); // state を削除
      navigate(completePagePath); // 完了ページへ
    }, [cardNumber, pathname, updateCardNumber]);

  const onClickFixButton = useCallback<MouseEventHandler>(() => {
    navigate(formPagePath, { state: cardNumber });
  }, [navigate, formPagePath, cardNumber]);

  return {
    onClickRegisterButton,
    isMutating,
    onClickFixButton,
    cardNumber
  };
};
export default usePepperConfirm;
