import { Card, Image, Text, VStack } from '@chakra-ui/react';
import { BarcodeWithErrorBoundary } from 'components/modules/Barcode';
import { FC } from 'react';
import Clock from 'react-live-clock';

type CuminBarcodeBoxProps = {
  cardNumber: string;
  imageUrl?: string | null;
  barcodeFormat?: string;
};

const CuminBarcodeBox: FC<CuminBarcodeBoxProps> = ({
  cardNumber,
  imageUrl,
  barcodeFormat
}) => {
  if (!imageUrl) {
    return null;
  }
  return (
    // カードの上半分を管理画面側で設定し、下半分をバーコード表示する
    <VStack spacing={0} m="0">
      <Image
        alt="カード画像"
        src={imageUrl}
        w="100%"
        objectFit="contain"
        borderTopRadius="10px"
      />
      {cardNumber && (
        <Card
          pt="0.5rem"
          w="100%"
          borderTopRadius="0"
          borderBottomRadius="12px"
          boxShadow="none"
        >
          <VStack spacing="0">
            {/** スクリーンショット防止用にリアルタイムで時刻を表示 */}
            <Text fontSize="2xs">
              <Clock
                format={'YYYY/MM/DD HH:mm:ss'}
                timezone={'Asia/Tokyo'}
                ticking
              />
            </Text>
            <BarcodeWithErrorBoundary
              code={cardNumber}
              format={barcodeFormat}
              height={30}
              fontSize={16}
              flat
            />
          </VStack>
        </Card>
      )}
    </VStack>
  );
};
export default CuminBarcodeBox;
