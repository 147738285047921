import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import { WarningIcon } from 'assets/icons/miscs';
import * as DOMPurify from 'dompurify';
import { openWindow } from 'libs/line';
import { FC, MouseEvent, useEffect, useRef, useState } from 'react';

type CampaignReceiptNoteProps = { campaign_receipt_note: string };

/** 受け取りに関する注記 */
const CampaignReceiptNote: FC<CampaignReceiptNoteProps> = ({
  campaign_receipt_note
}) => {
  const noticeContainerRef = useRef<HTMLDivElement>(null);
  const [isNoticeOverflowed, setIsNoticeOverflowed] = useState(false);
  const [showAllNotice, setShowAllNotice] = useState(false);

  const handleClickLink = (event: MouseEvent<HTMLParagraphElement>) => {
    event.preventDefault();

    const target = event.target as HTMLAnchorElement;
    if (target.tagName !== 'A') return;

    openWindow(target.href, true);
  };

  // 「続きを見る」ボタン表示・非表示判定処理：注記の文字数が多く、6行以上になる時のみ、「続きを見る」ボタンを表示する
  // *端末の縦横の向きが変わったケースにも対応できるようにする
  useEffect(() => {
    const noticeContainer = noticeContainerRef.current;

    const checkIsNoticeOverflowed = () => {
      const isScrollHeightOverflowed = noticeContainer
        ? noticeContainer.scrollHeight > noticeContainer.clientHeight
        : false;
      setIsNoticeOverflowed(showAllNotice || isScrollHeightOverflowed);
    };

    checkIsNoticeOverflowed();

    window.addEventListener('resize', checkIsNoticeOverflowed);

    return () => {
      window.removeEventListener('resize', checkIsNoticeOverflowed);
    };
  }, [showAllNotice]);

  return (
    <Flex
      w="100%"
      p="0.75rem"
      gap="0.25rem"
      bg="#F7F7F7"
      borderColor="#E3E3E3"
      borderRadius="0.375rem"
      borderWidth="thin"
    >
      <WarningIcon />
      <Stack w="100%" spacing="0.75rem">
        <Text
          ref={noticeContainerRef}
          fontSize="md"
          fontWeight="400"
          wordBreak="break-all"
          whiteSpace="pre-wrap"
          noOfLines={showAllNotice ? undefined : 6}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(campaign_receipt_note) // XSS 対策
          }}
          css={{
            a: {
              color: 'var(--chakra-colors-primary)',
              fontWeight: 500,
              textDecoration: 'underline'
            }
          }}
          onClick={handleClickLink}
        />
        {isNoticeOverflowed && (
          <Button
            variant="ghost"
            ml="auto"
            p={0}
            size="xs"
            fontSize="md"
            fontWeight="400"
            color="#8B8B8B"
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            onClick={() => setShowAllNotice(!showAllNotice)}
          >
            {showAllNotice ? '閉じる' : '続きを見る'}
          </Button>
        )}
      </Stack>
    </Flex>
  );
};

export default CampaignReceiptNote;
