import { TenantType } from 'types/TenantType';

export const DUMMY_TENANT_CODE = 'tenant_code';
// NOTE: テスト実施時に利用するため、ローカルのエンドポイントを定数化
export const LOCAL_API_ENDPOINT = 'http://127.0.2.1:3000/api/v1/line';
export const LOCAL_LIFF_ENDPOINT = 'https://package.digiclueapp.local:3000';

export const DUMMY_TENANT: TenantType = {
  name: 'テスト用テナント_1',
  brand: 'テストブランド_1',
  code: DUMMY_TENANT_CODE,
  line_channel_key: '123456789', // 実際は存在しないLINEミニアプリチャネル
  liff_url: 'https://miniapp.line.me/123456789-zsdhytXRef0',
  liff_id: '123456789-zsdhytXRef0',
  tax_round: 'round_down',
  scan_paymentable: false,
  features: [
    'reserve',
    'store',
    'membership',
    'digiclueplus',
    'notice',
    'link'
  ],
  feature_objects: [
    {
      id: 1,
      name: '予約販売',
      code: 'reserve',
      sub_features: []
    },
    {
      id: 2,
      name: '店舗管理',
      code: 'store',
      sub_features: []
    },
    {
      id: 3,
      name: 'お知らせ',
      code: 'notice',
      sub_features: []
    },
    {
      id: 4,
      name: '会員連携',
      code: 'membership',
      sub_features: []
    },
    {
      id: 5,
      name: 'デジクルプラス連携',
      code: 'digiclueplus',
      sub_features: [
        {
          id: 1,
          name: 'シンプルクーポン',
          code: 'simple_coupons'
        },
        {
          id: 2,
          name: 'ポイントクーポン',
          code: 'point_coupons'
        },
        {
          id: 3,
          name: 'キャンペーン',
          code: 'stamps'
        }
      ]
    },
    {
      id: 6,
      name: 'link',
      code: 'link',
      sub_features: []
    }
  ],
  logo_image: null,
  main_color: '#fdd35c',
  sub_color: '',
  external_links: [
    {
      label: 'リンク',
      url: 'https://spice-factory.co.jp/',
      icon: 'link'
    }
  ]
};

export const {
  line_channel_key,
  liff_id,
  tax_round,
  ...DUMMY_TENANT_CONFIGURATION
} = DUMMY_TENANT;
