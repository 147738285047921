import { AlertIcon } from 'assets/icons/miscs';
import { FC } from 'react';

type IconWithBadgeType = {
  icon: JSX.Element;
  hasBadge?: boolean;
};

const IconWithBadge: FC<IconWithBadgeType> = ({ icon, hasBadge }) => {
  return (
    <span style={{ position: 'relative', display: 'inline-flex' }}>
      {hasBadge && (
        <span
          style={{
            position: 'absolute',
            left: '17px',
            top: '0px'
          }}
        >
          <AlertIcon width="11px" height="11px" position="absolute" />
        </span>
      )}
      {icon}
    </span>
  );
};

export default IconWithBadge;
