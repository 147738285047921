import SimpleConfirmPage from 'features/membership/components/simple/SimpleConfirmPage';
import useSimpleConfirm from 'features/membership/hooks/useSimpleConfirm';
import {
  COMPLETE_PAGE_PATH,
  FORM_PAGE_PATH
} from 'features/membership/libs/const/memberPagePath';
import { membershipSimpleSettingOptionsState } from 'features/membership/libs/recoil/selector';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * 会員登録入力確認
 */
const SimpleSignupConfirm: FC = () => {
  const { signupConfirmNoticeContentOption } = useRecoilValue(
    membershipSimpleSettingOptionsState
  );
  // simpleの会員登録入力ページのパスを取得
  const formPagePath = FORM_PAGE_PATH.SIMPLE_NEW;
  const { isMutating, onClickRegisterButton, onClickFixButton, cardNumber } =
    useSimpleConfirm({
      formPagePath,
      completePagePath: COMPLETE_PAGE_PATH.SIMPLE_SIGNUP_COMPLETE
    });

  return (
    <SimpleConfirmPage
      pageTemplateOptions={{ signupConfirmNoticeContentOption }}
      noticeTextOptionKey="signupConfirmNoticeContentOption"
      formPagePath={formPagePath}
      cardNumber={cardNumber}
      isMutating={isMutating}
      onClickRegisterButton={onClickRegisterButton}
      onClickFixButton={onClickFixButton}
    />
  );
};

export default SimpleSignupConfirm;
