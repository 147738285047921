import SimpleTopPage from 'features/membership/components/simple/SimpleTopPage';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { membershipSimpleSettingOptionsState } from 'features/membership/libs/recoil/selector';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * トップ
 */
const SimpleTop: FC = () => {
  const templateOptions = useRecoilValue(membershipSimpleSettingOptionsState);
  return (
    <SimpleTopPage
      templateAliasCode={TEMPLATE_ALIAS_CODE.SIMPLE}
      templateOptions={templateOptions}
    />
  );
};

export default SimpleTop;
