import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { getTrackBackground, Range } from 'react-range';

type ConfirmSliderType = {
  values: number[];
  setValues: (values: number[]) => void;
  isDisabled?: boolean;
  rangeText?: string;
  handleComplete: () => void;
};

const ConfirmSlider: FC<ConfirmSliderType> = ({
  values,
  setValues,
  handleComplete,
  isDisabled,
  rangeText
}) => {
  const handleChangeValue = (values: number[]) => {
    if (values[0] > 90) {
      handleComplete();
    } else {
      setValues([0]);
    }
  };

  return (
    <Range
      values={values}
      onChange={(values) => setValues(values)}
      onFinalChange={handleChangeValue}
      disabled={isDisabled}
      renderTrack={(params) => (
        <Box
          style={{
            ...params.props.style,
            height: '3rem',
            display: 'flex',
            width: '100%',
            padding: '1rem'
          }}
        >
          <Box
            ref={params.props.ref}
            h="3rem"
            w="100%"
            borderRadius="6rem"
            background={getTrackBackground({
              values,
              colors: [
                'var(--chakra-colors-primary90)',
                'var(--chakra-colors-primary)'
              ],
              min: 0,
              max: 100
            })}
            position="relative"
            opacity={isDisabled ? 0.6 : 1}
          >
            <Text
              color="white"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontSize="lg"
              fontWeight="600"
            >
              {rangeText}
            </Text>
            {params.children}
          </Box>
        </Box>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            height: '3.5rem',
            width: '3.5rem',
            borderRadius: '50%',
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 2px 6px #aaa'
          }}
        >
          <Box
            h="1rem"
            w="1rem"
            borderTop="0.25rem solid"
            borderRight="0.25rem solid"
            borderColor="primary"
            transform="rotate(45deg)"
            marginLeft="-0.5rem"
          />
        </div>
      )}
    />
  );
};

export default ConfirmSlider;
