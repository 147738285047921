import { Container, Heading, Text, VStack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import ReservationHistoryItem from 'features/reservations/components/ReservationHistoryItem';
import { ReservationHistorySkeletonBox } from 'features/reservations/components/SkeletonBoxes';
import { useReservationHistory } from 'features/reservations/hooks/useReservationHistory';
import { isAvailableReservationStatusAlias } from 'features/reservations/libs/reservationStatus';
import { ReservationType } from 'features/reservations/types/ReservationType';
import { FC } from 'react';

/** 履歴一覧（予約一覧） */
const ReservationHistory: FC = () => {
  const { reservations, isDone } = useReservationHistory();
  return (
    <MainLayout bg="dark.50" header footer>
      <Container px="1rem" mt="3rem" mb="2.5rem">
        <Heading size="md" mb="1rem">
          注文履歴
        </Heading>
        {isDone ? (
          Object.keys(reservations)?.length !== 0 ? (
            <VStack columnGap={0} mx="0">
              {Object.entries(reservations).map(
                ([statusAlias, reservations]: [string, ReservationType[]]) =>
                  isAvailableReservationStatusAlias(statusAlias) &&
                  reservations.map((reserve) => (
                    <ReservationHistoryItem
                      key={reserve.id}
                      reservation={reserve}
                    />
                  ))
              )}
            </VStack>
          ) : (
            <Text fontWeight={400}>注文履歴はありません</Text>
          )
        ) : (
          <VStack columnGap="0.25rem">
            <ReservationHistorySkeletonBox />
            <ReservationHistorySkeletonBox />
            <ReservationHistorySkeletonBox />
            <ReservationHistorySkeletonBox />
            <ReservationHistorySkeletonBox />
          </VStack>
        )}
      </Container>
    </MainLayout>
  );
};

export default ReservationHistory;
