import { ListItem, UnorderedList } from '@chakra-ui/react';
import { FC } from 'react';

type CampaignReceiptTimeNotesProps = {
  campaign_auto_cancel_waiting_hours: number;
};

/** 受取日時に関する注記 */
const CampaignReceiptTimeNotes: FC<CampaignReceiptTimeNotesProps> = ({
  campaign_auto_cancel_waiting_hours
}) => {
  return (
    <UnorderedList fontSize="sm" mt="0.5rem">
      <ListItem>
        受取日時になったら、受取店舗のサービスカウンター、もしくはレジでこちらの画面をお見せください。
      </ListItem>
      <ListItem>
        また受取日時から
        {campaign_auto_cancel_waiting_hours}
        時間過ぎても受取店舗にいらっしゃらない場合、本予約は自動で取り消しとなりますので、あらかじめご了承ください。
      </ListItem>
    </UnorderedList>
  );
};

export default CampaignReceiptTimeNotes;
