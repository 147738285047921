/**
 * Jupiter pluginにおけるfeature_codesのapiのキーをまとめた定数
 * VDのみ関係するplugin
 * Ginger template optionで利用
 * */
export const JUPITER_FEATURE_CODES = {
  // 取引履歴取得
  GET_TRANSACTION_HISTORIES: 'get_transaction_histories',
  // マネー残高取得
  GET_BALANCE: 'get_balance'
} as const;
