import { Button, Stack, Text, VStack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import TermOfService from 'components/modules/TermOfService';
import CuminMemberProfileForm from 'features/membership/components/cumin/CuminMemberProfileForm';
import useCardIssueForm from 'features/membership/hooks/cumin/useCardIssueForm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  CuminPagePageTemplateOptionKey,
  memberFormRequiredFieldsState,
  memberFormVisibleFieldsState,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FC, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

/**
 * カード番号付番/入力
 */
const CuminCardIssue: FC = () => {
  const memberFormVisibleFields = useRecoilValue(memberFormVisibleFieldsState);
  const memberFormRequiredFields = useRecoilValue(
    memberFormRequiredFieldsState
  );
  const { methods, formSubmitHandler, isMutating } = useCardIssueForm({
    templateAliasCode: TEMPLATE_ALIAS_CODE.CUMIN,
    formFields: memberFormVisibleFields,
    requiredFormFields: memberFormRequiredFields
  });
  const templateOptions = useRecoilValue<
    Record<CuminPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.CUMIN));

  // 利用規約の同意
  const termOfServiceContent =
    templateOptions?.issueTermsOfServiceContentOption?.content;
  // 規約チェックボックスの状態
  const [isChecked, setIsChecked] = useState(!termOfServiceContent);
  const isValid = methods.formState.isValid;

  return (
    <MainLayout header>
      <Stack px="1rem" pt="3rem" pb="0.75rem" h="100%">
        <VStack gap="4" alignItems="start" w="100%" pb="0.75rem">
          <Text fontSize="xl" fontWeight="700" m="0">
            会員情報を入力してください
          </Text>
          <FormProvider {...methods}>
            <Stack
              as="form"
              onSubmit={formSubmitHandler}
              gap="1rem"
              alignItems="center"
              w="100%"
            >
              <CuminMemberProfileForm
                templateOptions={templateOptions}
                visibleFields={memberFormVisibleFields}
                requiredFields={memberFormRequiredFields}
                showEntryStoreCodeField
                isBirthdayEditable
              />
              {/* 利用規約チェックボックス 利用規約が存在する場合 かつ 新規登録時に表示*/}
              {!!termOfServiceContent && (
                <TermOfService
                  variant="secondary"
                  content={termOfServiceContent}
                  onCheckboxChange={(e) => setIsChecked(e.target.checked)}
                />
              )}

              {/* 「確認画面へ」ボタン */}
              <Button
                type="submit"
                variant="primary-fullwidth-rounded"
                isDisabled={!isChecked || !isValid}
                isLoading={isMutating}
              >
                確認画面へ
              </Button>
            </Stack>
          </FormProvider>
        </VStack>
      </Stack>
    </MainLayout>
  );
};
export default CuminCardIssue;
