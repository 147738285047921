import 'react-datepicker/dist/react-datepicker.css'; // NOTE：スタイルが崩れるため一番上に配置

import {
  Box,
  Button,
  Container,
  Input,
  Select as ChakraSelect,
  Skeleton,
  Text,
  Textarea,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useLayoutEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import 'assets/styles/date-picker.scss';
import MainLayout from 'components/layouts/MainLayout';
import FormInputField from 'components/modules/FormInputField';
import {
  checkReceiptableDate,
  getReceiptableDates,
  getReceiptableHourWidths
} from 'features/reservations/api';
import CampaignHeroSection from 'features/reservations/components/CampaignHeroSection';
import DatepickerModal from 'features/reservations/components/DatepickerModal';
import { PassingCartStateType } from 'features/reservations/components/ProductDetailModal';
import { useCampaignDetail } from 'features/reservations/hooks/useCampaign';
import { useCart } from 'features/reservations/hooks/useCart';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { telRegExp } from 'libs/regularExpressions';

export type ReservationCustomerFormType = {
  date: string;
  timerange: string;
  card_number?: string;
  tel_number?: string;
  staff_comment?: string;
  option_1_answer?: string;
  option_2_answer?: string;
  option_3_answer?: string;
};

/** 予約フォーム（受取設定） */
const ReservationCustomerForm: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { campaignId } = useParams();
  const { targetCampaign, isDone: isDoneTargetCampaign } = useCampaignDetail(
    Number(campaignId)
  );

  const { cartProducts } = useCart(Number(campaignId));

  const { state } = location as PassingCartStateType;

  const validationRule = yup
    .object({
      date: yup.string().required(),
      timerange: yup.string().required(),
      card_number:
        targetCampaign?.collect_card_number === 'required'
          ? yup.string().required()
          : yup.string().notRequired(),
      tel_number:
        targetCampaign?.collect_tel_number === 'required'
          ? yup.string().required().matches(telRegExp)
          : yup
              .string()
              .notRequired()
              .matches(telRegExp, { excludeEmptyString: true }),
      staff_comment:
        targetCampaign?.collect_staff_comment === 'required'
          ? yup.string().required()
          : yup.string().notRequired(),
      option_1_answer:
        targetCampaign?.option_1_status === 'required'
          ? yup.string().required()
          : yup.string().notRequired(),
      option_2_answer:
        targetCampaign?.option_2_status === 'required'
          ? yup.string().required()
          : yup.string().notRequired(),
      option_3_answer:
        targetCampaign?.option_3_status === 'required'
          ? yup.string().required()
          : yup.string().notRequired()
    })
    .required();

  const {
    isOpen: isOpenDatepicker,
    onOpen: handleOpenDatepicker,
    onClose: handleCloseDatepicker
  } = useDisclosure();

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    resetField,
    watch,
    formState: { errors, isValid }
  } = useForm<ReservationCustomerFormType>({
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: yupResolver(validationRule)
  });

  const watchDate = watch('date');

  const [receiptableDates, setReceiptableDates] = useState<string[]>([]);
  const [receiptableHourWidths, setReceiptableHourWidths] = useState<string[]>(
    []
  );
  const [isLoadingSubmission, setIsLoadingSubmission] =
    useState<boolean>(false);

  // campaignId, cartProducts, stateが変更されないため、
  // 以下のuseLayoutEffectは一度しか実行されない
  useLayoutEffect(() => {
    if (state) {
      getReceiptableDates({
        campaign_id: Number(campaignId),
        store_id: state.storeId ?? 0,
        products: cartProducts.map(({ id, quantity }) => ({
          product_id: id,
          quantity
        }))
      }).then((res) => {
        if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
          setReceiptableDates(res.data.receiptable_dates);
        }
      });
    }
  }, [campaignId, cartProducts, state]);

  useEffect(() => {
    if (watchDate) {
      resetField('timerange');
    }
  }, [resetField, watchDate]);

  const checkAvailableDateForPickup = (date: string) =>
    checkReceiptableDate({
      campaign_id: Number(campaignId),
      store_id: state.storeId ?? 0,
      receipt_at: date,
      products: cartProducts.map(({ id, quantity }) => ({
        product_id: id,
        quantity
      }))
    });

  const handleUpdatePickupDate = (date: string) => {
    setValue('date', date);
    getReceiptableHourWidths({
      campaign_id: Number(campaignId),
      store_id: state.storeId ?? 0,
      receipt_at: date
    }).then((res) => setReceiptableHourWidths(res));
  };

  const onSubmit = (values: ReservationCustomerFormType) => {
    setIsLoadingSubmission(true);
    navigate(`/reservations/${campaignId}/confirm`, {
      state: {
        ...state,
        receipt_at: values.date,
        receipt_hour_width: values.timerange,
        card_number: values.card_number ?? '',
        tel_number: values.tel_number ?? '',
        staff_comment: values.staff_comment ?? '',
        option_1_answer: values.option_1_answer ?? '',
        option_2_answer: values.option_2_answer ?? '',
        option_3_answer: values.option_3_answer ?? ''
      }
    });
  };

  return (
    <>
      <MainLayout header>
        <CampaignHeroSection
          title={targetCampaign?.name ?? ''}
          imgSrc={targetCampaign?.image_file_urls[0]?.image_file_url ?? ''}
          isLoaded={isDoneTargetCampaign}
        />
        <Container mb="1rem" px="1rem" pb="4rem">
          <VStack gap="0.75rem">
            <FormInputField label="受取日" isRequired={true}>
              <Skeleton isLoaded={isDoneTargetCampaign}>
                <Input
                  type="text"
                  readOnly
                  borderRadius="0"
                  placeholder="受取日を選択してください"
                  zIndex={0}
                  onClick={handleOpenDatepicker}
                  {...register('date')}
                />
              </Skeleton>
            </FormInputField>
            <FormInputField label="受取時間" isRequired={true}>
              <Skeleton isLoaded={isDoneTargetCampaign}>
                <ChakraSelect
                  borderRadius="0"
                  placeholder="選択してください"
                  {...register('timerange')}
                >
                  {receiptableHourWidths.map((hourWidth) => (
                    <option key={hourWidth} value={hourWidth}>
                      {hourWidth
                        .split('~')
                        .map((time) =>
                          dayjs(`2000/01/01 ${time}`).format('H:mm')
                        )
                        .join('〜')}
                    </option>
                  ))}
                </ChakraSelect>
              </Skeleton>
            </FormInputField>
            {(targetCampaign?.collect_card_number === 'required' ||
              targetCampaign?.collect_card_number === 'optional') && (
              <FormInputField
                label="会員番号"
                isRequired={targetCampaign?.collect_card_number === 'required'}
              >
                <Input
                  type="text"
                  borderRadius="0"
                  placeholder="会員番号を入力してください"
                  zIndex={0}
                  {...register('card_number')}
                />
              </FormInputField>
            )}
            {(targetCampaign?.collect_tel_number === 'required' ||
              targetCampaign?.collect_tel_number === 'optional') && (
              <FormInputField
                label="電話番号"
                isRequired={targetCampaign?.collect_tel_number === 'required'}
                error={
                  errors.tel_number
                    ? '0から始まる10桁または11桁の半角数字で入力してください'
                    : undefined
                }
              >
                <Input
                  type="tel"
                  borderRadius="0"
                  placeholder="電話番号を入力してください"
                  zIndex={0}
                  {...register('tel_number')}
                />
              </FormInputField>
            )}
            {(targetCampaign?.collect_staff_comment === 'required' ||
              targetCampaign?.collect_staff_comment === 'optional') && (
              <FormInputField
                label="受付スタッフ記入欄"
                isRequired={
                  targetCampaign?.collect_staff_comment === 'required'
                }
              >
                <Textarea
                  borderRadius="0"
                  placeholder={
                    targetCampaign?.collect_staff_comment === 'required'
                      ? '入力する内容は店舗スタッフまで確認ください'
                      : 'お客様自身での予約の場合は記入不要です'
                  }
                  zIndex={0}
                  {...register('staff_comment')}
                />
              </FormInputField>
            )}
            {(targetCampaign?.option_1_status === 'required' ||
              targetCampaign?.option_1_status === 'optional') && (
              <FormInputField
                label={targetCampaign?.option_1_label || '任意項目1'}
                isRequired={targetCampaign?.option_1_status === 'required'}
              >
                <Input
                  type="text"
                  borderRadius="0"
                  placeholder={`${
                    targetCampaign?.option_1_label || '任意項目1'
                  }を入力してください`}
                  zIndex={0}
                  {...register('option_1_answer')}
                />
              </FormInputField>
            )}
            {(targetCampaign?.option_2_status === 'required' ||
              targetCampaign?.option_2_status === 'optional') && (
              <FormInputField
                label={targetCampaign?.option_2_label || '任意項目2'}
                isRequired={targetCampaign?.option_2_status === 'required'}
              >
                <Input
                  type="text"
                  borderRadius="0"
                  placeholder={`${
                    targetCampaign?.option_2_label || '任意項目2'
                  }を入力してください`}
                  zIndex={0}
                  {...register('option_2_answer')}
                />
              </FormInputField>
            )}
            {(targetCampaign?.option_3_status === 'required' ||
              targetCampaign?.option_3_status === 'optional') && (
              <FormInputField
                label={targetCampaign?.option_3_label || '任意項目3'}
                isRequired={targetCampaign?.option_3_status === 'required'}
              >
                <Input
                  type="text"
                  borderRadius="0"
                  placeholder={`${
                    targetCampaign?.option_3_label || '任意項目3'
                  }を入力してください`}
                  zIndex={0}
                  {...register('option_3_answer')}
                />
              </FormInputField>
            )}
          </VStack>
          <Text fontSize="sm" fontWeight={400} mt="1.5rem">
            ご入力いただいた情報は予約注文及び必要なご連絡の目的で利用させていただきます。
          </Text>
        </Container>

        <Box
          className="c-bottom-area"
          w="100%"
          px="1rem"
          display="block"
          position="fixed"
          bottom="0.75rem"
          zIndex="100"
        >
          <Button
            isLoading={isLoadingSubmission}
            loadingText="予約内容を確認中…"
            variant="primary-fullwidth-rounded-shadow"
            isDisabled={!isValid || isLoadingSubmission}
            onClick={handleSubmit(onSubmit)}
          >
            確認画面へ進む
          </Button>
        </Box>
      </MainLayout>
      <DatepickerModal
        isOpen={isOpenDatepicker}
        onClose={handleCloseDatepicker}
        initialSelectedDate={getValues('date') || null}
        includeDates={receiptableDates}
        handleUpdatePickupDate={handleUpdatePickupDate}
        checkAvailableDateForPickup={checkAvailableDateForPickup}
      />
    </>
  );
};

export default ReservationCustomerForm;
