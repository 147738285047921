import { Stack, useDisclosure } from '@chakra-ui/react';
import { CycleIcon, HistoryIcon } from 'assets/icons/miscs';
import MainLayout from 'components/layouts/MainLayout';
import CuminBarcodeBox from 'features/membership/components/cumin/CuminBarcodeBox';
import CuminOvertimePointGetCautionModal from 'features/membership/components/cumin/CuminOvertimePointGetCautionModal';
import GingerPointBox from 'features/membership/components/ginger/GingerPointBox';
import {
  GingerPrepaidBox,
  IconButtonWithUnderText
} from 'features/membership/components/ginger/GingerPrepaidBox';
import usePointBalance from 'features/membership/hooks/cumin/usePointBalance';
import usePrepaidBalance from 'features/membership/hooks/ginger/usePrepaidBalance';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { EARTH_FEATURE_CODES } from 'features/membership/libs/const/plugins/earthFeatureCodes';
import { JUPITER_FEATURE_CODES } from 'features/membership/libs/const/plugins/jupiterFeatureCodes';
import {
  GingerPagePageTemplateOptionKey,
  membershipPluginFeatureState,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FEATURES } from 'libs/features';
import { membershipMemberResponseAtom } from 'libs/recoil/atom';
import _ from 'lodash';
import { FC } from 'react';
import { useEffectOnce } from 'react-use';
import { useRecoilValue } from 'recoil';

/**
 * VD+CSD トップ
 */
const GingerTop: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const membershipMemberResponse = useRecoilValue(membershipMemberResponseAtom);
  const plugins = useRecoilValue(membershipPluginFeatureState);
  const jupiterFeatureCodes = plugins.jupiter;
  const earthFeatureCodes = plugins.earth;

  const isGetPrepaidBalanceFeatureActive = Boolean(
    jupiterFeatureCodes?.includes(
      _.camelCase(JUPITER_FEATURE_CODES.GET_BALANCE)
    )
  );

  const isGetTransactionHistoriesFeatureActive = Boolean(
    jupiterFeatureCodes?.includes(
      _.camelCase(JUPITER_FEATURE_CODES.GET_TRANSACTION_HISTORIES)
    )
  );

  const isGetPointBalanceFeatureActive = Boolean(
    earthFeatureCodes?.includes(
      _.camelCase(EARTH_FEATURE_CODES.GET_POINT_BALANCE)
    )
  );

  const templateOptions = useRecoilValue<
    Record<GingerPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.GINGER));
  // 会員番号
  const cardNumber =
    membershipMemberResponse && membershipMemberResponse.status === 'succeeded'
      ? membershipMemberResponse.data.card_number
      : '';

  const {
    pointBalanceResponseData,
    serviceTime,
    isMutating: isPointBalanceMutating,
    executeGetPointBalance
  } = usePointBalance(cardNumber, onOpen);

  const {
    prepaidBalanceResponseData,
    isMutating: isPrepaidBalanceMutating,
    executeGetPrepaidBalance
  } = usePrepaidBalance();

  const updateButtonClickHandler = async () => {
    await executeGetPointBalance();
  };

  // 初回実行
  useEffectOnce(() => {
    executeGetPointBalance();
    executeGetPrepaidBalance();
  });

  return (
    <MainLayout header footer bg="dark.50">
      <Stack px="1rem" pt="3rem" spacing="1.25rem">
        <CuminBarcodeBox
          cardNumber={cardNumber}
          imageUrl={templateOptions?.topImageOption?.image_url}
          barcodeFormat={
            templateOptions?.topBarcodeTypeOption?.content ?? 'EAN13'
          }
        />
        {/* ハウスマネー表示欄 */}
        {isGetPrepaidBalanceFeatureActive && (
          <GingerPrepaidBox
            prepaidDisplay={{
              prepaidName:
                templateOptions?.houseMoneyNameContentOption?.content,
              prepaidBalance: prepaidBalanceResponseData?.balance_total,
              prepaidUnit: templateOptions?.houseMoneyUnitContentOption?.content
            }}
            isPrepaidMutating={isPrepaidBalanceMutating}
            buttonGroup={
              <>
                <IconButtonWithUnderText
                  buttonProps={{
                    onClick: () => {
                      executeGetPrepaidBalance();
                    },
                    isLoading: isPrepaidBalanceMutating,
                    icon: <CycleIcon color="primary" boxSize="1.5rem" />,
                    borderColor: 'primary'
                  }}
                  textProps={{
                    content: '更新',
                    color: 'primary'
                  }}
                />
                {isGetTransactionHistoriesFeatureActive && (
                  <IconButtonWithUnderText
                    buttonProps={{
                      to: `${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.GINGER}/transaction-histories`,
                      icon: <HistoryIcon color="primary" boxSize="1.25rem" />,
                      borderColor: 'primary'
                    }}
                    textProps={{
                      content: '履歴',
                      color: 'primary'
                    }}
                  />
                )}
              </>
            }
          />
        )}
        {/* CSDポイント表示欄 */}
        {isGetPointBalanceFeatureActive && (
          <>
            <GingerPointBox
              pointDisplay={{
                pointName: templateOptions?.pointNameContentOption?.content,
                pointBalance: pointBalanceResponseData?.total_point,
                pointUnit: templateOptions?.pointUnitContentOption?.content,
                unavailableHour: serviceTime?.unavailable_hour
              }}
              expiredDateDisplay={{
                pointExpiredDate: pointBalanceResponseData?.lapse_due_date,
                showPointExpiredDate: Boolean(
                  templateOptions?.topPointExpiryDateVisibleOption
                )
              }}
              isCardPointMutating={isPointBalanceMutating}
              onUpdateButtonClick={updateButtonClickHandler}
            />
            {/* NOTE: onOpenをuseCardPointsの引数に代入する関係で、GingerPointBoxの中には実装しない */}
            <CuminOvertimePointGetCautionModal
              isOpen={isOpen}
              onClose={onClose}
              isLoaded={!isPointBalanceMutating}
              availableHour={serviceTime?.available_hour}
            />
          </>
        )}
      </Stack>
    </MainLayout>
  );
};

export default GingerTop;
