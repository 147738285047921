import { getCampaignDetailByCampaignId } from 'features/reservations/api';
import { CampaignType } from 'features/reservations/types/CampaignType';
import { useEffect, useState } from 'react';

export const useCampaignDetail = (campaignId: number | undefined) => {
  const [targetCampaign, setTargetCampaign] = useState<CampaignType>();
  const [isDone, setIsDone] = useState<boolean>(false);

  useEffect(() => {
    if (!campaignId) return;

    getCampaignDetailByCampaignId(campaignId).then((res) => {
      setTargetCampaign(res);
      setIsDone(true);
    });
  }, [campaignId]);

  return { targetCampaign, isDone };
};
