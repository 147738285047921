import { Card, Flex, Skeleton, Stack, Text, VStack } from '@chakra-ui/react';
import UpdateButton from 'components/modules/UpdateButton';
import dayjs from 'dayjs';
import { FC, MouseEventHandler } from 'react';

type CuminPointBoxProps = {
  updateTime: string;
  isMutating: boolean;
  onUpdateButtonClick: MouseEventHandler;
  isPointExpiryDateVisible?: boolean;
  unavailableHour?: string;
  pointData?: {
    total_point: number;
    lapse_due_date: string;
  };
};

const CuminPointBox: FC<CuminPointBoxProps> = ({
  onUpdateButtonClick,
  updateTime,
  isMutating,
  isPointExpiryDateVisible,
  unavailableHour,
  pointData
}) => (
  <Card px="1.25rem" py="1rem" boxShadow="xs" borderColor="dark.300">
    <VStack gap="0.5rem">
      <Flex m="0" w="100%" alignItems="center" justifyContent="space-between">
        <Skeleton isLoaded={!isMutating}>
          <Text fontSize="md" fontWeight="400">
            {/** 保有ポイント情報取得時の時刻を表示。更新ボタン押下時にも更新する */}
            {updateTime} 時点
          </Text>
        </Skeleton>
        {/* 更新ボタン */}
        <UpdateButton onClick={onUpdateButtonClick} isLoading={isMutating} />
      </Flex>
      <Flex
        m="0"
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        px="0.5rem"
      >
        <Text w="30%" fontSize="md" fontWeight="400">
          保有ポイント残高
        </Text>
        <Stack gap="0.25rem">
          {/* サービス時間外ではない場合、ポイント表示をする */}
          <Skeleton isLoaded={!isMutating}>
            {/* 時間外じゃないが503の場合は - P表示 */}
            {!unavailableHour ? (
              <Text
                textAlign="right"
                fontSize="5xl"
                fontWeight="bold"
                data-testid="point-element"
              >
                {pointData?.total_point?.toLocaleString() ?? '-'}
                <Text as="span" fontSize="2xl">
                  P
                </Text>
              </Text>
            ) : (
              <Text fontSize="xl" fontWeight="bold">
                {/* サービス時間外がAPIから返却されている場合は表示 */}
                {unavailableHour}は<br />
                表示できません
              </Text>
            )}
          </Skeleton>
          {/** ポイント有効期限表示が管理画面側でONに設定している場合は表示 */}
          {isPointExpiryDateVisible && (
            <Skeleton isLoaded={!isMutating}>
              <Text textAlign="right" fontSize="md" color="dark.400">
                有効期限{' '}
                {pointData?.lapse_due_date
                  ? dayjs(pointData.lapse_due_date).format('YYYY/MM/DD')
                  : '----/--/--'}
              </Text>
            </Skeleton>
          )}
        </Stack>
      </Flex>
    </VStack>
  </Card>
);
export default CuminPointBox;
