import { DeepPartial, extendTheme, ThemeOverride } from '@chakra-ui/react';
import type { TenantType } from 'types/TenantType';

// Manage tenant configuration in this method.
export const tenantTheme = (
  defaultTheme: DeepPartial<ThemeOverride>,
  tenantConfig: TenantType
) =>
  extendTheme({
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      primary: tenantConfig.main_color
        ? tenantConfig.main_color
        : defaultTheme.colors?.primary,
      primary30: tenantConfig.main_color
        ? `${tenantConfig.main_color}30`
        : defaultTheme.colors?.primary30,
      primary90: tenantConfig.main_color
        ? `${tenantConfig.main_color}90`
        : defaultTheme.colors?.primary90,
      secondary: tenantConfig.sub_color
        ? tenantConfig.sub_color
        : defaultTheme.colors?.secondary
    }
  });
