/** ユーザー情報表示API（CSD連携）から返却される登録タイプの値 */
export const REGISTER_TYPE = {
  /** カード付番 */
  ISSUANCE: 'issuance',
  /** カード再付番 */
  REISSUANCE: 'reissuance',
  /** カード連携 */
  LINKAGE: 'linkage',
  /** カード再連携 */
  RELINKAGE: 'relinkage'
} as const;
