/**
 * @returns keys of object with types
 *
 * @example
 * ```ts
 * const obj = {
 *  'a': 10,
 *  'b': 20
 * }
 * typedKeys(obj)   // ['a', 'b'] as ('a' | 'b')[]
 * Object.keys(obj) // ['a', 'b'] as string[]
 * ```
 */
const typedKeys = <T extends object>(obj: T): (keyof T)[] =>
  Object.keys(obj) as (keyof T)[];

export { typedKeys };
