import {
  Button,
  Heading,
  HStack,
  IconButton,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { FC, useState } from 'react';

import { MinusIcon } from '@chakra-ui/icons';
import { PlusIcon } from 'assets/icons/miscs';
import Dialog from 'components/modules/Dialog';
import { ProductType } from 'features/reservations/types/ProductType';
import ProductListItem from './ProductListItem';

type CartListItemType = {
  product: ProductType;
  initialQuantity: number;
  maxQuantity: number;
  handleDecrement: () => void;
  handleIncrement: () => void;
  handleRemoveFromCart: () => void;
};

const CartListItem: FC<CartListItemType> = ({
  product,
  initialQuantity,
  maxQuantity,
  handleDecrement,
  handleIncrement,
  handleRemoveFromCart
}: CartListItemType) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [quantity, setQuantity] = useState<number>(initialQuantity);

  return (
    <>
      <VStack alignItems="end" w="100%">
        <ProductListItem product={product} as="reservation" />
        <HStack alignItems="center" mb="0.75rem">
          <Button
            variant="primary-link"
            color="primary"
            size="sm"
            onClick={onOpen}
          >
            削除
          </Button>
          <IconButton
            variant="primary"
            w="1.5rem"
            borderRadius="50%"
            aria-label="Decrease"
            icon={<MinusIcon color="white" boxSize={3} />}
            isDisabled={quantity <= 1}
            onClick={() => {
              handleDecrement();
              setQuantity((prev) => (prev > 0 ? prev - 1 : 0));
            }}
          />
          <Heading fontSize="2xl" w="2rem" pt="-4px" textAlign="center">
            {quantity}
          </Heading>
          <IconButton
            variant="primary"
            w="1.5rem"
            borderRadius="50%"
            aria-label="Increase"
            icon={<PlusIcon color="white" boxSize={3} />}
            isDisabled={quantity >= maxQuantity}
            onClick={() => {
              handleIncrement();
              setQuantity((prev) =>
                prev < maxQuantity ? prev + 1 : maxQuantity
              );
            }}
          />
        </HStack>
      </VStack>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        title="カート商品の削除"
        description="商品を削除しますか？"
        cancelText="キャンセル"
        submitText="削除する"
        onSubmit={() => {
          handleRemoveFromCart();
          onClose();
        }}
        isDestructive={true}
      />
    </>
  );
};

export default CartListItem;
