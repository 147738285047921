import { useEffect, useState } from 'react';

import { getAllReservations } from 'features/reservations/api';
import { ReservationsInStatusType } from 'features/reservations/types/ReservationType';

export const useReservationHistory = () => {
  const [reservations, setReservations] = useState<
    ReservationsInStatusType | object
  >({});
  const [isDone, setIsDone] = useState<boolean>(false);

  useEffect(() => {
    getAllReservations().then((res) => {
      setReservations(res);
      setIsDone(true);
    });
  }, []);

  return {
    reservations,
    isDone
  };
};
