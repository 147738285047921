import SimpleFormPage from 'features/membership/components/simple/SimpleFormPage';
import { useSimpleForm } from 'features/membership/hooks/useSimpleForm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  membershipPluginPageTemplateOptionState,
  SimplePageTemplateOptionKey
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * 会員編集入力
 */
const MintEdit: FC = () => {
  const { state: cardNumber } = useLocation();
  const { useFormReturn, toConfirmPage } = useSimpleForm(cardNumber);
  const confirmPagePath = `/membership/${TEMPLATE_ALIAS_CODE.MINT}/confirm`;
  const templateOptions = useRecoilValue<
    Record<SimplePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.MINT));

  return (
    <SimpleFormPage
      useFormReturn={useFormReturn}
      onFormSubmit={toConfirmPage(confirmPagePath)}
      templateOptions={templateOptions}
      cardNumberLabel="会員番号"
    />
  );
};

export default MintEdit;
