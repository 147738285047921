// TODO: ファイル名変更もしくは別ファイルで格納(linkも利用してるため)
export const BIRTHDAY_MONTH_OPTIONS = new Array(12)
  .fill(1)
  .map((baseNumber: number, index: number) => ({
    key: `birthday_month_${baseNumber + index}`,
    label: `${baseNumber + index}月`,
    value: baseNumber + index
  }));

// TODO: ファイル名変更もしくは別ファイルで格納(linkも利用してるため)
export const BIRTHDAY_DATE_OPTIONS = new Array(31)
  .fill(1)
  .map((baseNumber: number, index: number) => ({
    key: `birthday_date_${baseNumber + index}`,
    label: `${baseNumber + index}日`,
    value: baseNumber + index
  }));

export const GENDER_OPTIONS = [
  {
    key: 'gender_1',
    label: '女性',
    value: 'female'
  },
  {
    key: 'gender_2',
    label: '男性',
    value: 'male'
  },
  {
    key: 'gender_3',
    label: '回答しない',
    value: 'other'
  }
];
