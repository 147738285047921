import { TEMPLATE_ALIAS_CODE } from './aliasCode';

/** templateに応じた会員連携番号入力ページpathをまとめた定数 */
export const FORM_PAGE_PATH = {
  // simple 会員登録入力
  SIMPLE_NEW: `/membership/${TEMPLATE_ALIAS_CODE.SIMPLE}/signup/new`,
  // simple 会員編集入力
  SIMPLE_EDIT: `/membership/${TEMPLATE_ALIAS_CODE.SIMPLE}/edit`,
  // pepper 会員登録入力
  PEPPER_NEW: `/membership/${TEMPLATE_ALIAS_CODE.PEPPER}/signup/new`,
  // pepper 会員編集入力
  PEPPER_EDIT: `/membership/${TEMPLATE_ALIAS_CODE.PEPPER}/edit`,
  // mint 会員登録入力
  MINT_NEW: `/membership/${TEMPLATE_ALIAS_CODE.MINT}/signup/new`,
  // mint 会員編集入力
  MINT_EDIT: `/membership/${TEMPLATE_ALIAS_CODE.MINT}/edit`
} as const;

/** templateに応じた会員連携番号入力完了ページpathをまとめた定数 */
export const COMPLETE_PAGE_PATH = {
  // simple 会員登録入力完了
  SIMPLE_SIGNUP_COMPLETE: `/membership/${TEMPLATE_ALIAS_CODE.SIMPLE}/signup/complete`,
  // simple 会員編集入力完了
  SIMPLE_EDIT_COMPLETE: `/membership/${TEMPLATE_ALIAS_CODE.SIMPLE}/complete`,
  // pepper 会員登録入力完了
  PEPPER_SIGNUP_COMPLETE: `/membership/${TEMPLATE_ALIAS_CODE.PEPPER}/signup/complete`,
  // pepper 会員編集入力完了
  PEPPER_EDIT_COMPLETE: `/membership/${TEMPLATE_ALIAS_CODE.PEPPER}/complete`,
  // mint 会員登録入力完了
  MINT_SIGNUP_COMPLETE: `/membership/${TEMPLATE_ALIAS_CODE.MINT}/signup/complete`,
  // mint 会員編集入力完了
  MINT_EDIT_COMPLETE: `/membership/${TEMPLATE_ALIAS_CODE.MINT}/complete`
} as const;
