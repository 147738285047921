import { useQRCode } from 'next-qrcode';
import { FC } from 'react';

type QRCodeType = {
  text: string;
  margin?: number;
  width?: number;
};

const Qrcode: FC<QRCodeType> = ({ text, margin, width }) => {
  const { Canvas } = useQRCode();

  return (
    <Canvas
      text={text}
      options={{
        margin,
        width
      }}
    />
  );
};

export default Qrcode;
