import dispatchToast from 'components/modules/Toast';
import { marsFormatDataForLinkApi } from 'features/membership/libs/formatDataForApi';
import {
  EarthLinkCardResponse,
  ErrorResponse
} from 'features/membership/types';
import {
  GingerCardLinkFormInputs,
  GingerCardLinkRequestParams
} from 'features/membership/types/form';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { membershipMemberResponseAtom } from 'libs/recoil/atom';
import { MouseEventHandler, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useSetRecoilState } from 'recoil';

type UseCardLinkConfirmReturn = {
  onClickRegisterButton: MouseEventHandler;
  onClickBackButton: MouseEventHandler;
  isMutating?: boolean;
};

/**
 * Gingerのカード連携・再連携確認で利用するhooksをまとめたCustom Hook
 */
const useCardLinkConfirm = (
  state: null | { formData: GingerCardLinkFormInputs },
  completePagePath: string,
  formPagePath: string,
  linkCard: (
    data: GingerCardLinkRequestParams
  ) => Promise<EarthLinkCardResponse | ErrorResponse>
): UseCardLinkConfirmReturn => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const setMembershipMemberResponse = useSetRecoilState(
    membershipMemberResponseAtom
  );

  const [{ loading: isMutating }, onClickRegisterButton] =
    useAsyncFn(async () => {
      if (!state?.formData) return;

      const res = await linkCard(marsFormatDataForLinkApi(state.formData));

      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
        setMembershipMemberResponse(undefined); // 会員情報 のレスポンス のグローバルステートを削除
        navigate(pathname, { replace: true }); // state を削除
        navigate(completePagePath); // 完了ページへ
        return;
      }

      // 個別な項目じゃない問題で引っかかった場合
      if (!(res.error_fields && Object.keys(res.error_fields).length)) {
        dispatchToast({
          id: 'membership-member-api-error',
          toastText: res.errors.join('\n')
        });
        return;
      }

      if (res.error_fields && Object.keys(res.error_fields).length) {
        navigate(formPagePath, {
          state: { formData: state.formData, errors: res.error_fields }
        });
        return;
      }

      dispatchToast({
        id: 'membership-member-api-error',
        toastText:
          'エラーが発生しております。暫く経ってからもう一度お試しください'
      });
    }, [state, pathname, linkCard]);

  const onClickBackButton = useCallback<MouseEventHandler>(() => {
    navigate(formPagePath, { state });
  }, [state, formPagePath, navigate]);

  return {
    onClickRegisterButton,
    onClickBackButton,
    isMutating
  };
};

export default useCardLinkConfirm;
