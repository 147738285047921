import {
  Flex,
  HStack,
  IconButton,
  IconButtonProps,
  Skeleton,
  Stack,
  Text,
  TextProps,
  VStack
} from '@chakra-ui/react';
import { FC, MouseEventHandler, ReactElement } from 'react';
import { Link } from 'react-router-dom';

type IconButtonWithUnderTextProps = {
  buttonProps: (
    | { to: string; onClick?: never }
    | { to?: never; onClick: MouseEventHandler }
  ) &
    Omit<IconButtonProps, 'aria-label'>;
  textProps: {
    content: string;
  } & TextProps;
};

/**
 * 直下に文字の配置がされているIconButton
 */
export const IconButtonWithUnderText: FC<IconButtonWithUnderTextProps> = ({
  buttonProps: { onClick, to, ...otherButtonProps },
  textProps: { content, ...otherTextProps }
}) => (
  <VStack gap="0">
    <IconButton
      as={to ? Link : undefined}
      variant="outline"
      size="lg"
      border="1px"
      backgroundColor="#F7F7F7"
      isRound
      {...otherButtonProps}
      to={to}
      aria-label={content}
      onClick={!to ? onClick : undefined}
    />
    <Text fontSize="lg" {...otherTextProps}>
      {content}
    </Text>
  </VStack>
);

type GingerPrepaidBoxProps = {
  prepaidDisplay: {
    prepaidName?: string | null;
    prepaidBalance?: number;
    prepaidUnit?: string | null;
  };
  isPrepaidMutating: boolean;
  buttonGroup: ReactElement;
};

/**
 * VD+CSD ハウスマネー表示コンポーネント
 *
 * @params
 * prepaidBalanceDisplay ハウスマネー表示に関する情報をまとめたObject \
 *   prepaidName ハウスマネーセクションのタイトル テンプレート設定
 *   prepaidBalance ハウスマネー残高 APIから受け取り
 *   prepaidUnit ハウスマネーの単位 テンプレート設定
 * isPrepaidBalanceMutating {TODO: APIの修正} のmutate状態
 * buttonGroup 右側に表示するボタン群
 */
export const GingerPrepaidBox: FC<GingerPrepaidBoxProps> = ({
  prepaidDisplay: { prepaidName, prepaidBalance, prepaidUnit },
  isPrepaidMutating,
  buttonGroup
}) => {
  return (
    <Flex
      px="1rem"
      m="0"
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      gap="1.5rem"
    >
      <Stack gap="0.25rem">
        {/* NOTE: デフォルト値設定はしない */}
        {!!prepaidName && (
          <Text fontSize="md" wordBreak="break-all" whiteSpace="pre-wrap">
            {prepaidName}
          </Text>
        )}
        {/* TODO: マネー残高取得機能OFF時の表示の仕様 */}
        <Skeleton isLoaded={!isPrepaidMutating}>
          <Text fontSize="4xl" fontWeight="bold" data-testid="prepaid-element">
            {prepaidBalance?.toLocaleString() ?? '-'}
            {/* NOTE: デフォルト値設定はしない */}
            {!!prepaidUnit && (
              <Text
                as="span"
                fontSize="xl"
                fontWeight="400"
                wordBreak="break-all"
                whiteSpace="pre-wrap"
              >
                {prepaidUnit}
              </Text>
            )}
          </Text>
        </Skeleton>
      </Stack>
      {/* ボタンリスト */}
      <HStack gap="0.5rem">{buttonGroup}</HStack>
    </Flex>
  );
};
