import {
  Box,
  Button,
  Center,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  UseDisclosureReturn
} from '@chakra-ui/react';
import Quagga, { QuaggaJSResultObject } from '@ericblade/quagga2';
import { CameraIcon } from 'assets/icons/miscs';
import { getMedian } from 'libs/calculation';
import { FC, RefObject, useCallback, useLayoutEffect, useRef } from 'react';

type BarcodeScanBoxProps = {
  onDetected: ScannerProps['onDetected'];
  // ツルハで「カード番号」のラベルを変更したいとの要望があったため、一時対応としてラベルをカスタマイズできるようにしています
  cardNumberLabel?: string;
};

/**
 * バーコードのカメラ読み取りモーダルとモーダルを開くためのボタン
 */
const BarcodeScanBox: FC<BarcodeScanBoxProps> = ({
  onDetected,
  cardNumberLabel
}) => {
  // バーコードのカメラ読み取りモーダル管理
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Center>
        <Button
          type="button"
          variant="primary-link"
          h="auto"
          p="0"
          size="lg"
          leftIcon={<CameraIcon boxSize="1.5rem" />}
          onClick={onOpen}
        >
          カメラ読み取り
        </Button>
      </Center>
      <BarcodeScanModal
        isOpen={isOpen}
        onClose={onClose}
        onDetected={onDetected}
        cardNumberLabel={cardNumberLabel}
      />
    </>
  );
};

export default BarcodeScanBox;

type BarcodeScanModalProps = Pick<UseDisclosureReturn, 'isOpen' | 'onClose'> &
  BarcodeScanBoxProps;

/**
 * バーコードのカメラ読み取りモーダル
 *
 * 参考: https://github.com/ericblade/quagga2-react-example
 */
const BarcodeScanModal: FC<BarcodeScanModalProps> = ({
  isOpen,
  onClose,
  onDetected,
  cardNumberLabel = 'カード番号'
}) => {
  const scannerRef = useRef<HTMLDivElement>(null); // quagga2 に渡す DOM ターゲット
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent bgColor="black" height="100%" overflow="hidden">
        {/* 閉じるボタン */}
        <ModalCloseButton
          top="1rem"
          left="1rem"
          right="auto"
          zIndex="tooltip"
          color="white"
        />
        {/* quagga2 */}
        <Box ref={scannerRef} height="100%">
          {/* canvas タグ(自動生成されるがデザインを当てるため自分で用意) */}
          <Box
            as="canvas"
            className="drawingBuffer"
            display="none" // 不要なので表示しない
          />
          {/* video タグ(自動生成されるがデザインを当てるため自分で用意) */}
          <Box as="video" width="100%" height="100%" />
          {/* 「カード番号を直接入力する」ボタン */}
          <Button
            type="button"
            variant="primary-fullwidth-rounded"
            width="80%"
            position="absolute"
            bottom="20%"
            left="10%"
            onClick={onClose}
          >
            {cardNumberLabel}を直接入力する
          </Button>
          {/* カメラ読み取りを実行するコンポーネント */}
          <Scanner
            scannerRef={scannerRef}
            onDetected={(code) => {
              onDetected(code);
              onClose();
            }}
          />
        </Box>
      </ModalContent>
    </Modal>
  );
};

type ScannerProps = {
  scannerRef: RefObject<HTMLDivElement>;
  onDetected: (code: string) => void;
};

/**
 * カメラ読み取りを実行するコンポーネント
 *
 * 参考: https://github.com/ericblade/quagga2-react-example
 */
const Scanner: FC<ScannerProps> = ({ scannerRef, onDetected }) => {
  const detectCallback = useCallback(
    (result: QuaggaJSResultObject) => {
      const errors = result.codeResult.decodedCodes
        .flatMap((x) => x.error)
        .filter((error): error is number => typeof error == 'number');
      // 90% 以上の正確性があれば読み取り成功とみなす
      if (
        // 最初の一桁の正確性(型が間違ってるようなので取得できない場合は 0 とみなす)
        (result.codeResult.startInfo?.error || 0) < 0.1 &&
        // 最後の一桁の正確性(型が間違ってるようなので取得できない場合は 0 とみなす)
        (result.codeResult.endInfo?.error || 0) < 0.1 &&
        // その他全ての正確性の中央値
        getMedian(errors) < 0.1 &&
        result.codeResult.code
      )
        onDetected(result.codeResult.code);
    },
    [onDetected]
  );

  useLayoutEffect(() => {
    // if this component gets unmounted in the same tick that it is mounted, then all hell breaks loose,
    // so we need to wait 1 tick before calling init().  I'm not sure how to fix that, if it's even possible,
    // given the asynchronous nature of the camera functions, the non asynchronous nature of React, and just how
    // awful browsers are at dealing with cameras.
    let ignoreStart = false;
    const init = async () => {
      // wait for one tick to see if we get unmounted before we can possibly even begin cleanup
      await new Promise((resolve) => setTimeout(resolve, 1));
      if (ignoreStart || !scannerRef.current) return;
      // begin scanner initialization
      await Quagga.init(
        {
          inputStream: {
            type: 'LiveStream',
            // 16:9
            constraints: {
              height: 640,
              width: 360,
              facingMode: 'environment'
            },
            target: scannerRef.current,
            willReadFrequently: true,
            // バーコードの読み取りエリア
            area: {
              top: '20%',
              right: '10%',
              left: '10%',
              bottom: '40%'
            }
          },
          decoder: { readers: ['ean_reader'] }, // EAN-13 のみ対象
          locate: false
        },
        async (err) => {
          if (err) return console.error('Error starting Quagga:', err);
          if (scannerRef && scannerRef.current) await Quagga.start();
        }
      );
      Quagga.onDetected(detectCallback);
    };
    init();
    // cleanup by turning off the camera and any listeners
    return () => {
      ignoreStart = true;
      Quagga.stop();
      Quagga.offDetected(detectCallback);
    };
  }, [onDetected, scannerRef, detectCallback]);

  return null;
};
