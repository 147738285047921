import dayjs from 'dayjs';
import { reservationCartsAtom } from 'features/reservations/libs/recoil/atom';
import { CartType } from 'features/reservations/types/CartType';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

const sortById = (a: { id: number }, b: { id: number }) => a.id - b.id;

const isValidCart = (cart: CartType) =>
  dayjs(cart.updatedAt).isValid() &&
  dayjs(cart.updatedAt).isBefore(dayjs().add(3, 'month'));

export const useCart = (campaignId: number) => {
  const [recoilCarts, setRecoilCarts] = useRecoilState(reservationCartsAtom);

  const getTargetCartByCampaignId = (campaignId: number) =>
    recoilCarts.filter((cart) => cart.campaignId === campaignId)[0] ?? null;

  const [cartProducts, setCartProducts] = useState<
    { id: number; quantity: number }[]
  >(getTargetCartByCampaignId(campaignId)?.products ?? []);

  // NOTE: Update overall carts on update any one of carts.
  useEffect(() => {
    const targetCart = getTargetCartByCampaignId(campaignId);

    if (!targetCart) {
      const defaultCartValue = {
        campaignId,
        products: [],
        createdAt: dayjs().format(),
        updatedAt: dayjs().format()
      };
      setRecoilCarts((prevCarts) => [
        ...prevCarts.filter((cart) => cart.campaignId !== campaignId),
        defaultCartValue
      ]);
      return;
    }

    setRecoilCarts((prevCarts) =>
      [
        ...prevCarts.filter((prevCart) => prevCart.campaignId !== campaignId),
        {
          ...targetCart,
          products: cartProducts,
          updatedAt: dayjs().format()
        }
      ].filter(isValidCart)
    );
    // FIXME:dependencies の精査
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartProducts]);

  const addProduct = ({ id, quantity }: { id: number; quantity: number }) => {
    const targetProduct = cartProducts.filter((product) => product.id === id);
    if (targetProduct.length) {
      setCartProducts(
        [
          ...cartProducts.filter((product) => product.id !== id),
          { id, quantity: targetProduct[0].quantity + quantity }
        ].sort(sortById)
      );
    } else {
      setCartProducts([...cartProducts, { id, quantity }].sort(sortById));
    }
  };

  const removeProduct = ({ id }: { id: number }) => {
    setCartProducts(
      [...cartProducts.filter((product) => product.id !== id)].sort(sortById)
    );
  };

  const removeCart = (campaignId: number) => {
    setRecoilCarts((prevCarts) => [
      ...prevCarts.filter((prevCart) => prevCart.campaignId !== campaignId)
    ]);
  };

  return {
    cartProducts,
    addProduct,
    removeProduct,
    removeCart
  };
};
