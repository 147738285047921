import { Box, Heading, Image } from '@chakra-ui/react';
import { SimpleCampaignType } from 'features/reservations/types/CampaignType';
import { FALLBACK_IMAGES } from 'libs/constants';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type CampaignsListItemProps = {
  campaign: SimpleCampaignType;
  storeId: number;
};

const CampaignsListItem: FC<CampaignsListItemProps> = ({
  campaign,
  storeId
}) => {
  return (
    <Box w="100%" bg="white" borderRadius="0.5rem">
      <RouterLink to={`${campaign.id}?storeId=${storeId}`}>
        <Box
          h="7rem"
          w="100%"
          bg="gray.50"
          borderTopRadius="0.5rem"
          overflow="hidden"
        >
          <Image
            boxSize="100%"
            objectFit="contain"
            loading="lazy"
            src={campaign.image_file_urls[0]?.image_file_url ?? ''}
            fallbackSrc={FALLBACK_IMAGES.rect}
            alt="キャンペーン画像"
          />
        </Box>
        <Box p="0.75rem" pb="0.5rem">
          <Heading color="primary" size="md" mb="0.5rem">
            {campaign.name}
          </Heading>
        </Box>
      </RouterLink>
    </Box>
  );
};

export default CampaignsListItem;
