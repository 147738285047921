import { ProductType } from 'features/reservations/types/ProductType';
import {
  roundDecimalError,
  RoundingAliasKeys,
  roundToInteger
} from './rounding';

/**
 * Output the subtotal calculated with lined items and its quantity
 *
 * @module getSubTotal
 * @param {any[]} items - lined items filtered by the designated tax. (e.g.) products where tax rate is 0.08
 * @param {RoundingAliasKeys} roundingOption - set the rounding option.
 * @return {number}
 */
export const getSubTotal = (
  items: { price: number; quantity: number; tax_rate: number }[],
  roundingOption: RoundingAliasKeys = 'round_down'
) => {
  const subTotalWithoutRounding = items.reduce(
    (previousValue, product) =>
      previousValue +
      roundDecimalError(
        (product.price ?? 0) *
          (product.quantity ?? 0) *
          (1 + Number(product.tax_rate ?? 0)),
        roundingOption
      ),
    0
  );
  return roundToInteger(subTotalWithoutRounding, roundingOption);
};

/**
 * Output the grand total calculated with all items in cart.
 *
 * @module getGrandTotal
 * @param {any[]} items - lined items in a cart
 * @param {RoundingAliasKeys} roundingOption - set the rounding option.
 * @return {number}
 */
export const getGrandTotal = (
  items: { price: number; quantity: number; tax_rate: number }[],
  roundingOption: RoundingAliasKeys
) => {
  // Step 1: Generate array of sets of products' tax rate.
  // e.g. [0.08, 0.1, 0.08, 0.12] => [0.08, 0.1, 0.12]
  const taxSets = Array.from(new Set(items.map((item) => item.tax_rate)));
  // Step 2: Filtering products by its tax rate and calculate subtotal amount for each tax rate.
  const grandTotal = taxSets.reduce((previousValue, taxRate) => {
    const productsFilteredByTaxRate = items.filter(
      (item) => item.tax_rate === taxRate
    );
    const subTotal = getSubTotal(productsFilteredByTaxRate, roundingOption);
    return previousValue + subTotal;
  }, 0);
  return grandTotal;
};

/**
 * Output unit price including tax
 *
 * @module getUnitPriceIncludingTax
 * @param {Pick<ProductType, 'price' | 'tax_rate'>} product - lined items filtered by the designated tax. (e.g.) products where tax rate is 0.08
 * @param {RoundingAliasKeys} roundingOption - set the rounding option.
 * @return {number}
 */
export const getUnitPriceIncludingTax = (
  product: Pick<ProductType, 'price' | 'tax_rate'>,
  roundingOption: RoundingAliasKeys
) =>
  roundToInteger(
    roundDecimalError(
      product.price * (1 + Number(product.tax_rate)),
      roundingOption
    ),
    roundingOption
  );

/**
 * number 型配列の中央値を算出する
 *
 * 引用元: https://github.com/ericblade/quagga2-react-example/blob/c4dffb37917dc3d799424d74994f48e50c9a2fe8/src/Scanner.js#L5-L13
 *
 * @param {number[]} arr
 * @return {number}
 */
export const getMedian = (arr: number[]) => {
  const newArr = [...arr]; // copy the array before sorting, otherwise it mutates the array passed in, which is generally undesireable
  newArr.sort((a, b) => a - b);
  const half = Math.floor(newArr.length / 2);
  if (newArr.length % 2 === 1) {
    return newArr[half];
  }
  return (newArr[half - 1] + newArr[half]) / 2;
};
