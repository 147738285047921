import SimpleTopPage from 'features/membership/components/simple/SimpleTopPage';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { MERCURY_FEATURE_CODES } from 'features/membership/libs/const/plugins/mercuryFeatureCodes';
import {
  membershipPluginFeatureState,
  membershipPluginPageTemplateOptionState,
  SimplePageTemplateOptionKey
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import _ from 'lodash';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * トップ
 */
const PepperTop: FC = () => {
  const templateOptions = useRecoilValue<
    Record<SimplePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.PEPPER));
  const mercuryFeatureCodes = useRecoilValue(
    membershipPluginFeatureState
  ).mercury;
  const isChangeCardNumberFeatureActive = mercuryFeatureCodes?.includes(
    _.camelCase(MERCURY_FEATURE_CODES.CHANGE_CARD_NUMBER)
  );

  return (
    <SimpleTopPage
      templateAliasCode={TEMPLATE_ALIAS_CODE.PEPPER}
      templateOptions={templateOptions}
      isChangeCardNumberFeatureActive={Boolean(isChangeCardNumberFeatureActive)}
    />
  );
};

export default PepperTop;
