import { Box, HStack, Skeleton, SkeletonText, VStack } from '@chakra-ui/react';
import { ClockIcon, PhoneIcon, PinIcon } from 'assets/icons/miscs';
import { FC } from 'react';

export const StoresAccordionSkeletonBox: FC = () => (
  <HStack w="100%" bg="white" mt="0" ps="1rem" pe="2rem" py="1rem">
    <HStack flex="1" textAlign="left">
      <PinIcon boxSize="1rem" color="dark.400" />
      <SkeletonText w="20%" noOfLines={1} />
    </HStack>
    <Skeleton w="2rem" h="0.5rem" />
  </HStack>
);

export const StoreListSkeletonBox: FC = () => (
  <Box
    bg="white"
    border="1px solid"
    borderColor="dark.100"
    mb="1rem"
    p="1rem"
    borderRadius="4px"
  >
    <VStack mb="1rem" alignItems="flex-start">
      <Skeleton w="80%" h="1rem" mb="0.5rem" />
      <SkeletonText w="60%" noOfLines={1} />
    </VStack>

    <HStack mb="0.5rem">
      <ClockIcon color="dark.400" />
      <SkeletonText w="60%" noOfLines={1} />
    </HStack>
    <HStack mb="1rem">
      <PhoneIcon color="dark.400" />
      <SkeletonText w="60%" noOfLines={1} />
    </HStack>

    <Skeleton w="100%" h="2.5rem" borderRadius="0.375rem" />
  </Box>
);
