import { getCampaignProducts } from 'features/reservations/api';
import { ProductType } from 'features/reservations/types/ProductType';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

export const useProducts = (campaignId: number, campaignGroupId: number) => {
  const [parentCampaignName, setParentCampaignName] = useState<string>('');
  const [parentCampaignGroupName, setParentCampaignGroupName] =
    useState<string>('');
  const [products, setProducts] = useState<ProductType[]>([]);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  useEffectOnce(() => {
    getCampaignProducts({
      campaign_id: campaignId,
      group_id: campaignGroupId
    }).then((res) => {
      const { data, status } = res;
      if (status === API_RESPONSE_STATUS.SUCCEEDED) {
        setParentCampaignName(data.campaign_name);
        setParentCampaignGroupName(data.group_name);
        setProducts(data.products);
      } else {
        setIsNotFound(true);
      }
      setIsDone(true);
    });
  });

  return {
    parentCampaignName,
    parentCampaignGroupName,
    products,
    isDone,
    isNotFound
  };
};
