import { getNotificationDetail } from 'features/notification/api';
import { NotificationDetailType } from 'features/notification/types/NotificationType';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

export const useNotificationDetail = (id: number) => {
  const [notificationDetail, setNotificationDetail] =
    useState<NotificationDetailType>();
  const [isDone, setIsDone] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  useEffectOnce(() => {
    getNotificationDetail(id)
      .then((res) => {
        setNotificationDetail(res);
        setIsDone(true);
      })
      .catch(() => {
        setIsNotFound(true);
      });
  });

  return { notificationDetail, isDone, isNotFound };
};
