import {
  Button,
  Container,
  Divider,
  Heading,
  Image,
  Text
} from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import dayjs from 'dayjs';
import { useNotificationDetail } from 'features/notification/hooks/useNotificationDetail';
import { FALLBACK_IMAGES } from 'libs/constants';
import { openWindow } from 'libs/line';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/** お知らせ詳細 */
const NotificationDetail: FC = () => {
  const navigate = useNavigate();
  const { notificationId } = useParams();
  const { notificationDetail, isNotFound } = useNotificationDetail(
    Number(notificationId)
  );

  // IDが存在してない場合はエラーメッセージを表示
  if (isNotFound)
    return (
      <MainLayout header footer>
        <Container mt="3rem">
          <Text as="p" fontSize="md" pb="1.5rem">
            お知らせページが見つかりませんでした。
          </Text>
          <Button
            variant="primary-fullwidth-rounded-shadow"
            mt="1rem"
            onClick={() => navigate('/notifications')}
          >
            一覧へ戻る
          </Button>
        </Container>
      </MainLayout>
    );

  // notificationDetailはundefinedの場合
  if (!notificationDetail) return <MainLayout header footer />;

  const hasExternalLink =
    notificationDetail.external_link_url &&
    notificationDetail.external_link_label;

  return (
    <MainLayout header footer>
      {notificationDetail.main_image && (
        <Image
          w="100%"
          h="17.5rem"
          my="auto"
          objectFit="contain"
          loading="lazy"
          src={notificationDetail.main_image}
          fallbackSrc={FALLBACK_IMAGES.square}
          alt="お知らせ画像"
        />
      )}
      <Container px="1rem" pt={notificationDetail.main_image ? '1rem' : '2rem'}>
        <Heading mb="0.5rem" fontSize="1rem" fontWeight="500">
          {notificationDetail.title}
        </Heading>
        <Text mb="2.37rem" textAlign="right">
          {dayjs(notificationDetail.publish_at).format('YYYY/MM/DD HH:mm')}
        </Text>
        <Text
          mb="1rem"
          fontSize="0.75rem"
          fontWeight="400"
          whiteSpace="pre-wrap"
        >
          {notificationDetail.description}
        </Text>
        {hasExternalLink && (
          <Button
            mt="1.37rem"
            mb="2rem"
            variant="primary-fullwidth-rounded-shadow"
            wordBreak="break-all"
            whiteSpace="pre-wrap"
            onClick={() =>
              openWindow(notificationDetail.external_link_url || '', true)
            }
          >
            {notificationDetail?.external_link_label}
          </Button>
        )}
        {!!notificationDetail.stores.length && (
          <>
            <Divider mb="1rem" border="0.06rem" />
            <Heading mb="0.5rem" fontSize="1rem" fontWeight="500">
              対象店舗
            </Heading>
            <Text fontSize="0.75rem" fontWeight="400">
              {notificationDetail.stores.map((store) => store.name).join(' / ')}
            </Text>
          </>
        )}
      </Container>
    </MainLayout>
  );
};

export default NotificationDetail;
