import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export const reactSelectStyles = {
  control: (styles: object) => ({
    ...styles,
    fontWeight: 400,
    fontSize: '0.875rem',
    borderRadius: 0,
    border: '1px solid #e2e8f0',
    padding: '0.0625rem 0 0.0625rem 0.5rem'
  }),
  option: (styles: object, { isSelected }: { isSelected: boolean }) => ({
    ...styles,
    color: '#333',
    backgroundColor: isSelected ? '#e2e8f0' : '#fff',
    fontWeight: 400,
    fontSize: '0.875rem'
  }),
  placeholder: (styles: object) => ({
    ...styles,
    color: '#333'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (styles: object) => ({
    ...styles,
    color: '#333'
  })
};

export const getDayjs = (dateString: string, parseFormat: string) =>
  dayjs(dateString).isValid() ? dayjs(dateString, parseFormat) : null;

export const getDayjsArray = (dateStrings: string[], parseFormat: string) =>
  (dateStrings ?? [])
    .filter(
      (str) => dayjs(str).isValid() && dayjs(str).isSameOrAfter(dayjs(), 'day')
    )
    .map((str) => dayjs(str, parseFormat));

export const convertDayjsToDate = (date: Dayjs) => date.toDate();
export const convertDayjsToDateArray = (dates: Dayjs[]) =>
  dates.map(convertDayjsToDate);
