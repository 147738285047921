import { Input } from '@chakra-ui/react';
import FormInputField from 'components/modules/FormInputField';
import { postMarsMembershipLinkCardConfirmation } from 'features/membership/api';
import CuminCardLinkFormPage from 'features/membership/components/cumin/CuminCardLinkFormPage';
import { useCardLinkForm } from 'features/membership/hooks/ginger/useCardLinkForm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  GingerPagePageTemplateOptionKey,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { CardLinkFormInputs } from 'features/membership/types/form';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

/**
 * VD+CSD カード連携/入力
 */
const GingerCardLink: FC = () => {
  const confirmPagePath = `/membership/${TEMPLATE_ALIAS_CODE.GINGER}/link/confirm`;
  const { isMutating, methods, formSubmitHandler } = useCardLinkForm(
    confirmPagePath,
    postMarsMembershipLinkCardConfirmation
  );
  const templateOptions = useRecoilValue<
    Record<GingerPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.GINGER));

  return (
    // pin_codeがあると type エラーになるので、無理やり解消する
    <CuminCardLinkFormPage
      isMutating={isMutating}
      methods={methods as unknown as UseFormReturn<CardLinkFormInputs>}
      onFormSubmit={formSubmitHandler}
      templateOptions={templateOptions}
      isTermActive
      additionalField={
        <FormInputField
          label="PIN番号"
          warning={
            templateOptions?.cardFormPinCodeNoticeContentOption?.content ?? ''
          }
          error={methods.formState.errors.pin_code?.message}
          isRequired={true}
        >
          <Input
            type="tel"
            placeholder={
              templateOptions?.cardFormPinCodePlaceholderContentOption
                ?.content ?? '0000'
            }
            {...methods.register('pin_code')}
          />
        </FormInputField>
      }
    />
  );
};

export default GingerCardLink;
