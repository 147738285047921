import { createStandaloneToast, Flex, Text, ToastId } from '@chakra-ui/react';
import React from 'react';

import { theme } from 'libs/chakra';

type DispatchToastType = {
  id: string;
  toastText: string;
  center?: true;
  duration?: number;
};

const dispatchToast = ({
  id,
  toastText,
  center,
  duration = 3000
}: DispatchToastType): ToastId | undefined => {
  const { toast } = createStandaloneToast({ theme });

  if (!toast.isActive(id)) {
    return toast({
      id,
      duration,
      position: 'top',
      render: (): React.ReactNode => (
        <Flex
          w="calc(100vw - 2rem)"
          maxW="90%"
          mx="1rem"
          px="1rem"
          py="0.625rem"
          bg="rgba(51, 51, 51, 0.9)"
          borderRadius="8px"
          alignItems="center"
          justifyContent={center ? 'center' : 'space-between'}
        >
          <Text
            color="white"
            fontWeight="500"
            fontSize="lg"
            whiteSpace="pre-line"
          >
            {toastText}
          </Text>
        </Flex>
      )
    });
  }
};
export default dispatchToast;
