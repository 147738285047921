import { Box, ListItem, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { cashFormat } from 'features/membership/libs/numberFormat';
import { FC } from 'react';

type GingerTransactionHistoryListItemProps = {
  title: string;
  date: string;
  amount: number;
  unit?: string | null;
};

const GingerTransactionHistoryListItem: FC<
  GingerTransactionHistoryListItemProps
> = ({ title, date, amount, unit }) => (
  <ListItem
    py="1.25rem"
    px="1rem"
    display="flex"
    justifyContent="space-between"
    columnGap="1rem"
    bgColor="white"
    mb=".5rem"
  >
    <Box maxWidth="60%">
      <Text fontWeight={700} fontSize="lg" wordBreak="break-all">
        {title}
      </Text>
      <Text as="div" fontWeight={400} color="dark.400">
        {dayjs(date).format('YYYY/MM/DD')}
      </Text>
    </Box>
    <Text
      fontWeight={700}
      fontSize="3xl"
      color={amount > 0 ? '#006DEB' : '#DB3C23'}
    >
      {cashFormat(amount, true)}
      {!!unit && (
        <Text
          as="span"
          fontSize="md"
          wordBreak="break-all"
          whiteSpace="pre-wrap"
          fontWeight={700}
        >
          {unit}
        </Text>
      )}
    </Text>
  </ListItem>
);

export default GingerTransactionHistoryListItem;
