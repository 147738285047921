const BARCODE_TYPE_WIDTH_MAP = {
  CODE128: 2,
  EAN13: 2,
  UPC: 2,
  EAN8: 3,
  EAN5: 3,
  EAN2: 4,
  CODE39: 1,
  ITF14: 2,
  MSI: 1,
  MSI10: 1,
  MSI11: 1,
  MSI1010: 1,
  MSI1110: 1,
  pharmacode: 3,
  codabar: 1.5
};

/**
 * バーコードのフォーマットに適した width を取得する関数
 * ※最大16桁まで考慮
 * ※features 管理画面 > 会員連携管理 > 画面テンプレート設定（CSD×VD） > オプション管理 の
 * トップ_バーコードタイプ（top_barcode_type）のセレクトボックスから選択できるフォーマットが対象
 * ※上記セレクトボックスは、next-barcode で利用可能なフォーマットを元に作成
 * https://github.com/Bunlong/next-barcode?tab=readme-ov-file#barcodes-type
 */
export const getBarcodeWidthByFormat = (format?: string): number => {
  if (!format) return 2;

  return (
    BARCODE_TYPE_WIDTH_MAP[format as keyof typeof BARCODE_TYPE_WIDTH_MAP] ?? 2
  );
};
