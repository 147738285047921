import {
  CardLinkFormInputs,
  CuminCardLinkRequestParams,
  CuminIssueCardFormInput,
  CuminIssueCardRequestParams,
  CuminUserProfileFormInput,
  CuminUserProfileRequestParams,
  GingerCardLinkFormInputs,
  GingerCardLinkRequestParams
} from 'features/membership/types/form';

type CardIssue = Partial<CuminIssueCardFormInput | CuminUserProfileFormInput>;
type CardIssueRequestParams =
  | CuminIssueCardRequestParams
  | CuminUserProfileRequestParams;

const formatStringDateForApi = (dateList: string[]) =>
  dateList.map((dateVal) => (dateVal as string).padStart(2, '0')).join('-');

/**
 * フォームで入力したデータを付番と会員情報編集に関係するAPIのリクエストパラメータ用に変換する関数
 * 対象API：
 *   - カード番号付番入力確認API（CSD連携）
 *   - カード番号付番API（CSD連携）
 *   - ユーザー情報編集API（CSD連携）
 * @param localFormData フォームデータ
 * @returns
 */
export const earthFormatDataForIssueApi = (
  localFormData: CardIssue
): CardIssueRequestParams =>
  Object.entries(localFormData).reduce((prev, [fieldName, fieldValue]) => {
    // fieldValueがnullの可能性もある
    if (fieldName === 'full_name' && fieldValue) {
      const valArr = Object.values(fieldValue);
      // CSD側のルールで姓名の間は全角スペース、入力されてなかったらnull
      return {
        ...prev,
        [fieldName as keyof CardIssue]: !valArr.includes('')
          ? Object.values(fieldValue).join('　')
          : null
      };
    }
    // fieldValueがnullの可能性もある
    if (fieldName === 'full_name_kana' && fieldValue) {
      const valArr = Object.values(fieldValue);
      // CSD側のルールで姓名かなの間は半角スペース、入力されてなかったらnull
      return {
        ...prev,
        [fieldName as keyof CardIssue]: !valArr.includes('')
          ? Object.values(fieldValue).join(' ')
          : null
      };
    }
    // fieldValueがnullの可能性もある
    if (fieldName === 'birthday' && fieldValue) {
      const valArr = Object.values(fieldValue);
      return {
        ...prev,
        [fieldName as keyof CardIssue]: !valArr.includes('')
          ? formatStringDateForApi(valArr)
          : null
      };
    }
    if (typeof fieldValue === 'string' || typeof fieldValue === 'number') {
      return {
        ...prev,
        [fieldName as keyof CardIssue]: fieldValue !== '' ? fieldValue : null
      };
    }

    return prev;
  }, {});

/**
 * フォームで入力したデータを連携に関係するAPIのリクエストパラメータ用に変換する関数
 * 対象API：
 *   - カード連携入力確認API（CSD連携）
 *   - カード番号連携API（CSD連携）
 *   - カード再連携確認API（CSD連携）
 *   - カード再連携API（CSD連携）
 *   - カード連携入力確認API（CSD×VD連携）
 *   - カード番号連携API（CSD×VD連携）
 *   - カード再連携確認API（CSD×VD連携）
 *   - カード番号再連携API（CSD×VD連携）
 * @param localFormData フォームデータ
 * @returns
 */
const formatDataForLinkApi =
  <
    T extends CardLinkFormInputs | GingerCardLinkFormInputs,
    K extends CuminCardLinkRequestParams | GingerCardLinkRequestParams
  >(
    initData: K
  ) =>
  (localFormData: T): K =>
    Object.entries(localFormData).reduce((prev, [fieldName, fieldValue]) => {
      if (fieldName === 'birthday' && fieldValue) {
        const valArr = Object.values(fieldValue);
        // 連携フォームは全て必須値・バリデーション処理が存在する＆typeも必須としているので
        // 空文字＆nullの時のrequestパラメータのnull設定はしない
        return {
          ...prev,
          [fieldName as keyof T]: formatStringDateForApi(valArr)
        };
      }
      if (typeof fieldValue === 'string' || typeof fieldValue === 'number') {
        return {
          ...prev,
          [fieldName as keyof T]: fieldValue
        };
      }

      return prev;
    }, initData);

/** earth 連携・再連携APIで利用するフォーマット関数 */
export const earthFormatDataForLinkApi = formatDataForLinkApi<
  CardLinkFormInputs,
  CuminCardLinkRequestParams
>({ card_number: '', birthday: '' });

/** mars 連携・再連携APIで利用するフォーマット関数 */
export const marsFormatDataForLinkApi = formatDataForLinkApi<
  GingerCardLinkFormInputs,
  GingerCardLinkRequestParams
>({ card_number: '', birthday: '', pin_code: '' });
