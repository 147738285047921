import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { FEATURES } from 'libs/features';
import { membershipSettingAtom } from 'libs/recoil/atom';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * 会員連携トップ
 *
 * 画面テンプレートに従いパスを振り分ける
 */
const MembershipTop: FC = () => {
  const {
    page_template: { alias_code }
  } = useRecoilValue(membershipSettingAtom);

  // 会員連携画面テンプレート: Simple
  if (alias_code === TEMPLATE_ALIAS_CODE.SIMPLE)
    return (
      <Navigate
        to={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.SIMPLE}`}
        replace
      />
    ); // Simpleのトップへ

  // 会員連携画面テンプレート: Pepper
  if (alias_code === TEMPLATE_ALIAS_CODE.PEPPER)
    return (
      <Navigate
        to={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.PEPPER}`}
        replace
      />
    ); // Pepperのトップへ

  // 会員連携画面テンプレート: Mint
  if (alias_code === TEMPLATE_ALIAS_CODE.MINT)
    return (
      <Navigate
        to={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.MINT}`}
        replace
      />
    ); // Mintのトップへ

  // 会員連携画面テンプレート: Cumin
  if (alias_code === TEMPLATE_ALIAS_CODE.CUMIN)
    return (
      <Navigate
        to={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.CUMIN}`}
        replace
      />
    ); // Cuminのトップへ

  // 会員連携画面テンプレート: Ginger
  if (alias_code === TEMPLATE_ALIAS_CODE.GINGER)
    return (
      <Navigate
        to={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.GINGER}`}
        replace
      />
    ); // Gingerのトップへ

  // トップへ
  return <Navigate to="/" />;
};

export default MembershipTop;
